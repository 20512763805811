<template>
    <div class="n-pb-4 n-bg-1">
        <div class="c-banner c-banner-home n-col-1 n-border-box n-overflow-hidden">
            <NImage class="c-banner-image n-col-1 n-h-1"
                    type="cover"
                    :src="require('@/assets/image/banner.jpg')">
            </NImage>

            <div class="c-banner-text c-max-width n-m-auto n-p-3 n-flex n-flex-a-c home-banner-text">
                <h1 class="n-c-1 n-flex-s n-text-left c-f-2 n-f-1 n-mr-4 n-m-b-0"
                    v-html="LAN.welcomeBR"></h1>

                <div>
                    <h3 class="n-c-1 n-text-left n-line-height-1"
                        v-html="LAN.intro.i1"></h3>
                    <h3 class="n-c-1 n-text-left n-mt-5 n-line-height-1"
                        v-html="LAN.intro.i2"></h3>
                    <h3 class="n-c-1 n-text-left n-mt-5 n-line-height-1"
                        v-html="LAN.intro.i3"></h3>
                    <h3 class="n-c-1 n-text-left n-mt-5 n-line-height-1"
                        v-html="LAN.intro.i4"></h3>
                </div>
            </div>
        </div>

        <div class="c-max-width n-m-auto n-p-4">
            <section class="mid n-radius-6 n-p-3 n-bg-1 n-pointer c-hover c-shadow"
                     @click="$router.push({name: 'ordering', query: {p: '0-1'}})">
                <h2 class="n-text-center n-m-0 n-mb-2 n-line-height-1">{{ LAN.home.sold }}</h2>
                <h4 class="n-text-center n-m-0 n-mt-1">{{ LAN.home.soldDesc }}</h4>
                <h4 class="n-text-center n-m-0 n-mt-1 c-hover-target"
                    v-html="LAN.home.soldBtn"></h4>
            </section>
        </div>

        <div class="c-max-width-1 n-m-auto n-p-2">
            <div class="n-flex">
                <div class="n-flex-g n-mr-3">
                    <h3 v-html="LAN.home.sTitle"
                        class="n-mb-6"></h3>
                    <h4 v-html="LAN.home.sContent1"
                        class="n-mb-1"></h4>
                    <h4 v-html="LAN.home.sContent2"
                        class="n-mb-1"></h4>
                    <h4 v-html="LAN.home.sContent3"
                        class="n-mb-1"></h4>
                </div>
                <ul class="n-ul n-p-0 tag-list n-flex-s">
                    <li class="c-shadow n-radius-6 n-pointer n-mb-4 n-overflow-hidden"
                        @click="$router.push({ name: 'journal' })">
                        <NImage class="tag-list-item n-flex-s n-mr-3"
                                type="cover"
                                :src="require('@/assets/image/journal.png')"></NImage>
                        <div class="tag-content n-col-1 n-h-1 n-flex n-flex-dr-c n-flex-a-c n-flex-j-c">
                            <h1 class="n-c-1 n-line-height-1 n-mb-1">{{ journalCount }}</h1>
                            <h4 class="n-c-1 n-m-0">{{ LAN.nav.QQ }}</h4>
                        </div>
                    </li>
                    <li class="c-shadow n-radius-6 n-pointer n-overflow-hidden"
                        @click="$router.push({ name: 'book' })">
                        <NImage class="tag-list-item n-flex-s n-mr-3"
                                type="cover"
                                :src="require('@/assets/image/book.png')"></NImage>
                        <div class="tag-content n-col-1 n-h-1 n-flex n-flex-dr-c n-flex-a-c n-flex-j-c">
                            <h1 class="n-c-1 n-line-height-1 n-mb-1">{{ bookCount }}</h1>
                            <h4 class="n-c-1 n-m-0">{{ LAN.nav.book }}</h4>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapMutations, mapState } from 'vuex';
    import { StarOutlined, LikeOutlined, MessageOutlined, UndoOutlined } from '@ant-design/icons-vue';
    import NImage from '@/components/nui/NImage/NImage.vue'

    import nui from '@/assets/script';

    export default {
        name: 'Home',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                journalCount: '-',
                bookCount: '-'
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            NMounted () {
                this.loadData()
            },
            loadData () {
                this.request(this.API.homeData, {}).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE && res.data) {
                        this.journalCount = res.data.totalPeriodicals;
                        this.bookCount = res.data.totalBooks;
                    }
                })
                // this.request(this.API.journal, { requestId: 'journal', params: { current: 1, size: 9999999, keyword: '' } }),

                // this.journalCount = 29;
                // this.bookCount = 364
                // Promise.all([
                // ]).then(res => {
                //     var [r1, r2, r3, r4] = res, bookCount = 0;

                //     if (r1.code == this.REQUEST_SUCCESS_CODE) {
                //         this.journalCount = r1.data.total;
                //     }

                //     if (r2.code == this.REQUEST_SUCCESS_CODE) {
                //         bookCount += r2.data.total;
                //     }

                //     if (r3.code == this.REQUEST_SUCCESS_CODE) {
                //         bookCount += r3.data.total;
                //     }

                //     if (r4.code == this.REQUEST_SUCCESS_CODE) {
                //         r4.data.forEach(item => {
                //             bookCount += parseInt(item.count)
                //         });
                //     }

                //     this.bookCount = bookCount;
                // })
            }
        }),
        components: {
            StarOutlined,
            LikeOutlined,
            MessageOutlined,
            NImage,
            UndoOutlined
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .tag-list {
        width: 210px;

        li {
            position: relative;

            &:hover {
                .tag-list-item {
                    transform: scale(1.2);
                }
            }
        }

        .tag-list-item {
            transition: all 0.3s;
            width: 100%;
            height: 120px;
        }

        .tag-content {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 1;
            }

            h1 {
                position: relative;
                z-index: 2;
                font-size: 36px;

                small {
                    font-size: 16px;
                    position: relative;
                    top: -2px;
                }
            }

            h4 {
                position: relative;
                z-index: 2;
            }
        }
    }

    .home-banner-text {
        h1 {
            width: 465px;
            line-height: 1.2;
        }
    }

    .mid {
        width: 70%;
        margin: 0 auto;
        background-image: linear-gradient(
            90deg,
            rgba(251, 225, 230, 0.69),
            rgba(255, 255, 255, 0)
        );
    }

    @media screen and (max-width: 1100px) {
        .home-banner-text {
            display: block;

            h1 {
                margin-bottom: 10px;
                width: 100%;
                text-align: center;
            }

            h3 {
                margin-top: 10px;
            }
        }

        .mid {
            width: 100%;
        }
    }
</style>