<template>
    <div class="n-pb-4">
        <div class="c-banner c-banner-small n-col-1 n-border-box n-overflow-hidden">
            <NImage class="c-banner-image n-col-1 n-h-1"
                    type="cover"
                    :src="require('@/assets/image/banner.jpg')"></NImage>

            <div class="c-banner-text n-col-1 n-p-3">
                <h1 class="n-c-1 c-max-width n-text-center n-m-auto n-mb-1 c-f-1 n-f-1">{{LAN.welcome}}</h1>
            </div>
        </div>

        <div class="c-max-width n-m-auto n-bg-2 n-mt-4 n-plr-2">
            <div class="n-flex n-flex-a-c n-flex-j-sb n-mb-4">
                <h2 class="n-f-bold n-c-base c-title n-b-line n-t-line n-inline-block n-pb-1">{{LAN.common.allJournal}}</h2>

                <div class="n-mr-2 search-container">
                    <a-input-search v-model:value="keyword"
                                    enter-button
                                    allowClear
                                    size="large"
                                    :placeholder="LAN.nui.common.pleaseEnterKeyword"
                                    @search="searchData" />
                </div>
            </div>

            <a-row :gutter="[15,15]"
                   class="n-col-1"
                   v-if="loading">
                <a-col :span="24"
                       :lg="12"
                       :key="item"
                       v-for="item in 4">
                    <div class="n-flex n-bg-1 n-p-4 n-pointer list-item">
                        <div class="list-thumb n-flex-s n-mr-3 n-skeleton"></div>
                        <div class="n-flex-g n-flex-g-w-1-hack">
                            <div class="n-skeleton n-skeleton-text n-ptb-6"></div>
                            <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-6"></div>
                            <div class="n-skeleton n-skeleton-text n-mt-6"></div>
                        </div>
                    </div>
                </a-col>
            </a-row>

            <a-row :gutter="[15,15]"
                   class="n-col-1"
                   v-else-if="data.length > 0">

                <a-col :span="24"
                       :lg="12"
                       :key="item.id"
                       v-for="(item,index) in data">
                    <div class="n-flex n-bg-1 n-p-4 n-pointer list-item n-h-1"
                         @click="goJournalLP(item)">
                        <div class="list-thumb c-border n-flex-s n-mr-3">
                            <NImage class="list-thumb-image n-col-1 n-h-1"
                                    type="cover"
                                    zoomView
                                    :k="index"
                                    :src="item.coverPicture"></NImage>
                        </div>
                        <div class="n-flex-g n-flex-g-w-1-hack n-flex n-flex-dr-c">
                            <h1 class="n-f-bold n-c-4 n-m-0 n-e-2"
                                style="height: 50px; line-height: 25px"
                                v-html="parseHilight(item.name, keyword)"></h1>

                            <span class="n-block"
                                  v-if="item.isAnnualIssue == 1">{{ LAN.journal.since1.replace("|YEAR|", item.firstPublishYear) }}</span>

                            <span class="n-block"
                                  v-else-if="LANI == 0">
                                {{ (item.firstPublishYear ? LAN.journal.sinces.replace("|YEAR|", item.firstPublishYear) : '') + LAN.journal.sincep.replace("|US|", (item.issuesPerYear)) }}
                            </span>
                            <span class="n-block"
                                  v-else>
                                {{  LAN.journal.sincep.replace("|US|", (item.issuesPerYear)) + (item.firstPublishYear ? LAN.journal.sinces.replace("|YEAR|", item.firstPublishYear) : '')  }}
                            </span>
                            <!-- 
                            <span class="n-block"
                                  v-else>{{ LAN.journal.since.replace("|US|", item.issuesPerYear).replace("|YEAR|", item.firstPublishYear) }}</span> -->
                            <p class="n-block n-flex-g n-mb-0"
                               v-html="item.fAbstract "></p>
                            <!-- <span class="n-e-1 n-block">{{ item.bookRelatedPerson.map(item => item.name).join('、') }}</span> -->
                            <!-- <span class="n-c-2 n-block n-mt-2">{{LAN.common.publishDate +': '+ parseCDate(item.publication[0].publishDate)}}</span> -->
                            <!-- <span class="n-c-2 n-block"
                                              v-html="LAN.common.salePrice1 + ': ' + item.publication[0].currency + item.publication[0].listPrice"></span> -->
                            <div class="n-flex n-flex-a-c n-flex-j-sb">
                                <div v-if="item.isOnline == 1 && item.onlyExternalPublish == 1"
                                     @click.stop="goExternalSite(item)"
                                     class="n-flex n-flex-a-c n-line-height-1 c-line-hack n-f-underline">
                                    {{LAN.journal.onlines}}
                                </div>
                                <div v-if="item.isOnline == 1 && item.onlyExternalPublish == 0"
                                     @click.stop="push.call(this, {name: 'journalPanel', query: {jid: item.id}})"
                                     class="n-flex n-flex-a-c n-line-height-1 c-line-hack n-f-underline">
                                    {{LAN.journal.onlines}}
                                </div>
                                <div v-if="item.isOnline == 0 "
                                     class="n-flex n-flex-a-c n-line-height-1 c-line-hack n-f-underline">

                                </div>
                                <a-button type="primary"
                                          size="middle">
                                    {{LAN.common.view}}
                                </a-button>
                            </div>
                        </div>
                    </div>
                </a-col>

            </a-row>

            <a-row :gutter="[15,15]"
                   class="n-col-1"
                   v-else>
                <a-col class="n-bg-1 n-ptb-3"
                       span="24">
                    <a-empty :description="LAN.journal.noJournal"></a-empty>
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script >
    import { mapMutations, mapState } from 'vuex';
    import { StarOutlined, LikeOutlined, MessageOutlined } from '@ant-design/icons-vue';
    import NImage from '@/components/nui/NImage/NImage.vue'

    import nui from '@/assets/script';

    export default {
        name: 'Journal',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                loading: false,
                keyword: '',
                data: [],
                dataCache: []
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            NMounted () {
                this.loadData()
            },
            searchData () {
                this.data = this.extend([], this.dataCache.filter(item => {
                    return item.name.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0 ||
                        item.nameAbbr.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0
                }))

                this.$nextTick(() => {
                    this.parseMathJax()
                })
            },
            loadData () {
                this.loading = true;
                return this.request(this.API.journal, {
                    requestId: 'list',
                    params: { current: 1, size: 9999999, keyword: '' }
                }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE) {
                        this.data = res.data.records
                        this.dataCache = res.data.records
                    }
                    this.loading = false
                    this.$nextTick(() => {
                        this.parseMathJax()
                    })
                }).catch(err => {
                    this.loading = false
                })
            },
            /**仅在外部发布的期刊，如JDG跳转到外部网站链接 */
            goExternalSite (_data) {
                if (_data.onlyExternalPublish == 1) {
                    window.open(_data.externalSiteUrl, '_blank')
                }
            },
            goJournalLP (data) {
                if (data.isAnnualIssue == 1) {
                    this.push.call(this, { name: 'bookSuccessionList', query: { id: data.serialId } })
                } else {
                    this.push.call(this, { name: 'journalList', query: { id: data.id } })
                }
            }
        }),
        components: {
            StarOutlined,
            LikeOutlined,
            MessageOutlined,
            NImage
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .list-thumb {
        width: 130px;
        height: 168px;

        .list-thumb-image {
            transition: all 0.3s;
        }
    }

    .list-item {
        transition: all 0.3s;

        &:hover {
            box-shadow: $shadow-2;
        }
    }
</style>