import LAN from '../assets/script/LAN';
import nui from '../assets/script';

export default {
    state: {
        // 多语言
        LAN: LAN.language,
        LANI: 0,
        ORIGINAL_LAN: LAN,

        //页面请求ID缓存，用于路由变换的时候取消该请求
        pageRequestIdCache: {},

        // 用户信息
        userInfo: {},

        // 窗口尺寸
        windowWidth: document.body.clientWidth,
        windowHeight: document.body.clientHeight,

        scrollInfo: { y: 0 },

        ISGROUP: null
    },
    mutations: {
        SETISGROUP (state, type) {
            state.ISGROUP = type
        },
        setScroll (state, data) {
            state.scrollInfo = Object.assign({}, state.scrollInfo, data)
        },
        setLanguage (state, data) {
            state.LANI = data;
            LAN.LF.setLanguage(data)
            state.LAN = LAN.LF.exportLanguage()
        },
        /**
         * @description 缓存窗口尺寸
         */
        setWindowSize (state) {
            state.windowWidth = document.body.clientWidth
            state.windowHeight = document.body.clientHeight
        },
        /**
         * @description 缓存请求ID
         */
        addPageRequestIdCache (state, data) {
            var newData = {};
            newData[data.page] = {};
            newData[data.page][data.id] = {};
            state.pageRequestIdCache = nui.extend({}, state.pageRequestIdCache, newData);
        },
        /**
         * @description 移除缓存请求ID
         */
        removePageRequestIdCache (state, data) {
            var newData = nui.extend({}, state.pageRequestIdCache);

            if (newData[data.page]) {
                newData[data.page][data.id] && delete newData[data.page][data.id]

                if (Object.keys(newData[data.page]).length <= 0) {
                    delete newData[data.page]
                }
            }

            state.pageRequestIdCache = newData
        },
        /* @description 缓存用户信息
        * @param {*} state 
        * @param {*} data 
        */
        setUserInfo (state, data) {
            state.userInfo = data;
        },
        /**
         * @description 清空用户信息
         * @param {*} state 
         * @param {*} data 
         */
        removeUserInfo (state) {
            state.userInfo = null;
        },
    }
}