<template>
    <div>
        <div class="c-banner c-banner-small n-col-1 n-border-box n-overflow-hidden">
            <NImage class="c-banner-image n-col-1 n-h-1"
                    type="cover"
                    :src="require('@/assets/image/banner.jpg')">
            </NImage>

            <div class="c-banner-text n-col-1 n-p-3">
                <h1 class="n-c-1 c-max-width n-text-center n-m-auto n-mb-1 c-f-1 n-f-1">{{ LAN.welcome }}</h1>
            </div>
        </div>

        <div class="c-max-width n-m-auto n-mt-3 n-plr-2">
            <a-row :gutter="[20, 0]">
                <a-col :span="24"
                       :lg="6">
                    <div v-if="loading"
                         class="n-bg-1 n-p-2 n-pb-4 n-flex n-flex-dr-c n-flex-a-c">
                        <div class="thumb n-flex-s n-mb-3 n-skeleton"></div>
                        <div class="n-skeleton n-skeleton-text n-ptb-3 n-mt-3 n-col-1"></div>
                        <div class="n-skeleton n-skeleton-text n-ptb-3 n-mt-3 n-col-1"></div>
                        <div class="n-skeleton n-skeleton-text n-ptb-3 n-mt-3 n-col-1"></div>
                    </div>

                    <div v-else>
                        <div class="n-bg-1 n-plr-2 n-pt-2 n-pb-4 n-flex n-flex-dr-c n-flex-a-c">
                            <h3 class="n-f-normal n-m-0 c-hover n-pointer n-text-center"
                                @click="goHome">{{
                data.name }}
                            </h3>

                            <div class="thumb n-flex-s n-mt-2 n-border-3">
                                <NImage class="thumb-image n-col-1 n-h-1"
                                        type="cover"
                                        zoomView
                                        :src="data.coverPicture"></NImage>
                            </div>
                        </div>
                    </div>
                </a-col>
                <a-col :span="24"
                       :lg="18">
                    <div class="n-flex n-flex-a-c n-flex-j-sb n-mb-2">
                        <div class="n-mr-2 search-container n-flex n-flex-a-c">

                            <a-input-search v-model:value="keyword"
                                            enter-button
                                            allowClear
                                            size="large"
                                            :placeholder="LAN.nui.common.pleaseEnterKeyword"
                                            @search="searchData" />
                        </div>

                        <a-button type="primary"
                                  shape="round"
                                  v-if="canBack()"
                                  @click="$router.go(-1)">
                            <template #icon>
                                <LeftOutlined />
                            </template>
                            {{ LAN.common.back }}
                        </a-button>
                    </div>

                    <div class="n-flex n-flex-a-c n-flex-w">
                        <div class="n-flex n-flex-a-c">
                            <div class="n-flex n-flex-a-c n-ptb-2 n-mr-4">
                                <span class="title-icon n-flex n-flex-a-c n-flex-j-c n-mr-2 n-c-1 n-radius-3">
                                    <EditOutlined class="n-flex-s" />
                                </span>
                                <h1 class="n-c-4 n-f-bold n-m-0">{{ LAN.journal.volumnes }}</h1>
                            </div>
                            <span style="padding-top: 1px">{{ LAN.journal.totalV.replace('|T|', this.volumeCache?.length ||
                0) }}</span>
                        </div>

                        <a class="n-c-base n-ml-3 c-hover-line n-flex-g n-text-right"
                           :href="data.externalSiteUrl"
                           target="_blank"
                           v-if="data.externalSiteUrl && data.onlyExternalPublish == 0">{{
                LAN.journal.readOn.replace('|SITE|',
                    data.externalSiteName).replace('|NAME|', data.name) }}</a>

                    </div>

                    <div v-if="loading">
                        <ul class="n-ul n-p-0 list n-bg-1 n-mt-2">
                            <li class="n-b-line n-pointer n-p-4"
                                v-for="item in 3"
                                :key="item">
                                <div class="n-skeleton n-skeleton-text n-ptb-4 n-col-3"></div>
                                <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                            </li>
                        </ul>
                    </div>

                    <div v-else-if="volumeData?.length > 0"
                         class="n-bg-1 n-mt-2">
                        <ul class="n-ul n-p-0 list">
                            <li class="n-b-line n-pointer n-p-4 c-hover"
                                v-for="item in volumeData"
                                :key="item"
                                @click="$router.push({ name: 'journalPanel', query: { jid: $route.query.jid, sid: item.id, iid: item.issueId } })">
                                <div class="n-flex n-flex-a-c">
                                    <h3 class="n-m-0">{{ LAN.journal.yearVolume1.replace("|Y|", item.volumeNum) }}</h3>
                                    <span class="c-bg-red n-c-base n-f-bold n-line-height-1-5 n-plr-1 n-radius-3 n-mr-3 n-f-2 n-ml-1"
                                          v-if="item.isNewest == 1">{{ LAN.journal.newest }}</span>
                                </div>
                                <div class="n-flex n-flex-a-e">
                                    <div class="n-flex-g">
                                        <span class="n-block c-hover-target"
                                              v-html="parseHilight(item.volumeTitle, keyword)"></span>
                                        <span class="n-block"
                                              v-if="item.editors.length > 0">({{ item.editors.map(item => item.fullName).join('; ') }})</span>
                                    </div>

                                    <div class="n-block n-col-3 n-text-right">
                                        <a-button type="primary">{{ LAN.common.view }}</a-button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <a-row :gutter="[15,15]"
                           class="n-col-1"
                           v-else>
                        <a-col class="n-bg-1 n-ptb-3"
                               span="24">
                            <a-empty :description="LAN.journal.noVolumes"></a-empty>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script>
    import { mapMutations, mapState } from 'vuex';
    import { EditOutlined } from '@ant-design/icons-vue';

    import NImage from '@/components/nui/NImage/NImage.vue'
    import Breadcrumb from '@/components/Breadcrumb.vue'

    import nui from '@/assets/script';

    export default {
        name: 'BDetail',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                loading: false,
                data: {},

                volumeData: [],
                volumeCache: [],
                keyword: '',
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            NMounted () {
                this.loadData()
            },
            searchData () {
                this.volumeData = this.extend([], this.volumeCache.filter(item => {
                    return item.volumeNumText.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0
                }))
            },
            loadData () {
                this.loading = true;

                Promise.all([
                    this.request(this.API.journalList, {
                        requestId: 'journalList',
                        params: { id: this.$route.query.jid || '' }
                    }),
                    this.request(this.API.journalAnnualPanel, {
                        requestId: 'journalAnnualPanel',
                        params: { id: this.$route.query.jid || '' }
                    })
                ]).then(res => {
                    var [res1, res2] = res;

                    if (res1.code == this.REQUEST_SUCCESS_CODE && res1.data && res1.data.id) {
                        this.data = res1.data || {};
                    } else {
                        this.$router.push({ name: '404page' });
                    }

                    if (res2.code == this.REQUEST_SUCCESS_CODE) {
                        this.volumeData = res2.data || [];
                        this.volumeCache = res2.data || []
                    } else {
                        this.$router.push({ name: '404page' });
                    }

                    this.loading = false
                }).catch((err) => {
                    this.loading = false;

                    if (err.code != 'ERR_CANCELED') {
                        this.$router.push({ name: '404page' });
                    }
                })
            },
            goHome () {
                if (this.data.isAnnualIssue == 1) {
                    this.push.call(this, { name: 'bookSuccessionList', query: { id: this.$route.query.sid } })
                } else {
                    this.$router.push({ name: 'journalList', query: { id: this.$route.query.jid } })
                }
            },
        }),
        components: {
            NImage,
            Breadcrumb,
            EditOutlined
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .thumb {
        width: 250px;
        height: 320px;

        .thumb-image {
            transition: all 0.3s;
        }
    }

    .title-icon {
        background-color: rgb(255, 226, 186);
        color: rgb(255, 162, 66);
        padding: 5px;
    }

    .list {
        & > li {
            position: relative;

            i {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-image: $linear;
                background-color: transparent;
                opacity: 0;
                transition: all 0.3s;
            }

            &:hover {
                h1,
                p {
                    color: $color-base;
                }

                i {
                    opacity: 1;
                }
            }
        }
    }
</style>