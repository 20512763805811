<template>
    <div class="n-pb-4">
        <div class="c-banner c-banner-small n-col-1 n-border-box n-overflow-hidden">
            <NImage class="c-banner-image n-col-1 n-h-1"
                    type="cover"
                    :src="require('@/assets/image/banner.jpg')"></NImage>

            <div class="c-banner-text n-col-1 n-p-3">
                <h1 class="n-c-1 c-max-width n-text-center n-m-auto n-mb-1 c-f-1 n-f-1">{{LAN.welcome}}</h1>
            </div>
        </div>

        <div class="c-max-width n-m-auto n-bg-2 n-mt-4 n-plr-2">
            <h2 class="n-f-bold n-c-base c-title n-b-line n-t-line n-inline-block n-pb-1 n-mb-4">{{LAN.common.allOnline}}</h2>

            <a-collapse v-model:activeKey="active">
                <a-collapse-panel key="1"
                                  header="Arkiv for Matematik">
                    <ul class="n-ul n-p-0 n-m-0 n-border-3">
                        <li class="item n-flex n-flex-a-t">
                            <div class="left n-flex n-flex-dr-c n-flex-j-c n-flex-a-c n-flex-s">
                                <span class="c-bg-red n-c-base n-f-bold n-line-height-1 n-ptb-1 n-plr-2 n-radius-6">NEWEST</span>
                                <h3 class="n-m-0">2023年61卷</h3>
                            </div>

                            <div class="n-flex n-flex-w n-p-1">
                                <div v-for="item in 10"
                                     :key="item"
                                     class="right n-flex-s n-p-1">
                                    <div class="n-flex n-flex-a-c n-bg-2 n-p-2 n-radius-3 n-pointer"
                                         @click="push.call(this, {name: 'journalPanel', query: {from: 'online'}})">
                                        <GlobalOutlined class="n-c-2 c-f-1 n-mr-2" />

                                        <div class="n-flex-s">
                                            <h4 class="n-m-0">第{{item}}期</h4>
                                            <span class="n-keep-word n-f-2 n-c-2">pp.212-229</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </a-collapse-panel>
                <a-collapse-panel key="2"
                                  header="Acta Mathematica">
                    <ul class="n-ul n-p-0 n-m-0 n-border-3">
                        <li class="item n-flex n-flex-a-t">
                            <div class="left n-flex n-flex-dr-c n-flex-j-c n-flex-a-c n-flex-s">
                                <h3 class="n-m-0">2022年60卷</h3>
                            </div>

                            <div class="n-flex n-flex-w n-p-1">
                                <div v-for="item in 1"
                                     :key="item"
                                     class="right n-flex-s n-p-1">
                                    <div class="n-flex n-flex-a-c n-bg-2 n-p-2 n-radius-3 n-pointer"
                                         @click="push.call(this, {name: 'journalPanel', query: {from: 'online'}})">
                                        <GlobalOutlined class="n-c-2 c-f-1 n-mr-2" />

                                        <div class="n-flex-s">
                                            <h4 class="n-m-0">第{{item}}期</h4>
                                            <span class="n-keep-word n-f-2 n-c-2">pp.212-229</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </a-collapse-panel>
                <a-collapse-panel key="3"
                                  header="Advances in Theoretical and Mathematical Physics">
                    <ul class="n-ul n-p-0 n-m-0 n-border-3">
                        <li class="item n-flex n-flex-a-t">
                            <div class="left n-flex n-flex-dr-c n-flex-j-c n-flex-a-c n-flex-s">
                                <h3 class="n-m-0">2022年60卷</h3>
                            </div>

                            <div class="n-flex n-flex-w n-p-1">
                                <div v-for="item in 2"
                                     :key="item"
                                     class="right n-flex-s n-p-1">
                                    <div class="n-flex n-flex-a-c n-bg-2 n-p-2 n-radius-3 n-pointer"
                                         @click="push.call(this, {name: 'journalPanel', query: {from: 'online'}})">
                                        <GlobalOutlined class="n-c-2 c-f-1 n-mr-2" />

                                        <div class="n-flex-s">
                                            <h4 class="n-m-0">第{{item}}期</h4>
                                            <span class="n-keep-word n-f-2 n-c-2">pp.212-229</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </a-collapse-panel>
                <a-collapse-panel key="4"
                                  header="AsianJournal of Mathematics">
                    <ul class="n-ul n-p-0 n-m-0 n-border-3">
                        <li class="item n-flex n-flex-a-t">
                            <div class="left n-flex n-flex-dr-c n-flex-j-c n-flex-a-c n-flex-s">
                                <span class="c-bg-red n-c-base n-f-bold n-line-height-1 n-ptb-1 n-plr-2 n-radius-6">NEWEST</span>
                                <h3 class="n-m-0">2023年61卷</h3>
                            </div>

                            <div class="n-flex n-flex-w n-p-1">
                                <div v-for="item in 3"
                                     :key="item"
                                     class="right n-flex-s n-p-1">
                                    <div class="n-flex n-flex-a-c n-bg-2 n-p-2 n-radius-3 n-pointer"
                                         @click="push.call(this, {name: 'journalPanel'})">
                                        <GlobalOutlined class="n-c-2 c-f-1 n-mr-2" />

                                        <div class="n-flex-s">
                                            <h4 class="n-m-0">第{{item}}期</h4>
                                            <span class="n-keep-word n-f-2 n-c-2">pp.212-229</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </a-collapse-panel>
            </a-collapse>
        </div>
    </div>
</template>
<script >
    import { mapMutations, mapState } from 'vuex';
    import { StarOutlined, LikeOutlined, MessageOutlined, GlobalOutlined } from '@ant-design/icons-vue';
    import NImage from '@/components/nui/NImage/NImage.vue'

    import nui from '@/assets/script';

    export default {
        name: 'Online',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                active: "1"
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            NMounted () {
            }
        }),
        components: {
            StarOutlined,
            LikeOutlined,
            MessageOutlined,
            NImage,
            GlobalOutlined
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .item {
        border-bottom: 1px $line-color-3 solid;

        &:last-child {
            border: 0;
        }

        .left {
            border-right: 1px $line-color-3 solid;
            width: 120px;
        }

        .right {
            width: 155px;

            & > div {
                transition: all 0.3s;
            }

            & > div:hover {
                background-color: $background-red;
            }

            &:last-child > div {
                background-color: $background-red;
            }
        }
    }
</style>