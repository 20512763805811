<template>
    <div class="n-pb-4">
        <div class="c-banner c-banner-small n-col-1 n-border-box n-overflow-hidden">
            <NImage class="c-banner-image n-col-1 n-h-1"
                    type="cover"
                    :src="require('@/assets/image/banner.jpg')"></NImage>

            <div class="c-banner-text n-col-1 n-p-3">
                <h1 class="n-c-1 c-max-width n-text-center n-m-auto n-mb-1 c-f-1 n-f-1">{{LAN.welcome}}</h1>
            </div>
        </div>

        <div class="c-max-width n-m-auto n-bg-2 n-mt-4 n-plr-2">
            <h2 class="n-f-bold n-c-base c-title n-b-line n-t-line n-inline-block n-pb-1 n-mb-4">{{LAN.nav.bookSuccession}}<span class="n-inline-block n-f-normal n-ml-2 n-c-UI-blue n-f-2">{{LAN.common.total}} {{totalCount}} {{LAN.unit.x}}</span></h2>

            <a-row :gutter="[15,15]"
                   v-if="loading">
                <a-col :span="24"
                       :lg="8"
                       :key="item"
                       v-for="item in 4">
                    <div class="n-block c-shadow n-radius-6">
                        <div class="n-bg-1 n-p-4 n-flex n-flex-dr-c n-flex-j-sb n-flex-a-c">
                            <h4 class="n-skeleton n-skeleton-text n-col-1 n-ptb-2 n-mb-3"></h4>
                            <h4 class="n-skeleton n-skeleton-text n-col-2 n-ptb-5"></h4>
                        </div>
                    </div>
                </a-col>
            </a-row>

            <a-row :gutter="[15,15]"
                   v-else>
                <a-col :span="24"
                       :lg="8"
                       :key="index"
                       v-for="(item, index) in data">
                    <router-link :to="{name: 'bookSuccessionList', query: {from: 'bookSuccession', id: item.id, k: new Date().getTime()}}"
                                 custom
                                 v-slot="{ href }">
                        <a :href="href"
                           class="n-block n-h-1 c-shadow n-radius-6">
                            <div class="n-bg-1 n-p-4 n-pointer n-h-1 n-flex n-flex-dr-c n-flex-j-sb n-flex-a-c">
                                <h4 class="n-text-center n-plr-6 n-col-1">{{ item.tagName }}</h4>

                                <a-button type="primary"
                                          size="middle">{{item.fcount}} {{ LAN.unit.b1 }}</a-button>
                            </div>
                        </a>
                    </router-link>
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script >
    import { mapMutations, mapState } from 'vuex';
    import { StarOutlined, LikeOutlined, MessageOutlined, UndoOutlined } from '@ant-design/icons-vue';
    import NImage from '@/components/nui/NImage/NImage.vue'

    import nui from '@/assets/script';

    export default {
        name: 'BookSuccession',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                loading: false,

                totalCount: 0,

                data: []
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            NActivated () {
                this.loadData()
            },
            loadData () {
                this.loading = true

                this.request(this.API.bookSuccession).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE) {
                        this.data = res.data

                        this.totalCount = res.data.length
                    }

                    this.loading = false
                }).catch(err => {
                    this.loading = false
                })
            }
        }),
        components: {
            StarOutlined,
            LikeOutlined,
            MessageOutlined,
            NImage,
            UndoOutlined
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .list-thumb {
        width: 130px;
        height: 180px;

        .list-thumb-image {
            transition: all 0.3s;
        }
    }

    .list-item {
        transition: all 0.3s;

        &:hover {
            box-shadow: $shadow-2;
        }
    }
</style>