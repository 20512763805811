<template>
    <div class="n-pb-4">
        <div class="c-banner c-banner-small n-col-1 n-border-box n-overflow-hidden">
            <NImage class="c-banner-image n-col-1 n-h-1"
                    type="cover"
                    :src="require('@/assets/image/banner.jpg')"></NImage>

            <div class="c-banner-text n-col-1 n-p-3">
                <h1 class="n-c-1 c-max-width n-text-center n-m-auto n-mb-1 c-f-1 n-f-1">{{LAN.welcome}}</h1>
            </div>
        </div>

        <div class="c-max-width n-m-auto n-bg-2 n-mt-4 n-plr-2">

            <div class="title-container">
                <div id="title"
                     class="n-flex n-flex-a-c n-flex-j-sb search-row title-inner n-bg-2">
                    <h2 class="n-f-bold n-c-base c-title n-b-line n-t-line n-inline-block n-pb-1 n-mb-4">{{LAN.nav.bookLight}}<span class="n-inline-block n-f-normal n-ml-2 n-c-UI-blue n-f-2">{{LAN.common.total}} {{totalCount}} {{LAN.unit.b}}</span></h2>

                    <div class="n-mb-2 search-container">
                        <a-input-search v-model:value="keyword"
                                        :placeholder="LAN.nui.common.pleaseEnterKeyword"
                                        enter-button
                                        allowClear
                                        size="large"
                                        @search="search" />
                    </div>
                </div>
            </div>

            <a-row :gutter="[15,15]"
                   v-if="loading">
                <a-col :span="24"
                       :lg="12"
                       :key="item"
                       v-for="item in 4">
                    <div class="n-flex n-bg-1 n-p-4 n-pointer list-item">
                        <div class="list-thumb n-flex-s n-mr-3 n-skeleton"></div>
                        <div class="n-flex-g n-flex-g-w-1-hack">
                            <div class="n-skeleton n-skeleton-text n-ptb-6"></div>
                            <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-6"></div>
                            <div class="n-skeleton n-skeleton-text n-mt-6"></div>
                        </div>
                    </div>
                </a-col>
            </a-row>

            <a-row :gutter="[15,15]"
                   v-else-if="data.length > 0">
                <a-col :span="24"
                       :lg="12"
                       :key="item.id"
                       v-for="(item,index) in data">
                    <router-link :to="{name: 'BDetail', query: {from: 'bookLight', id: item.id, k: new Date().getTime()}}"
                                 custom
                                 v-slot="{ href }">
                        <a :href="href">
                            <div class="n-flex n-bg-1 n-p-4 n-pointer list-item">
                                <div class="list-thumb c-border n-flex-s n-mr-3">
                                    <NImage class="list-thumb-image n-col-1 n-h-1"
                                            type="cover"
                                            zoomView
                                            :k="index"
                                            :src="item.coverPicture"></NImage>
                                </div>
                                <div class="n-flex-g n-flex-g-w-1-hack">
                                    <h1 class="n-f-bold n-c-4 n-m-0 n-e-2"
                                        style="height: 50px; line-height: 25px"
                                        v-html="parseHilight(item.title, keyword)"></h1>

                                    <span class="n-e-1 n-c-2 n-block n-mt-2">{{ item.bookRelatedPerson.map(item => item.name).join('、') }}</span>
                                    <span class="n-c-2 n-block n-mt-2">{{LAN.common.publishDate +': '+ parseCDate(item.publication[0].publishDate)}}</span>
                                    <div class="n-flex n-flex-a-c n-flex-j-sb n-mt-2">
                                        <div class="n-flex n-flex-a-c n-line-height-1 c-line-hack"
                                             :class="getViewType(item.authLevelName).class">
                                            <i :class="getViewType(item.authLevelName).icon"
                                               class="n-mr-1"></i>
                                            {{ getViewType(item.authLevelName).text }}
                                        </div>
                                        <a-button type="primary"
                                                  size="middle">
                                            {{LAN.common.view}}
                                        </a-button>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </router-link>
                </a-col>
            </a-row>

            <a-row :gutter="[15,15]"
                   class="n-col-1"
                   v-else>
                <a-col class="n-bg-1 n-ptb-3"
                       span="24">
                    <a-empty :description="LAN.book.noBook"></a-empty>
                </a-col>
            </a-row>

            <a-pagination v-model:current="currentPage"
                          show-quick-jumper
                          class="n-mt-4"
                          v-if="false"
                          :pageSize="pageSize"
                          :total="totalCount"
                          @change="loadData" />
        </div>
    </div>
</template>
<script >
    import { mapMutations, mapState } from 'vuex';
    import { StarOutlined, LikeOutlined, MessageOutlined, UndoOutlined } from '@ant-design/icons-vue';
    import NImage from '@/components/nui/NImage/NImage.vue'

    import nui from '@/assets/script';

    export default {
        name: 'BookLight',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                keyword: '',
                loading: false,
                currentPage: 1,
                pageSize: 8,
                data: [],
                dataCache: [],
                totalCount: 0
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            NActivated () {
                this.$nextTick(() => {
                    var title = document.getElementById('title');

                    if (title) {
                        title.style.width = title.offsetWidth + 'px'
                        this.$root.titleMounted()
                    }
                })
            },
            NForward () {
                this.keyword = ''
                this.currentPage = 1
                this.loadData()
            },
            search () {
                this.data = this.dataCache.filter(item => item.title.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0)
            },
            loadData () {
                this.loading = true

                this.request(this.API.bookLight, {
                    params: {
                        current: this.currentPage,
                        size: 9999999,
                        tagName: 'Textbook'
                    }
                }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE) {
                        this.data = res.data.records
                        this.dataCache = res.data.records

                        this.totalCount = res.data.total
                    }

                    this.loading = false
                }).catch(err => {
                    this.loading = false
                })
            }
        }),
        components: {
            StarOutlined,
            LikeOutlined,
            MessageOutlined,
            NImage,
            UndoOutlined
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .list-thumb {
        width: 120px;
        height: 157px;

        .list-thumb-image {
            transition: all 0.3s;
        }
    }

    .list-item {
        transition: all 0.3s;

        &:hover {
            box-shadow: $shadow-2;
        }
    }

    @media screen and (max-width: 1100px) {
        .search-row {
            display: block;
        }
    }
</style>