/**
 * @description 这里是针对本应用的通用函数
 */
import nui from './nui';
import CONFIG from './config';

// var parseTot = null

export default {
    canBack () {
        return window.history.state && window.history.state.back
    },
    parseHilight (str, k, ctx) {
        var res = ''

        if (nui.strIsNull(k)) {
            res = str
        } else {
            res = str.replace(RegExp(k, "gi"), match => '<mark>' + match + '</mark>')
        }

        // parseTot && clearTimeout()

        // parseTot = setTimeout(() => {
        //     ctx && ctx.parseMathJax()
        // }, 400);
        var c = document.createElement('span')
        c.innerHTML = res;
        MathJax.typeset([c])

        return c.innerHTML
    },
    parseMathJax (dom = document.body) {
        MathJax.typesetPromise();
        // window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, dom])
    },
    parseCDate (str) {
        return str.substring(0, 7)
    },
    getViewType (type) {
        var checkType = type.toLowerCase()

        if (checkType == 'open') {
            return {
                class: 'n-c-green',
                text: type,
                icon: 'c-icon-open'
            }
        } else {
            return {
                class: 'n-c-red',
                text: type,
                icon: 'c-icon-lock'
            }
        }
    },
    push (param) {

        if (!param.query) {
            param.query = { k: new Date().getTime() }
        } else {
            param.query.k = new Date().getTime()
        }

        this.$router.push(param)
    },
}