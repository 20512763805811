<template>
    <div class="n-pb-4">
        <div class="c-banner c-banner-small n-col-1 n-border-box n-overflow-hidden">
            <NImage class="c-banner-image n-col-1 n-h-1"
                    type="cover"
                    :src="require('@/assets/image/banner.jpg')">
            </NImage>

            <div class="c-banner-text n-col-1 n-p-3">
                <h1 class="n-c-1 c-max-width n-text-center n-m-auto n-mb-1 c-f-1 n-f-1">{{ LAN.welcome }}</h1>
            </div>
        </div>

        <div class="c-max-width n-m-auto n-mt-3 n-plr-2">
            <a-row :gutter="[20, 0]"
                   class="n-flex-a-st">
                <a-col :lg="20"
                       :span="24">
                    <div class="n-flex n-flex-dr-c n-h-1">
                        <a-row class="n-flex-s"
                               :gutter="[20, 0]">
                            <a-col :span="24"
                                   style="padding: 0">
                                <div class="search-container n-flex n-flex-a-c n-pr-2 n-mb-2">
                                    <a-input-search v-model:value="keyword"
                                                    enter-button
                                                    allowClear
                                                    size="large"
                                                    :placeholder="LAN.nui.common.pleaseEnterKeyword"
                                                    @search="searchData" />
                                </div>
                            </a-col>
                        </a-row>
                        <a-row class="n-flex-g"
                               :gutter="[20, 0]">
                            <a-col :span="24"
                                   :lg="5"
                                   style="padding: 0">
                                <div id="menu"
                                     os="0"
                                     class="n-bg-1">
                                    <div class="n-plr-4 n-pt-3">
                                        <a-button type="primary"
                                                  size="large"
                                                  class="n-mr-6 category-button n-flex-s"
                                                  @click="showMenu = !showMnenu">
                                            <template #icon>
                                                <MenuUnfoldOutlined />
                                            </template>
                                        </a-button>

                                        <h2 class="n-f-bold n-c-base c-title n-b-line n-t-line n-inline-block n-pb-1">{{LAN.nav.mine }}</h2>
                                    </div>
                                    <div class="left-menu">
                                        <div class="n-plr-4">
                                            <ul class="n-p-0 n-ul n-m-0 n-pb-4 c-content-left">
                                                <li class="n-f-6 n-ptb-1 n-plr-2 n-pointer c-hover n-b-line"
                                                    :class="{ 'n-c-red n-selected': category == 2 }"
                                                    @click="changeCategory(2)">
                                                    {{ LAN.mine.subscribe }}</li>
                                                <li class="n-f-6 n-ptb-1 n-plr-2 n-pointer c-hover"
                                                    :class="{ 'n-c-red n-selected': category == 1 }"
                                                    @click="changeCategory(1)">
                                                    {{ LAN.mine.recent }}</li>
                                                <!-- <li class="n-f-6 n-pt-1 n-pb-2 n-pointer c-hover"
                                               :class="{ 'n-c-red': category == 3 }"
                                               @click="changeCategory(3)">
                                               {{ LAN.mine.message }}</li> -->
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </a-col>
                            <a-col :span="24"
                                   :lg="19">
                                <div class="n-h-1 n-bg-1 n-plr-3 n-pb-3">
                                    <a-tabs v-model:activeKey="subCategory1"
                                            size="large"
                                            v-if="category == 1"
                                            @change="tabChange">

                                        <template #rightExtra>
                                            <div class="n-flex n-flex-a-c n-flex-j-sb">
                                                <a-button type="primary"
                                                          shape="round"
                                                          class="n-ml-2"
                                                          v-if="canBack()"
                                                          @click="$router.go(-1)">
                                                    <template #icon>
                                                        <LeftOutlined />
                                                    </template>
                                                    {{ LAN.common.back }}
                                                </a-button>
                                            </div>
                                        </template>
                                        <a-tab-pane :key="11"
                                                    :tab="LAN.nav.QQ">

                                            <div v-if="loading">
                                                <ul class="n-ul n-p-0 list n-bg-1 n-mt-2">
                                                    <li class="n-b-line n-pointer n-p-4"
                                                        v-for="item in 3"
                                                        :key="item">
                                                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-col-3"></div>
                                                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div v-else-if="jHistoryList.length > 0"
                                                 class="n-bg-1 n-mt-2 mid-scroll n-scroll-v auto">
                                                <ul class="n-ul n-p-0 list">
                                                    <li class="n-b-line n-pointer n-p-2 c-hover"
                                                        v-for="item in jHistoryList"
                                                        :key="item.id"
                                                        @click="$router.push({ name: 'JDetail', params: { cid: item.id } })">
                                                        <div>
                                                            <p class="n-block n-f-6 n-f-bold c-hover-target n-m-0"
                                                               v-html="parseHilight(item.title, keyword, this)"></p>

                                                            <span class="n-block">{{ parseAuthors(item.authors) }}</span>

                                                            <div class="n-flex n-flex-a-e n-flex-j-sb">
                                                                <span class="n-block n-c-base"
                                                                      v-if="item.startPage && item.endPage">{{ LAN.journal.pages }}
                                                                    {{ item.startPage }}-{{ item.endPage }}</span>
                                                                <span v-else></span>
                                                                <div class="n-block n-col-3 n-text-right">
                                                                    <a-button type="primary">{{ LAN.common.view }}</a-button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <a-row :gutter="[15,15]"
                                                   class="n-col-1"
                                                   v-else>
                                                <a-col class="n-bg-1 n-ptb-3"
                                                       span="24">
                                                    <a-empty :description="LAN.mine.noHis"></a-empty>
                                                </a-col>
                                            </a-row>

                                            <span class="n-f-2 n-c-2 n-mt-2 n-block"
                                                  v-if="contentAll.updateTimeGmt">{{ LAN.common.updateTime }}: {{ contentAll.updateTimeGmt }} ({{contentAll.updateTimeLocal }})
                                            </span>
                                        </a-tab-pane>
                                        <a-tab-pane :key="12"
                                                    :tab="LAN.nav.book">

                                            <div v-if="loading">
                                                <ul class="n-ul n-p-0 list n-bg-1 n-mt-2">
                                                    <li class="n-b-line n-pointer n-p-4"
                                                        v-for="item in 3"
                                                        :key="item">
                                                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-col-3"></div>
                                                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div v-else-if="bHistoryList.length > 0"
                                                 class="n-bg-1 n-mt-2 mid-scroll n-scroll-v auto">
                                                <ul class="n-ul n-p-0 list">
                                                    <li class="n-b-line n-pointer n-p-2 c-hover"
                                                        v-for="item in bHistoryList"
                                                        :key="item.id"
                                                        @click="push.call(this, {name: 'BDetail', query: {from: 'mine', id: item.id}})">
                                                        <div>
                                                            <p class="n-block n-f-6 n-f-bold c-hover-target n-m-0"
                                                               v-html="parseHilight(item.title, keyword)"></p>

                                                            <div class="n-flex n-flex-a-e n-flex-j-sb">
                                                                <span class="n-c-2 n-block n-mt-2">{{LAN.common.publishDate +': '+ parseCDate(item.publication[0].publishDate)}}</span>
                                                                <div class="n-block n-col-3 n-text-right">
                                                                    <a-button type="primary">{{ LAN.common.view }}</a-button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <a-row :gutter="[15,15]"
                                                   class="n-col-1"
                                                   v-else>
                                                <a-col class="n-bg-1 n-ptb-3"
                                                       span="24">
                                                    <a-empty :description="LAN.mine.noHis"></a-empty>
                                                </a-col>
                                            </a-row>

                                            <span class="n-f-2 n-c-2 n-mt-2 n-block"
                                                  v-if="contentAll.updateTimeGmt">{{ LAN.common.updateTime }}: {{ contentAll.updateTimeGmt }} ({{contentAll.updateTimeLocal }})
                                            </span>

                                        </a-tab-pane>
                                    </a-tabs>
                                    <a-tabs v-model:activeKey="subCategory2"
                                            size="large"
                                            v-if="category == 2"
                                            @change="tabChange">

                                        <template #rightExtra>
                                            <div class="n-flex n-flex-a-c n-flex-j-sb">
                                                <a-button type="primary"
                                                          shape="round"
                                                          class="n-ml-2"
                                                          v-if="canBack()"
                                                          @click="$router.go(-1)">
                                                    <template #icon>
                                                        <LeftOutlined />
                                                    </template>
                                                    {{ LAN.common.back }}
                                                </a-button>
                                            </div>
                                        </template>
                                        <a-tab-pane :key="21"
                                                    :tab="LAN.nav.QQ">

                                            <div v-if="loading">
                                                <ul class="n-ul n-p-0 list n-bg-1 n-mt-2">
                                                    <li class="n-b-line n-pointer n-p-4"
                                                        v-for="item in 3"
                                                        :key="item">
                                                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-col-3"></div>
                                                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div v-else-if="jSubList.length > 0"
                                                 class="n-bg-1 n-mt-2 mid-scroll n-scroll-v auto">
                                                <a-row :gutter="[16,16]">
                                                    <a-col :span="12"
                                                           :lg="8"
                                                           v-for="item in jSubList"
                                                           :key="item.id"
                                                           @click="goThisVolume(item)">
                                                        <div class="n-border-3 n-p-3 n-h-1 n-flex n-flex-dr-c n-pointer c-hover c-hover-border">
                                                            <div class="n-flex-g icon-title">
                                                                <i class="n-icon n-icon-periodical n-icon-size-20"></i>
                                                                <a-tooltip :mouseEnterDelay=".3">
                                                                    <template #title>{{item.periodicalName}}</template>
                                                                    <h4 class="n-break-word c-hover-target"
                                                                        v-html="parseHilight(item.periodicalName, keyword)"></h4>
                                                                </a-tooltip>
                                                            </div>

                                                            <h2 class="n-f-bold n-m-0 n-mt-4">{{LAN.journal.volume}} {{item.volumeNum}} ({{item.volumeYear}})</h2>
                                                        </div>
                                                    </a-col>
                                                </a-row>
                                            </div>

                                            <a-row :gutter="[15,15]"
                                                   class="n-col-1"
                                                   v-else>
                                                <a-col class="n-bg-1 n-ptb-3"
                                                       span="24">
                                                    <a-empty :description="LAN.mine.noSub"></a-empty>
                                                </a-col>
                                            </a-row>

                                            <span class="n-f-2 n-c-2 n-mt-2 n-block"
                                                  v-if="contentAll.updateTimeGmt">{{ LAN.common.updateTime }}: {{ contentAll.updateTimeGmt }} ({{contentAll.updateTimeLocal }})
                                            </span>
                                        </a-tab-pane>
                                        <a-tab-pane :key="22"
                                                    :tab="LAN.nav.book">

                                            <div v-if="loading">
                                                <ul class="n-ul n-p-0 list n-bg-1 n-mt-2">
                                                    <li class="n-b-line n-pointer n-p-4"
                                                        v-for="item in 3"
                                                        :key="item">
                                                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-col-3"></div>
                                                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div v-else-if="bSubList.length > 0"
                                                 class="n-bg-1 n-mt-2 mid-scroll n-scroll-v auto">
                                                <a-row :gutter="[16,16]">
                                                    <a-col :span="12"
                                                           :lg="8"
                                                           v-for="item in bSubList"
                                                           :key="item.id"
                                                           @click="push.call(this, {name: 'BDetail', query: {from: 'mine', id: item.id}})">
                                                        <div class="n-border-3 n-p-3 n-h-1 n-flex n-flex-dr-c n-pointer c-hover c-hover-border">
                                                            <div class="n-flex-g icon-title">
                                                                <i class="n-icon n-icon-wp-sj-2 n-icon-size-20"></i>
                                                                <a-tooltip :mouseEnterDelay=".3">
                                                                    <template #title>{{item.title}}</template>
                                                                    <h4 class="n-break-word n-e-2 c-hover-target"
                                                                        v-html="parseHilight(item.title, keyword)"></h4>
                                                                </a-tooltip>
                                                            </div>

                                                            <span class="n-c-2 n-block n-mt-2">{{LAN.common.publishDate +': '+ parseCDate(item.publication[0].publishDate)}}</span>
                                                        </div>
                                                    </a-col>
                                                </a-row>
                                            </div>

                                            <a-row :gutter="[15,15]"
                                                   class="n-col-1"
                                                   v-else>
                                                <a-col class="n-bg-1 n-ptb-3"
                                                       span="24">
                                                    <a-empty :description="LAN.mine.noSub"></a-empty>
                                                </a-col>
                                            </a-row>

                                            <span class="n-f-2 n-c-2 n-mt-2 n-block"
                                                  v-if="contentAll.updateTimeGmt">{{ LAN.common.updateTime }}: {{ contentAll.updateTimeGmt }} ({{contentAll.updateTimeLocal }})
                                            </span>

                                        </a-tab-pane>
                                    </a-tabs>
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                </a-col>

                <a-col :span="24"
                       :lg="4"
                       style="padding: 0">

                    <div class="n-bg-1 n-p-3 n-h-1">
                        <div class="n-flex n-flex-a-c n-b-line n-pb-2">
                            <NImage :src="require('@/assets/image/user1.png')"
                                    type="cover"
                                    :zoomView="false"
                                    class="n-radius-circle avatar n-flex-s" />
                            <h4 class="n-f-10 n-m-0 n-ml-2 n-break-word n-line-height-1">{{userInfo.nick_name}}</h4>
                        </div>

                        <h4 class="n-f-6 n-m-0 n-mt-2 n-text-center">{{LAN.mine.open}}</h4>

                        <div v-if="accLoading"
                             class="n-bg-1 n-p-2 n-pb-4 n-flex n-flex-dr-c n-flex-a-c">
                            <div class="thumb n-flex-s n-mb-3 n-skeleton"></div>
                            <div class="n-skeleton n-skeleton-text n-ptb-3 n-mt-3 n-col-1"></div>
                            <div class="n-skeleton n-skeleton-text n-ptb-3 n-mt-3 n-col-1"></div>
                            <div class="n-skeleton n-skeleton-text n-ptb-3 n-mt-3 n-col-1"></div>
                        </div>

                        <div v-else>
                            <div class="n-bg-1 n-plr-2 n-pt-2">
                                <div v-for="(item,index) in accList"
                                     :key="item.id"
                                     class="n-flex n-flex-dr-c n-flex-a-c"
                                     :class="{'n-mt-4': index != 0}"
                                     @click="goJournalLP(item)">
                                    <h4 class="n-f-normal n-m-0 c-hover n-pointer n-text-center">{{item.periodicalName}}</h4>

                                    <div class="thumb n-flex-s n-mt-2 n-border-3">
                                        <NImage class="thumb-image n-col-1 n-h-1"
                                                type="cover"
                                                zoomView
                                                :src="item.coverPicture"></NImage>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>

    <a-drawer v-model:visible="showMenu"
              placement="left"
              width="70%">
        <ul class="n-p-0 n-ul n-m-0">
            <li class="n-f-6 n-ptb-2 n-pointer c-hover n-b-line"
                :class="{ 'n-c-red': category == 2 }"
                @click="changeCategory(2)">{{ LAN.mine.subscribe }}</li>
            <li class="n-f-6 n-ptb-2 n-pointer c-hover"
                :class="{ 'n-c-red': category == 1 }"
                @click="changeCategory(1)">{{ LAN.mine.recent }}</li>
            <!-- <li class="n-f-6 n-pt-1 n-pb-2 n-pointer c-hover n-b-line"
                :class="{ 'n-c-red': category == 3 }"
                @click="changeCategory(3)">{{ LAN.mine.message }}</li> -->
        </ul>
    </a-drawer>
</template>
<script>
    import { mapMutations, mapState } from 'vuex';
    import { getStore } from '@/assets/script/store';

    import { SwapLeftOutlined, SwapRightOutlined, MenuFoldOutlined, MenuUnfoldOutlined, CaretRightOutlined, UndoOutlined, GlobalOutlined } from '@ant-design/icons-vue';
    import NImage from '@/components/nui/NImage/NImage.vue'
    import Breadcrumb from '@/components/Breadcrumb.vue'
    import NEditorParserLocal from '@/components/NEditorParserLocal.vue';
    import nui from '@/assets/script';
    import category from '@/assets/script/category';

    export default {
        name: 'JournalList',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                loading: false,
                accLoading: false,
                active: [],
                keyword: '',
                category: 2,
                subCategory2: 21,
                subCategory1: 11,
                showMenu: false,
                columns: [],

                accList: [],
                jHistoryList: [],
                jHistoryListCache: [],
                bHistoryList: [],
                bHistoryListCache: [],

                jSubList: [],
                jSubListCache: [],
                bSubList: [],
                bSubListCache: [],

                userInfo: {},

                contentAll: {}
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            NActivated () {
                this.userInfo = getStore({ name: 'userInfo' }) || { type: '' };

                if (!this.loading) {
                    this.loadHistory(true)
                }
            },
            NMounted () {
                this.loadAcc()
                this.loadHistory()
            },
            changeCategory (index) {
                this.category = index;
                this.showMenu = false;
                this.loadHistory()
            },
            async loadHistory (background) {
                var hasLoad = this.jHistoryListCache.length <= 0 || this.bHistoryListCache.length <= 0 || this.jSubListCache.length <= 0 || this.bSubListCache.length <= 0;

                if (hasLoad) {
                    this.loading = true;
                }

                if ((this.jHistoryListCache.length <= 0 && this.category == 1 && this.subCategory1 == 11) || background) {
                    await this.request(this.API.journalHistory, {
                        requestId: 'journalHistory'
                    }).then(res => {
                        if (res.code == this.REQUEST_SUCCESS_CODE) {
                            this.jHistoryList = res.data.records
                            this.jHistoryListCache = res.data.records
                        }
                    })
                }


                if ((this.bHistoryListCache.length <= 0 && this.category == 1 && this.subCategory1 == 12) || background) {
                    await this.request(this.API.bookHistory, {
                        requestId: 'bookHistory'
                    }).then(res => {
                        if (res.code == this.REQUEST_SUCCESS_CODE) {
                            this.bHistoryList = res.data.records
                            this.bHistoryListCache = res.data.records
                        }
                    })
                }

                if ((this.jSubListCache.length <= 0 && this.category == 2 && this.subCategory2 == 21) || background) {
                    await this.request(this.API.jSub, {
                        requestId: 'jSub'
                    }).then(res => {
                        if (res.code == this.REQUEST_SUCCESS_CODE) {
                            this.jSubList = res.data.records
                            this.jSubListCache = res.data.records
                        }
                    })
                }

                if ((this.bSubListCache.length <= 0 && this.category == 2 && this.subCategory2 == 22) || background) {
                    await this.request(this.API.bSub, {
                        requestId: 'bSub'
                    }).then(res => {
                        if (res.code == this.REQUEST_SUCCESS_CODE) {
                            this.bSubList = res.data.records
                            this.bSubListCache = res.data.records
                        }
                    })
                }

                if (hasLoad) {
                    this.searchData()
                }

                this.loading = false

                this.$nextTick(() => {
                    this.parseMathJax()
                })
            },
            /**
             * @description open acc
             */
            loadAcc () {
                this.accLoading = true;

                this.request(this.API.userOpenAcc, {
                    requestId: 'userOpenAcc'
                }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE) {
                        this.accList = res.data.records.slice(0, 2)
                    }

                    this.accLoading = false
                }).catch(err => {
                    this.accLoading = false
                })
            },
            searchData () {
                this.jHistoryList = this.extend([], this.jHistoryListCache.filter(item => {
                    return item.title.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0
                }))

                this.bHistoryList = this.extend([], this.bHistoryListCache.filter(item => {
                    return item.title.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0
                }))

                this.jSubList = this.extend([], this.jSubListCache.filter(item => {
                    return item.periodicalName.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0
                }))

                this.bSubList = this.extend([], this.bSubListCache.filter(item => {
                    return item.title.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0
                }))

                this.$nextTick(() => {
                    this.parseMathJax()
                })
            },
            goThisVolume (data) {
                if (data.onlyExternalPublish == 1) {
                    window.open(data.externalSiteUrl, '_blank')
                } else {
                    this.$router.push({ name: 'journalList', query: { p: 5, id: data.periodicalId, openVId: data.volumeId } })
                }
            },
            parseAuthors (data = []) {
                var res = JSON.parse(JSON.stringify(data.map(item => item.fullName)))

                if (res.length > 1) {
                    if (res.length == 2) {
                        return res.join(' and ')
                    } else {
                        res[res.length - 1] = 'and ' + res[res.length - 1]
                        return res.join(', ')
                    }
                } else {
                    return res.join(', ')
                }
            },
            tabChange () {
                this.loadHistory()
            },
            goJournalLP (data) {
                if (data.isAnnualIssue == 1) {
                    this.push.call(this, { name: 'bookSuccessionList', query: { id: data.serialId } })
                } else {
                    this.push.call(this, { name: 'journalList', query: { id: data.periodicalId } })
                }
            }
        }),
        components: {
            SwapLeftOutlined,
            SwapRightOutlined,
            MenuFoldOutlined,
            MenuUnfoldOutlined,
            NEditorParserLocal,
            CaretRightOutlined,
            NImage,
            UndoOutlined,
            Breadcrumb,
            GlobalOutlined
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .thumb {
        width: 180px;
        height: 220px;

        .thumb-image {
            transition: all 0.3s;
        }
    }

    .avatar {
        height: 50px;
        width: 50px;
    }

    .category-button {
        display: none;
    }

    .c-title {
        display: block;

        &:before {
            width: 90%;
        }

        &:after {
            width: 100%;
        }
    }

    .icon-title {
        position: relative;

        i {
            position: absolute;
            left: 0;
            top: -8px;
        }

        h4 {
            text-indent: 25px;
        }
    }

    .mid-scroll {
        max-height: calc(100vh - 430px);
    }

    @media screen and (max-width: 1100px) {
        .left-menu {
            display: none;
        }

        .c-title {
            display: none;
        }

        .category-button {
            display: inline-block;
        }

        .search-container {
            padding-left: $margin-padding-2;
        }

        .mid-scroll,
        .right-scroll {
            max-height: 999999px;
        }
    }
</style>