import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import config from '../assets/script/config';

import Home from '@/views/Home.vue';
import Journal from '@/views/Journal.vue';
import JournalOnline from '@/views/JournalOnline.vue';
import JournalList from '@/views/JournalList.vue';
import JournalPanel from '@/views/JournalPanel.vue'
import JournalAnnualPanel from '@/views/JournalAnnualPanel.vue'
import Online from '@/views/Online.vue';
import Book from '@/views/Book.vue';
import BookList from '@/views/BookList.vue';
import BookLight from '@/views/BookLight.vue';
import BookSuccession from '@/views/BookSuccession.vue'
import BookSuccessionList from '@/views/BookSuccessionList.vue'
import BDetail from '@/views/BDetail.vue';
import JDetail from '@/views/JDetail.vue'
import Contact from '@/views/Contact.vue'
import Ordering from '@/views/Ordering.vue'
import Mine from '@/views/Mine.vue'
import JournalAbbr from '@/views/JournalAbbr.vue'

const routes = [{
    path: '/',
    redirect: '/home',
    meta: { keepAlive: true, title: 'APPName' },
}, {
    path: '/home',
    name: 'home',
    component: Home,
    meta: { keepAlive: true, title: 'APPName' },
}, {
    path: '/journals',
    name: 'journals',
    redirect: '/journals/journal',
    meta: { keepAlive: true, title: 'APPName' },
    children: [{
        path: 'journalOnline',
        name: 'journalOnline',
        component: JournalOnline,
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: 'journal',
        name: 'journal',
        component: Journal,
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: 'journalList',
        name: 'journalList',
        component: JournalList,
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: 'journalPanel',
        name: 'journalPanel',
        component: JournalPanel,
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: 'journalAnnualPanel',
        name: 'journalAnnualPanel',
        component: JournalAnnualPanel,
        meta: { keepAlive: true, title: 'APPName' },
        // }, {
        //     path: 'JDetail/:cid/:viewtype?',
        //     name: 'JDetailBack',
        //     component: JDetail,
        //     meta: { keepAlive: true, title: 'APPName' },
    }]
}, {
    path: '/JDetail/:cid/:viewtype?',
    name: 'JDetail',
    component: JDetail,
    meta: { keepAlive: true, title: 'APPName' },
}, {
    path: '/online',
    name: 'online',
    component: Online,
    meta: { keepAlive: true, title: 'APPName' },
}, {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: { keepAlive: true, title: 'APPName' },
}, {
    path: '/ordering',
    name: 'ordering',
    component: Ordering,
    meta: { keepAlive: true, title: 'APPName' },
}, {
    path: '/mine',
    name: 'mine',
    component: Mine,
    meta: { keepAlive: true, title: 'APPName' },
}, {
    path: '/books',
    name: 'books',
    redirect: '/books/book',
    meta: { keepAlive: true, title: 'APPName' },
    children: [{
        path: 'book',
        name: 'book',
        component: Book,
        meta: { keepAlive: true, title: 'APPName' }
    }, {
        path: 'bookLight',
        name: 'bookLight',
        component: BookLight,
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: 'bookList',
        name: 'bookList',
        component: BookList,
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: 'bookSuccession',
        name: 'bookSuccession',
        component: BookSuccession,
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: 'bookSuccessionList',
        name: 'bookSuccessionList',
        component: BookSuccessionList,
        meta: { keepAlive: true, title: 'APPName' },
    }, {
        path: '/BDetail',
        name: 'BDetail',
        component: BDetail,
        meta: { keepAlive: true, title: 'APPName' },
    }]
}, {
    path: '/journalabbr',
    name: 'journalabbr',
    component: JournalAbbr,
    meta: { keepAlive: true, title: 'APPName' },
}, {
    //404 到首页
    path: '/404',
    name: '404page',
    redirect: '/home',
    meta: { keepAlive: true, title: 'APPName' },
}, {
    // 未知路由重定向
    path: '/:pathMatch(.*)',
    redirect: '/journalabbr',
    hidden: true
}];
//createWebHistory
//createWebHashHistory
const router = createRouter({
    history: createWebHistory(),
    base: config.PBASE,
    routes
});

// const routerPush = VueRouter.prototype.push,
//     routerReplace = VueRouter.prototype.replace;

// VueRouter.prototype.push = function push (location) {
//     return routerPush.call(this, location).catch(error => error)
// }

// VueRouter.prototype.replace = function push (location) {
//     return routerReplace.call(this, location).catch(error => error)
// }

export default router;
