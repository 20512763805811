import functions from './functions'; // 引入functions.js
import nui from './nui'; // 引入NUI基础函数base.js
import API from './API' //引入接口定义
import config from './config' //配置项
import { message } from 'ant-design-vue';

/**
 * @description VUE 模块基础配置，一定要使用此配置不要使用VUE官方配置，这个模块是向下兼容的
 */
let extendVue = {
    /**
     * @description 组件默认计算数据，此数据会默认加载到VUE组件的mapState中
     */
    extendState: ['LAN', 'LANI', 'isMobileByWinWidth', 'isMobileByTouch', 'orientation', 'historyState', 'safeAreaData'],
    /**
    * @description 组件默认计算番薯，此数据会默认加载到VUE组件的mapMutations中
    */
    extendMutations: ['setLanguage'],
    /**
     * @description VUE生命周期同去掉N前缀的函数同名
     */
    extendMethods: {
        ...message,
        ...functions,
        ...nui
    },
    /**
     * @description 全局默认数据，次数据会被默认加载到VUE组件的 data 中
     */
    extendData: {
        cahceK: null,
        breadcrumb: [],
        ...config,
        API
    },
    /**
     * @description VUE组件生命周期
     */
    extendLife: {
        updated: function () {
            this.NUpdated && this.NUpdated();
        },
        activated: function () {
            this.NActivated && this.NActivated();

            if (this.$route.query.k != this.cacheK) {
                this.cacheK = this.$route.query.k;
                this.NForward && this.NForward()
            }
        },
        mounted: function () {
            this.NMounted && this.NMounted();
        },
        beforeDestroy: function () {
            this.NBeforeDestroy && this.NBeforeDestroy();
        },
        deactivated: function () {
            this.NDeactivated && this.NDeactivated();
        }
    }
};

// 将function nui base集成导出，方便调用
export default {
    ...functions,
    ...nui,
    extendVue,
    config,
    API: API
};
