let tap = {};

function touchStart (param, e) {
    var el = param[0],
        binding = param[1];

    binding.modifiers.stop && e.stopPropagation();
    binding.modifiers.prevent && e.preventDefault();

    if (!binding.modifiers.preventBlur) {
        document.activeElement.blur();
    }

    var obj = e.targetTouches[0];

    el.startX = obj.pageX;
    el.startY = obj.pageY;
    el.startTime = new Date().getTime();

    let parents = getParents(e.target)

    if (e.target.disabled || e.target.getAttribute('disabled')) {
        return
    }

    for (var i = 0; i < parents.length; i++) {
        if (parents[i].disabled || (parents[i].getAttribute && parents[i].getAttribute('disabled'))) {
            return
        }
    }

    binding.value.start && binding.value.start.call(this, binding.value.param !== undefined ? binding.value.param : e, e);
}

function touchMove (param, e) {
    var binding = param[1];

    binding.value.move && binding.value.move.call(this, binding.value.param !== undefined ? binding.value.param : e, e);
}

function touchEnd (param, e) {
    var el = param[0],
        binding = param[1];

    binding.modifiers.stop && e.stopPropagation();
    binding.modifiers.prevent && e.preventDefault();

    var obj = e.changedTouches[0];

    el.endX = obj.pageX;
    el.endY = obj.pageY;
    el.endTime = new Date().getTime();

    let parents = getParents(e.target)


    if (e.target.disabled || e.target.getAttribute('disabled')) {
        return
    }


    for (var i = 0; i < parents.length; i++) {
        if (parents[i].disabled || (parents[i].getAttribute && parents[i].getAttribute('disabled'))) {
            return
        }
    }

    if (el.endTime - el.startTime < 300 && Math.abs(el.endY - el.startY) < 10 && Math.abs(el.endX - el.startX) < 10) {
        if (binding.modifiers.delay) {
            setTimeout(() => {
                binding.value.tap && binding.value.tap.call(this, binding.value.param !== undefined ? binding.value.param : obj, obj);
            }, 100);
        } else {
            binding.value.tap && binding.value.tap.call(this, binding.value.param !== undefined ? binding.value.param : obj, obj);
        }
    }
    binding.value.end && binding.value.end.call(this, binding.value.param !== undefined ? binding.value.param : e, e);

}

// tap.install = function (Vue) {
//     Vue.directive('tap',);
// };

function getParents (el) {
    const parentSelector = document;

    let parents = [],
        p = el.parentNode;

    while (p && p !== parentSelector) {
        let o = p;
        parents.push(o);
        p = o.parentNode;
    }

    parents.push(parentSelector);
    return parents;
}

export default {
    mounted: function (el, binding) {
        el.tapParam = { startX: null, startY: null, endX: null, endY: null, startTime: null, endTime: null }

        if (document.body.ontouchstart !== undefined || binding.tap) {
            el.addEventListener('touchstart', touchStart.bind(this, [el, binding]), { passive: false });
            el.addEventListener('touchmove', touchMove.bind(this, [el, binding]), { passive: false });
            el.addEventListener('touchend', touchEnd.bind(this, [el, binding]), { passive: false });
        } else {
            el.addEventListener('click', function (e) {
                binding.modifiers.stop && e.stopPropagation();
                binding.modifiers.prevent && e.preventDefault();

                binding.value.tap && binding.value.tap.call(this, binding.value.param !== undefined ? binding.value.param : e, e);
            })

            el.addEventListener('mousedown', function (e) {

                binding.modifiers.stop && e.stopPropagation();
                binding.modifiers.prevent && e.preventDefault();

                e.targetTouches = [e];
                binding.value.start && binding.value.start.call(this, binding.value.param !== undefined ? binding.value.param : e, e);
            })

            el.addEventListener('mousemove', function (e) {

                binding.modifiers.stop && e.stopPropagation();
                binding.modifiers.prevent && e.preventDefault();

                e.changedTouches = [e];
                binding.value.move && binding.value.move.call(this, binding.value.param !== undefined ? binding.value.param : e, e);
            })

            el.addEventListener('mouseup', function (e) {

                binding.modifiers.stop && e.stopPropagation();
                binding.modifiers.prevent && e.preventDefault();

                e.changedTouches = [e];
                binding.value.end && binding.value.end.call(this, binding.value.param !== undefined ? binding.value.param : e, e);
            })
        }
    },
    unmounted: function (el) {
        el.removeEventListener('touchstart', touchStart);
        el.removeEventListener('touchend', touchEnd)
    }
}