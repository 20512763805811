<template>
    <a-config-provider :locale="antdLAN">
        <a-layout class="n-mvh-1"
                  v-if="browserSuport"
                  :class="'language-' + LANI">
            <a-layout class="n-layout-content n-flex n-flex-dr-c">
                <a-layout-header class="c-header-container n-flex-s">
                    <Header @organizationlogin="handleOrganizationLogin"
                            @personallogin="handlePersonalLogin"
                            @logout="handleLogout"
                            :organiztionHasAuth="organiztionHasAuth"
                            :userInfo="userInfo"></Header>
                </a-layout-header>
                <a-layout-content class="n-col-1 n-flex-g n-bg-2 n-scroll-v n-flex-g-h-1-hack"
                                  id="body"
                                  @scroll="bodyScroll">
                    <router-view v-slot="{ Component,route }">
                        <keep-alive>
                            <component :is="Component"
                                       :key="route.fullPath"
                                       ref="router" />
                        </keep-alive>
                    </router-view>
                </a-layout-content>
                <a-layout-footer class="n-bg-1 n-flex-s n-p-2 n-text-center c-shadow-1"
                                 style="position: relative;z-index: 10;">
                    <div class="n-flex n-flex-j-sb c-max-width n-m-auto n-flex-a-c"
                         ref="footer">
                        <div class="n-m-0 n-text-center n-col-1">{{ cr }}
                            <br />
                            <a href="https://www.un-bug.com/"
                               target="_blank"
                               style="color:#0A87FD">Technical Support Pigeon Cloud</a>
                        </div>
                        <!-- <a-dropdown v-model:visible="showLanguage">
                            <span class="n-flex n-flex-a-c n-c-base n-pointer"
                                  @click.prevent>
                                {{LAN.languageStr[LANI]}}
                                <UpOutlined class="n-ml-1" />
                            </span>
                            <template #overlay>
                                <a-menu @click="e => {setLanguage(e.key); showLanguage = false}">
                                    <a-menu-item key="0">中文</a-menu-item>
                                    <a-menu-item key="1">English</a-menu-item>
                                </a-menu>
                            </template>
                        </a-dropdown> -->
                    </div>
                </a-layout-footer>
            </a-layout>
        </a-layout>

        <h1 v-else
            class="n-browser">{{LAN.nui.browser}}</h1>

        <a-back-top :visibilityHeight="100"
                    :target="() => body"
                    v-if="body" />
    </a-config-provider>
</template>
<script >
    import { mapMutations, mapState } from 'vuex';
    import zhCN from 'ant-design-vue/es/locale/zh_CN';
    import enUS from 'ant-design-vue/es/locale/en_US';
    import nui from '@/assets/script';
    import LAN from '@/assets/script/LAN';

    import { UpOutlined } from '@ant-design/icons-vue';
    import Header from '@/components/Header.vue'
    import { getStore, setStore, removeStore } from '@/assets/script/store';
    import { setToken, setRefreshToken, removeToken, removeRefreshToken, getRefreshToken } from '@/assets/script/auth'


    export default {
        name: 'App',
        computed: {
            ...mapState(nui.extendVue.extendState.concat(['scrollInfo', 'windowWidth'])),

            /**
             * @description 检测浏览器兼容性
             */
            browserSuport () {
                var userAgent = navigator.userAgent,
                    ie6 = (/msie\s*(\d+)\.\d+/g.exec(userAgent.toLowerCase()) || [0, "0"])[1] == "6",
                    ie7 = userAgent.indexOf('MSIE 7.0') > -1,
                    ie8 = userAgent.indexOf('MSIE 8.0') > -1,
                    ie9 = userAgent.indexOf('MSIE 9.0') > -1;

                return !((ie6 || ie7 || ie8 || ie9) && location.href.indexOf('browser') <= 0)
            }
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                showLanguage: false,
                antdLAN: zhCN,
                body: null,
                cr: '-',
                techweblink: "",
                userInfo: { nick_name: "", type: "" },
                organiztionHasAuth: false
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat(['setWindowSize', 'setLevel', 'setStatus', 'setScroll', 'setIsMobileByWinWidth'])),

            async NMounted () {
                window.addEventListener('resize', this.setWindowSize)

                this.$nextTick(() => {
                    this.body = document.getElementById('body')
                })

                await this.checkLogin();

                this.request(this.API.cr, {
                    autoCancel: false
                }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE) {
                        this.cr = res.data.paramValue;
                    }
                });

                this.request(this.API.techweblink, {
                    autoCancel: false
                }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE) {
                        this.techweblink = res.data.paramValue;
                    }
                });

                this.setIsMobileByWinWidth()

                window.onresize = () => {
                    this.setIsMobileByWinWidth()
                }

                this.setLanguage(navigator.language == 'zh-CN' ? 0 : 1)
                this.antdLAN = navigator.language == 'zh-CN' ? zhCN : enUS;

                this.setLanguage(1)
                this.antdLAN = enUS;

                this.$nextTick(() => {
                    var menu = document.getElementById('menu');

                    if (menu) {
                        menu.style.width = menu.offsetWidth + 'px'
                    }
                })
            },
            menuMounted () {
                if (this.windowWidth > 992) {
                    var header = document.getElementById('header'),
                        menu = document.getElementById('menu');

                    menu.style.position = 'static'

                    if (menu.getBoundingClientRect().top - header.offsetHeight <= 0) {
                        menu.style.top = header.offsetHeight + 20 + 'px'
                        menu.style.position = 'fixed'
                    }

                    if (menu.querySelector('.c-content-left')) {
                        var h2 = menu.querySelector('h2') ? menu.querySelector('h2').offsetHeight : 0,
                            t = menu.getBoundingClientRect().top,
                            f = this.$refs.footer.offsetHeight,
                            all = h2 + t + f + 70;

                        menu.querySelector('.c-content-left').style.maxHeight = 'calc(100vh - ' + all + 'px)'
                    }
                }
            },
            titleMounted () {
                var header = document.getElementById('header'),
                    title = document.getElementById('title');

                title.style.position = 'absolute'
                title.style.top = '0'
                title.style.padding = "0"

                if (title && header) {
                    if (title.getBoundingClientRect().top - header.offsetHeight <= 0) {
                        title.style.top = header.offsetHeight + 'px'
                        title.style.position = 'fixed'

                        if (this.isMobileByWinWidth) {
                            title.style.padding = "10px 0"
                        } else {
                            title.style.padding = "0"
                        }
                    }
                }
            },
            sortMounted () {
                var header = document.getElementById('header'),
                    sort = document.getElementById('sort');

                sort.style.position = 'absolute'
                sort.style.top = '0'
                sort.style.padding = "0"

                if (sort && header) {
                    if (sort.getBoundingClientRect().top - header.offsetHeight <= 0) {
                        sort.style.top = header.offsetHeight + 'px'
                        sort.style.position = 'fixed'

                        if (this.isMobileByWinWidth) {
                            sort.style.padding = "10px 0"
                        } else {
                            sort.style.padding = "10px"
                        }
                    }
                }
            },
            bodyScroll (e) {
                var type = e.target.scrollTop > this.scrollInfo.y ? 'down' : 'up'

                this.setScroll({ y: e.target.scrollTop })

                var menu = document.getElementById('menu'),
                    header = document.getElementById('header'),
                    sort = document.getElementById('sort'),
                    title = document.getElementById('title');

                if (menu) {
                    menu.style.width = menu.offsetWidth + 'px'
                }

                if (menu && header && this.windowWidth > 992) {
                    if (type == 'down') {
                        if (menu.getBoundingClientRect().top - header.offsetHeight <= 0) {
                            menu.style.top = header.offsetHeight + parseInt(menu.getAttribute('os') || 20) + 'px'
                            menu.style.position = 'fixed'

                            if (menu.querySelector('.c-content-left')) {
                                var h2 = menu.querySelector('h2') ? menu.querySelector('h2').offsetHeight : 0,
                                    t = menu.getBoundingClientRect().top,
                                    f = this.$refs.footer.offsetHeight,
                                    all = h2 + t + f + 70;

                                menu.querySelector('.c-content-left').style.maxHeight = 'calc(100vh - ' + all + 'px)'
                            }
                        }
                    } else {
                        if (e.target.scrollTop <= 20) {
                            menu.style.position = 'static'

                            if (menu.querySelector('.c-content-left')) {
                                var h2 = menu.querySelector('h2') ? menu.querySelector('h2').offsetHeight : 0,
                                    t = menu.getBoundingClientRect().top,
                                    f = this.$refs.footer.offsetHeight,
                                    all = h2 + t + f + 70;

                                menu.querySelector('.c-content-left').style.maxHeight = 'calc(100vh - ' + all + 'px)'
                            }
                        }
                    }
                }

                if (sort && header) {
                    if (type == 'down') {
                        if (sort.getBoundingClientRect().top - header.offsetHeight <= 0) {
                            sort.style.top = header.offsetHeight + 'px'
                            sort.style.position = 'fixed'

                            if (this.isMobileByWinWidth) {
                                sort.style.padding = "10px 0"
                            } else {
                                sort.style.padding = "10px"
                            }
                        }
                    } else {
                        if (e.target.scrollTop <= 20) {
                            sort.style.position = 'absolute'
                            sort.style.top = '0'
                            sort.style.padding = "0"
                        }
                    }
                }

                if (title && header) {
                    if (type == 'down') {
                        if (title.getBoundingClientRect().top - header.offsetHeight <= 0) {
                            title.style.top = header.offsetHeight + 'px'
                            title.style.position = 'fixed'

                            if (this.isMobileByWinWidth) {
                                title.style.padding = "10px 0"
                            } else {
                                title.style.paddingTop = "10px"
                            }
                        }
                    } else {
                        if (e.target.scrollTop <= 20) {
                            title.style.position = 'absolute'
                            title.style.top = '0'
                            title.style.padding = "0"
                        }
                    }
                }
            },
            async checkLogin () {
                this.userInfo = getStore({ name: 'userInfo' }) || { type: '' };

                if ((this.userInfo.type || '') == '') {
                    await this.handleOrganizationLogin();
                } else {
                    this.handelRefreshToken();
                    // if ((this.userInfo.type || '') == 'personal') {
                    this.handleOrganizationLogin(true);
                    // }

                }
            },
            async handleOrganizationLogin (onlyCheck) {
                if (onlyCheck == undefined) {
                    onlyCheck = false;
                }
                await this.request(this.API.organiztionLogin, {
                    method: 'post',
                    params: {
                        tenantId: "000000",
                        grant_type: "organization_ip"
                    },
                    headers: { "User-Type": "organization" }
                }).then(res => {
                    const data = res || {};
                    this.organiztionHasAuth = (data.type || '') != '';

                    // 无论用户是个人还是组织，都缓存他是不是组织
                    this.$store.commit('SETISGROUP', data.type == 'organization' ? 1 : 0)

                    if (onlyCheck) {
                        return;
                    } else {
                        if ((data.code || 200) == 401) {
                            this.clearToken();
                            return;
                        }
                    }

                    if ((data.error_description || '') != '') {
                        alert(data.error_description);
                    } else {
                        setToken(data.access_token);
                        setStore({ name: 'token', content: data.access_token })

                        setRefreshToken(data.refresh_token)
                        setStore({ name: 'refreshToken', content: data.refresh_token })

                        setStore({ name: 'tenantId', content: data.tenant_id })
                        setStore({ name: 'userInfo', content: data })


                        this.userInfo = data;
                        // window.location.href = "/";
                    }
                }).catch(err => {
                })
            },
            handelRefreshToken () {
                this.request(this.API.organiztionLogin,
                    {
                        method: 'post',
                        params: {
                            refresh_token: getRefreshToken(),
                            tenantId: '000000',
                            grant_type: "refresh_token",
                            scope: "all",
                            action: "refresh_token"
                        }
                    }
                ).then(res => {
                    const data = res || {};
                    // this.organiztionHasAuth = (data.type || '') == 'organization';
                    if ((data.code || 200) == 401) {
                        this.clearToken();
                        return;
                    }

                    setToken(data.access_token);
                    setStore({ name: 'token', content: data.access_token })

                    setRefreshToken(data.refresh_token)
                    setStore({ name: 'refreshToken', content: data.refresh_token })

                    setStore({ name: 'tenantId', content: data.tenant_id })
                    setStore({ name: 'userInfo', content: data })
                }).catch(error => {

                });
            },
            handlePersonalLogin () {
                this.clearToken();
                if (window.location.href.lastIndexOf("localhost") > -1)
                    window.location.href = "http://localhost:1889/#/portallogin";
                else
                    window.location.href = "/portal/#/portallogin";
            },
            handleLogout () {
                this.clearToken();
                window.location.href = "/";
            },
            clearToken () {
                this.userInfo = { nick_name: "", type: "" };
                removeStore({ name: 'userInfo' });
                removeStore({ name: 'token' });
                removeStore({ name: 'tenantId' });
                removeStore({ name: 'refreshToken' });
                removeToken();
                removeRefreshToken();
            }
        }),
        components: {
            Header,
            UpOutlined
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    #body {
        transition: all 0.1s;
    }
    .c-header-container {
        padding: 0;
        height: auto;
    }
</style>