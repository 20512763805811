<template>
    <div>
        <div class="c-banner c-banner-small n-col-1 n-border-box n-overflow-hidden">
            <NImage class="c-banner-image n-col-1 n-h-1"
                    type="cover"
                    :src="require('@/assets/image/banner.jpg')">
            </NImage>

            <div class="c-banner-text n-col-1 n-p-3">
                <h1 class="n-c-1 c-max-width n-text-center n-m-auto n-mb-1 c-f-1 n-f-1">{{ LAN.welcome }}</h1>
            </div>
        </div>

        <div class="c-max-width n-m-auto n-mt-3 n-plr-2">
            <a-row :gutter="[20, 0]">
                <a-col :span="24"
                       :lg="6">
                    <div v-if="loading"
                         class="n-bg-1 n-p-2 n-pb-4 n-flex n-flex-dr-c n-flex-a-c">
                        <div class="thumb n-flex-s n-mb-3 n-skeleton"></div>
                        <div class="n-skeleton n-skeleton-text n-ptb-3 n-mt-3 n-col-1"></div>
                        <div class="n-skeleton n-skeleton-text n-ptb-3 n-mt-3 n-col-1"></div>
                        <div class="n-skeleton n-skeleton-text n-ptb-3 n-mt-3 n-col-1"></div>
                    </div>

                    <div v-else>
                        <div class="n-bg-1 n-plr-2 n-pt-2 n-flex n-flex-dr-c n-flex-a-c"
                             :class="data.isAnnualIssue == 1 ? 'n-pb-4' : ''">
                            <h3 class="n-f-normal n-m-0 c-hover n-pointer n-text-center"
                                @click="goHome">{{
                data.name }}
                            </h3>

                            <div class="thumb n-flex-s n-mt-2 n-border-3">
                                <NImage class="thumb-image n-col-1 n-h-1"
                                        type="cover"
                                        zoomView
                                        :src="volumeData.coverPicture"></NImage>
                            </div>
                        </div>

                        <div class="n-bg-1 n-plr-2 n-pb-2">
                            <h3 class="n-f-normal n-m-0 n-ptb-2 n-text-center c-hover n-pointer c-link"
                                @click="goHome">{{ LAN.journal.journalHome }}</h3>
                            <h3 class="n-f-normal n-m-0 n-ptb-2 n-text-center c-hover n-pointer c-link"
                                v-if="data.isOnline == 1"
                                @click="goOnline">{{ LAN.journal.allVolumes }}</h3>
                            <h3 class="n-f-normal n-m-0 n-ptb-2 n-text-center c-hover n-pointer c-link"
                                v-if="data.isOnline == 1 && data.isAnnualIssue != 1"
                                @click="goThisVolume">{{ LAN.journal.thisVolume }}</h3>
                        </div>
                    </div>
                </a-col>
                <a-col :span="24"
                       :lg="18">
                    <div class="n-flex n-flex-a-c n-flex-j-sb n-mb-2">
                        <div class="n-mr-2 search-container n-flex n-flex-a-c">
                            <a-input-search v-model:value="keyword"
                                            enter-button
                                            allowClear
                                            size="large"
                                            :placeholder="LAN.nui.common.pleaseEnterKeyword"
                                            @search="searchData" />
                        </div>

                        <a-button type="primary"
                                  shape="round"
                                  v-if="canBack()"
                                  @click="$router.go(-1)">
                            <template #icon>
                                <LeftOutlined />
                            </template>
                            {{ LAN.common.back }}
                        </a-button>
                    </div>

                    <div class="n-bg-1 n-p-2 n-mb-2">
                        <h3 class="n-c-4 n-f-bold n-mtb-0 n-flex-g n-flex-s">
                            {{ LAN.journal.yearVolume.replace("|Y|", volumeData.fYear||'-').replace("|V|",
                volumeData.volumeNum||'-') }} {{ LAN.journal.qq.replace('|Q|', issueData.issueLabel||'-') }}</h3>
                        <div class="n-flex-s n-mt-2 n-bg-2 n-p-2"
                             v-if="contentAll?.specialIssueTitle || contentAll?.specialIssueEditors">
                            <div v-html="contentAll.specialIssueTitle"></div>
                            <div v-html="contentAll.specialIssueEditors"></div>
                        </div>
                    </div>

                    <div class="n-flex n-flex-a-c n-flex-w">
                        <div class="n-flex n-flex-a-c">
                            <div class="n-flex n-flex-a-c n-ptb-2 n-mr-4">
                                <span class="title-icon n-flex n-flex-a-c n-flex-j-c n-mr-2 n-c-1 n-radius-3">
                                    <EditOutlined class="n-flex-s" />
                                </span>
                                <h1 class="n-c-4 n-f-bold n-m-0">{{ LAN.journal.contents }}</h1>
                            </div>
                            <span style="padding-top: 1px">{{ LAN.journal.totalI.replace('|T|', this.contents?.length ||
                0) }}</span>
                        </div>

                        <a class="n-c-base n-ml-3 c-hover-line n-flex-g n-text-right"
                           :href="data.externalSiteUrl"
                           target="_blank"
                           v-if="data.externalSiteUrl && data.onlyExternalPublish == 0">{{
                LAN.journal.readOn.replace('|SITE|',
                    data.externalSiteName).replace('|NAME|', data.name) }}</a>

                    </div>

                    <div v-if="loading">
                        <ul class="n-ul n-p-0 list n-bg-1 n-mt-2">
                            <li class="n-b-line n-pointer n-p-4"
                                v-for="item in 3"
                                :key="item">
                                <div class="n-skeleton n-skeleton-text n-ptb-4 n-col-3"></div>
                                <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                            </li>
                        </ul>
                    </div>

                    <div v-else-if="contents?.length > 0"
                         class="n-bg-1 n-mt-2">
                        <ul class="n-ul n-p-0 list">
                            <li class="n-b-line n-pointer n-p-2 c-hover"
                                v-for="item in contents"
                                :key="item"
                                @click="$router.push({ name: 'JDetail', params:{ cid: item.id, viewtype: $route.query.viewtype} })">

                                <div>
                                    <p class="n-block n-f-6 n-f-bold c-hover-target n-m-0"
                                       v-html="parseHilight(item.title, keyword)"></p>

                                    <span class="n-block">{{ parseAuthors(item.authors) }}</span>

                                    <div class="n-flex n-flex-a-e n-flex-j-sb">
                                        <span class="n-block n-c-base"
                                              v-if="item.startPage && item.endPage">{{ LAN.journal.pages }}
                                            {{ item.startPage }}-{{ item.endPage }}</span>
                                        <span v-else></span>
                                        <div class="n-block n-col-3 n-text-right">
                                            <a-button type="primary">{{ LAN.common.view }}</a-button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                    </div>

                    <a-row :gutter="[15,15]"
                           class="n-col-1"
                           v-else>
                        <a-col class="n-bg-1 n-ptb-3"
                               span="24">
                            <a-empty :description="LAN.journal.noContents"></a-empty>
                        </a-col>
                    </a-row>

                    <span class="n-f-2 n-c-2 n-mt-2 n-block"
                          v-if="contentAll.updateTimeGmt">{{ LAN.common.updateTime }}: {{ contentAll.updateTimeGmt }} ({{contentAll.updateTimeLocal }})
                    </span>
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script>
    import { mapMutations, mapState } from 'vuex';
    import { EditOutlined } from '@ant-design/icons-vue';
    import NImage from '@/components/nui/NImage/NImage.vue'
    import Breadcrumb from '@/components/Breadcrumb.vue'
    import nui from '@/assets/script';

    export default {
        name: 'BDetail',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                loading: false,
                data: {},

                volumeData: {},
                contentsCache: [],
                issueData: {},
                contentAll: {},
                contents: [],
                keyword: '',
                volumeId: '',
                issueId: ''
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            NMounted () {
                this.loadData()
            },
            searchData () {
                this.contents = this.extend([], this.contentsCache.filter(item => {
                    return item.title.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0
                }))
            },
            loadData () {
                this.loading = true;

                let periodicalId = this.$route.query.jid || '';
                let volumeId = this.$route.query.sid || '';
                let issueId = this.$route.query.iid || '';

                var a = this.request(this.API.journalList, {
                    requestId: 'journalList',
                    params: { id: periodicalId, viewType: this.$route.query.viewtype }
                }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE && res.data && res.data.id) {
                        this.data = res.data || {};
                    }
                    else {
                        this.$router.push({ name: '404page' });
                    }
                }).catch(() => {
                    this.loading = false;
                })

                var b = this.request(this.API.journalPanel, {
                    requestId: 'journalPanel',
                    params: { periodicalId: periodicalId, issueId: issueId, viewType: this.$route.query.viewtype }
                }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE
                        && res.data
                        && res.data.issueId
                        && res.data.volumeId
                        && res.data.periodicalId
                    ) {
                        this.contents = res.data.content
                        this.contentsCache = res.data.content;
                        volumeId = res.data.volumeId || '';
                        issueId = res.data.issueId || '';

                        this.contentAll = res.data;
                        this.volumeId = volumeId;
                        this.issueId = issueId;
                    }
                    else {
                        this.$router.push({ name: '404page' });
                    }
                }).catch(() => {
                    this.loading = false;
                })

                Promise.all([a, b]).then(() => {
                    if (issueId != '') {
                        this.volumeData = this.data.volumes?.filter(item => item.id == volumeId)[0] || {};
                        this.issueData = this.volumeData.issues?.filter(item => item.id == issueId)[0] || {};

                        if (!this.volumeData.id) {
                            this.$router.push({ name: '404page' });
                        }

                        if (!this.issueData.id) {
                            this.$router.push({ name: '404page' });
                        }
                    }
                    this.loading = false
                    this.$nextTick(() => {
                        this.parseMathJax()
                    })
                }).catch((err) => {
                    this.loading = false

                    if (err.code != 'ERR_CANCELED') {
                        this.$router.push({ name: '404page' });
                    }
                })
            },
            goHome () {
                if (this.data.isAnnualIssue == 1) {
                    this.push.call(this, { name: 'bookSuccessionList', query: { id: this.data.serialId } })
                } else {
                    this.$router.push({ name: 'journalList', query: { id: this.$route.query.jid } })
                }
            },
            goOnline () {
                if (this.data.isAnnualIssue == 1) {
                    this.push.call(this, { name: 'journalAnnualPanel', query: { jid: this.$route.query.jid, sid: this.data.serialId } })
                } else {
                    if (this.data.onlyExternalPublish == 1) {
                        window.open(this.data.externalSiteUrl, '_blank')
                    } else {
                        this.$router.push({ name: 'journalList', query: { p: 5, id: this.$route.query.jid } })
                    }
                }
            },
            goThisVolume () {
                if (this.data.onlyExternalPublish == 1) {
                    window.open(this.data.externalSiteUrl, '_blank')
                } else {
                    this.$router.push({ name: 'journalList', query: { p: 5, id: this.$route.query.jid, openIId: this.$route.query.iid } })
                }
            },
            parseAuthors (data = []) {
                var res = JSON.parse(JSON.stringify(data.map(item => item.fullName)))

                if (res.length > 1) {
                    if (res.length == 2) {
                        return res.join(' and ')
                    } else {
                        res[res.length - 1] = 'and ' + res[res.length - 1]
                        return res.join(', ')
                    }
                } else {
                    return res.join(', ')
                }
            }
        }),
        components: {
            NImage,
            Breadcrumb,
            EditOutlined
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .thumb {
        width: 250px;
        height: 320px;

        .thumb-image {
            transition: all 0.3s;
        }
    }

    .title-icon {
        background-color: rgb(255, 226, 186);
        color: rgb(255, 162, 66);
        padding: 5px;
    }

    .list {
        & > li {
            position: relative;

            i {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-image: $linear;
                background-color: transparent;
                opacity: 0;
                transition: all 0.3s;
            }

            &:hover {
                h1,
                p {
                    color: $color-base;
                }

                i {
                    opacity: 1;
                }
            }
        }
    }
</style>