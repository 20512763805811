<template>
    <div class="n-pb-4">
        <div class="c-banner c-banner-small n-col-1 n-border-box">
            <NImage class="c-banner-image n-col-1 n-h-1"
                    type="cover"
                    :src="require('@/assets/image/banner.jpg')"></NImage>

            <div class="c-banner-text n-col-1 n-p-3">
                <h1 class="n-c-1 c-max-width n-text-center n-m-auto n-mb-1 c-f-1 n-f-1">{{LAN.welcome}}</h1>
            </div>
        </div>

        <div class="c-max-width n-m-auto n-mt-4 n-bg-1">
            <a-row :gutter="[0, 0]">
                <a-col :span="24"
                       :lg="4"
                       style="padding: 0">
                    <div class="n-bg-1"
                         os="0"
                         id="menu">
                        <div class="n-plr-4 n-pt-3 n-flex n-flex-a-c">
                            <a-button type="primary"
                                      size="large"
                                      class="category-button n-mr-3"
                                      @click="showMenu = !showMnenu">
                                <template #icon>
                                    <MenuUnfoldOutlined />
                                </template>
                            </a-button>

                            <h2 class="n-f-bold n-c-base c-title c-title-1 n-b-line n-t-line n-inline-block n-pb-1 n-mb-2">{{ LAN.nav.ordering }}</h2>
                        </div>

                        <div class="n-p-4"
                             v-if="categoryLoading">
                            <span class="n-skeleton n-skeleton-text n-ptb-4 n-mb-4 n-block n-col-1"
                                  v-for="item in 4"
                                  :key="item"></span>
                        </div>
                        <div :span="24"
                             :lg="4"
                             class="left-menu n-scroll-v auto"
                             v-else>
                            <div class="n-pb-1 c-content-left n-scroll-v auto">
                                <a-collapse v-model:activeKey="active"
                                            ghost
                                            class="category">
                                    <a-collapse-panel v-for="item in category"
                                                      :key="item.dictKey"
                                                      :header="item.dictValue">
                                        <div class="n-pl-5">
                                            <ul class="n-ul n-p-0 n-bg-2">
                                                <li class="n-ptb-1 n-plr-2 n-b-line n-pointer n-break-word"
                                                    :class="{'n-active': selected == sItem.dictKey}"
                                                    v-for="sItem in item.children"
                                                    :key="sItem.dictKey"
                                                    @click="changeCategory(sItem.dictKey)">{{sItem.dictValue}}</li>
                                            </ul>
                                        </div>
                                    </a-collapse-panel>
                                </a-collapse>
                            </div>
                        </div>
                    </div>
                </a-col>
                <a-col :span="24"
                       :lg="20">
                    <div v-if="infoLoading"
                         class="n-flex n-flex-a-c n-flex-j-c n-h-1 n-bg-1 n-p-3"
                         style="height: 300px">
                        <a-spin size="large" />
                    </div>
                    <div v-else
                         class="c-content-right n-bg-1 n-p-2">

                        <p v-html="content"></p>

                        <span class="n-f-2 n-c-2 n-mt-2">{{ LAN.common.updateTime }}: {{ data.updateTimeGmt }} ({{ data.updateTimeLocal }}) </span>
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>

    <a-drawer v-model:visible="showMenu"
              placement="left"
              width="70%">
        <a-collapse v-model:activeKey="active"
                    ghost
                    class="category">
            <a-collapse-panel v-for="item in category"
                              :key="item.dictKey"
                              :header="item.dictValue">
                <div class="n-pl-5">
                    <ul class="n-ul n-p-0 n-bg-2">
                        <li class="n-ptb-1 n-plr-2 n-b-line n-pointer n-break-word"
                            :class="{'n-active': selected == sItem.dictKey}"
                            v-for="sItem in item.children"
                            :key="sItem.dictKey"
                            @click="changeCategory(sItem.dictKey)">{{sItem.dictValue}}</li>
                    </ul>
                </div>
            </a-collapse-panel>
        </a-collapse>
    </a-drawer>
</template>
<script >
    import { mapMutations, mapState } from 'vuex';
    import NEditorParser from '@/components/NEditorParser.vue';
    import { SwapLeftOutlined, SwapRightOutlined, MenuFoldOutlined, MenuUnfoldOutlined, CaretRightOutlined, UndoOutlined } from '@ant-design/icons-vue';
    import NImage from '@/components/nui/NImage/NImage.vue'

    import nui from '@/assets/script';

    export default {
        name: 'Journal',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                active: '',
                selected: '',

                data: {},
                content: '',

                showMenu: false,

                infoLoading: false,
                categoryLoading: false,
                category: [],
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            NActivated () {
                this.loadData()
            },
            changeCategory (id) {
                this.selected = id;
                this.loadInfo()
            },
            loadData () {
                this.categoryLoading = true

                this.request(this.API.orderCategory).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE) {
                        this.category = res.data

                        if (res.data.length > 0) {
                            var p = this.$route.query.p ? this.$route.query.p.split('-') : null;


                            if (p) {
                                this.active = res.data[p[0]].dictKey


                                if (res.data[p[0]] && res.data[p[0]].children && res.data[p[0]].children[p[1]]) {
                                    this.selected = res.data[p[0]].children[p[1]].dictKey
                                }
                            } else {
                                this.active = res.data[0].dictKey

                                if (res.data[0].children && res.data[0].children.length > 0) {
                                    this.selected = res.data[0].children[0].dictKey
                                }
                            }


                        }

                        this.loadInfo()
                    }

                    this.categoryLoading = false
                }).catch(err => {
                    this.categoryLoading = false
                })
            },
            loadInfo () {
                if (!this.selected) {
                    return
                }

                this.infoLoading = true

                this.request(this.API.orderInfo, {
                    params: { id: this.selected }
                }).then(res => {
                    this.infoLoading = false

                    if (res.code == this.REQUEST_SUCCESS_CODE) {
                        this.data = res.data
                        this.content = res.data.fcontent
                    }

                    this.$nextTick(() => {
                        this.parseMathJax()
                    })
                }).catch(err => {
                    this.infoLoading = false
                })
            }
        }),
        components: {
            SwapLeftOutlined,
            SwapRightOutlined,
            MenuFoldOutlined,
            MenuUnfoldOutlined,
            CaretRightOutlined,
            NImage,
            UndoOutlined,
            NEditorParser
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .category {
        .n-pointer {
            transition: all 0.3s;
            font-size: 14px;
        }

        .n-pointer {
            &:hover,
            &.n-active {
                background-color: $background-red;
                color: $color-base;

                &:after {
                    background-color: $background-red;
                }
            }
        }
    }

    .category-button {
        display: none;
    }

    @media screen and (max-width: 1100px) {
        .left-menu {
            display: none;
        }

        .c-content-right {
            min-height: 0;
        }

        .category-button {
            display: inline-block;
            margin-bottom: 15px;
        }
    }
</style>
<style lang="scss">
    .left-menu {
        .ant-collapse-content-box {
            padding: 0 10px !important;
        }

        .ant-collapse-header {
            font-size: 16px;
        }
    }

    .c-content-right {
        min-height: 100%;
    }

    // .info-content table {
    //     border: 1px solid $line-color-3;

    //     p {
    //         margin: 0;
    //     }

    //     th,
    //     td {
    //         padding: 3px 7px;
    //         text-align: left;
    //     }
    // }
</style>