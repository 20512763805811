<template>
    <div class="n-ptb-4">
        <Breadcrumb :data="breadcrumb"></Breadcrumb>

        <div class="n-p-4 c-max-width n-m-auto n-bg-1 c-shadow n-mt-3">
            <div class="n-flex n-bg-1 n-p-4"
                 v-if="loadings">
                <div class="list-thumb  n-flex-s n-mr-3 n-skeleton"></div>
                <div class="n-flex-g n-flex-g-w-1-hack">
                    <div class="n-skeleton n-skeleton-text n-ptb-6"></div>
                    <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-6"></div>
                    <div class="n-skeleton n-skeleton-text n-mt-6"></div>
                </div>
            </div>

            <div class="n-flex book-top"
                 v-else>
                <div class="list-thumb c-border n-flex-s n-mr-3">
                    <NImage class="list-thumb-image n-col-1 n-h-1"
                            type="cover"
                            zoomView
                            :src="data.coverPicture">
                    </NImage>
                </div>
                <div class="n-flex-g n-flex-g-w-1-hack book-info">
                    <h1 class="n-f-bold n-c-4 n-m-0"><span class="n-mr-3 n-inline">{{ data.title || '-' }}</span></h1>
                    <div class="n-flex n-c-4 n-mt-1">
                        <h4 class="n-mb-0 n-flex-s">{{ bookRelatedPersonLabel }}:&nbsp;&nbsp;</h4>
                        <ul class="n-ul n-p-0 n-m-0 n-flex n-flex-w">
                            <li v-for="item, index in data.bookRelatedPerson"
                                :key="item.name">{{ item.name +
            ((item.title || '') == '' ? '' : (`(${item.title})`)) }}{{ index <
            data.bookRelatedPerson.length - 1 ? '、' : '' }}</li>
                        </ul>
                    </div>
                    <h4 class="n-c-4 n-mb-0 n-f-normal"
                        v-if="publication && publication.publishDate && data.bookPublishType != 'print'">
                        <label class="n-flex-s">{{ LAN.common.publishDate }}:&nbsp;&nbsp;</label>{{ publication ?
            parseCDate(publication.publishDate) : '-' }}
                    </h4>

                    <span class="n-block n-c-4"
                          v-if="data.publisher">
                        <label class="n-flex-s">{{ LAN.common.publisher }}:&nbsp;&nbsp;</label>{{ data.publisher || '-'
                        }}
                    </span>
                    <span class="n-block n-c-4"
                          v-if="publication && publication.isbn13">
                        <label class="n-flex-s">{{ 'ISBN (' + (data.bookPublishType == 'EBook' ? 'Online Only' : (
            data.bookPublishType == 'print' ? 'Print Only' : "Print & Online"
        )) + ')' }}:&nbsp;&nbsp;</label>{{ publication.isbn13 }}
                    </span>

                    <span class="n-block n-c-4"
                          v-if="publication && publication.mediumDesc">
                        <label class="n-flex-s">{{ LAN.book.medium }}:&nbsp;&nbsp;</label>{{ (data.bookPublishType ==
            'EBook' ? 'Online Only' : (
            data.bookPublishType == 'print' ? 'Print Only' : "Print & Online"
        )) }}
                    </span>
                    <!-- <span class="n-block n-c-4" v-if="publication && publication.bindingDesc">
                        <label class="n-flex-s">{{ LAN.book.binding }}:&nbsp;&nbsp;</label>{{
            publication.bindingDesc }}
                    </span> -->
                    <span class="n-block n-c-4"
                          v-if="data.pages">
                        <label class="n-flex-s">{{ LAN.common.pages }}:&nbsp;&nbsp;</label>{{ data.pages }}
                    </span>

                    <span class="n-block n-c-4"
                          v-if="publication && publication.size">
                        <label class="n-flex-s">{{ LAN.book.size }}:&nbsp;&nbsp;</label>{{ publication.size }}
                    </span>
                    <!-- <span class="n-block n-c-4 n-mb-2" v-if="publication && publication.publishStatusDesc">
                        <label class="n-flex-s">{{ LAN.book.status
                            }}:&nbsp;&nbsp;</label>{{ publication.publishStatusDesc }}
                    </span> -->

                    <a-button type="primary"
                              v-if="auth && CONTENT"
                              @click="openPDF">{{ LAN.common.PDFAll }}</a-button>
                    <a-button type="primary"
                              v-if="!auth && CONTENT"
                              @click="goContact">{{
            LAN.common.subscribe }}</a-button>
                </div>
            </div>
        </div>

        <div class="n-flex c-max-width n-m-auto n-bg-2 n-mt-4"
             v-if="data.seriesInfo && data.seriesInfo.length > 0">
            <label class="n-flex-s">{{ LAN.book.belong.split('|SS|')[0] }}</label>

            <ul class="n-ul n-p-0 n-m-0 n-mlr-2">
                <li v-for="(item, index) in data.seriesInfo"
                    class="n-c-base n-pointer c-hover-1 n-inline"
                    :key="item.tagId"
                    :class="{ 'n-mr-2': index != data.seriesInfo.length - 1 }"
                    @click="push.call(this, { name: 'bookSuccessionList', query: { from: 'bookDetail', id: item.tagId } })">
                    {{ item.tagName }}{{ index == data.seriesInfo.length - 1 ? '' : '、' }}</li>
            </ul>

            <label class="n-flex-s">{{ LAN.book.belong.split('|SS|')[1] }}</label>
        </div>

        <div class="c-max-width n-m-auto n-bg-2 n-mt-4"
             v-if="!loadings">
            <a-card class="n-col-1"
                    :tab-list="tabList"
                    :active-tab-key="tab"
                    @tabChange="tabChange">
                <template #customTab="item">
                    <span class="n-m-0"
                          :class="tab == item.key ? 'n-c-base' : ''">{{ LAN.common[item.tab] || item.tab
                        }}</span>
                </template>

                <template #tabBarExtraContent>

                    <div class="n-flex n-pl-3">
                        <a-button type="primary"
                                  size="small"
                                  v-if="PREVIEW"
                                  @click="showViewpPreview = true">{{
            LAN.common.PDFPreview }}</a-button>

                        <a-button type="primary"
                                  class="n-ml-2"
                                  size="small"
                                  v-if="TOC"
                                  @click="showViewTOC = true">{{
            LAN.common.PDFTable }}</a-button>
                    </div>
                </template>

                <div v-if="tab == 'introduce'">
                    <div v-if="data.childSet && data.childSet.length > 0">
                        <h4 class="n-c-4 n-f-bold n-m-0">{{ LAN.common.childNotice }}</h4>

                        <ul class="n-ul n-plr-0 n-b-line n-ptb-2">
                            <li v-for="item in data.childSet"
                                :key="item.id">
                                <router-link :to="{ name: 'BDetail', query: { from: $route.query.from, id: item.id, k: new Date().getTime() } }"
                                             custom
                                             v-slot="{ href }">
                                    <LinkOutlined class="n-c-red n-mr-1" /><a :href="href"
                                       class="n-c-base">{{
            item.title }}</a>
                                </router-link>
                            </li>
                        </ul>
                    </div>

                    <div v-if="data.parentSet && data.parentSet.id">
                        <h4 class="n-c-4 n-f-bold n-m-0">{{ LAN.common.parentNotice }}</h4>

                        <ul class="n-ul n-plr-0 n-b-line n-ptb-2">
                            <li>
                                <router-link :to="{ name: 'BDetail', query: { from: $route.query.from, id: data.parentSet.id, k: new Date().getTime() } }"
                                             custom
                                             v-slot="{ href }">
                                    <LinkOutlined class="n-c-red n-mr-1" /><a :href="href"
                                       class="n-c-base">{{
            data.parentSet.title
        }}</a>
                                </router-link>
                            </li>
                        </ul>
                    </div>

                    <p v-html="data.description"></p>

                    <span v-if="data.bookPublishType != 'EBook' && (data.printBookInfo || '') != ''">{{
            LAN.book.printBookInfo
        }}</span>

                    <p v-html="data.printBookInfo"
                       v-if="data.bookPublishType != 'EBook' && (data.printBookInfo || '') != ''"></p>

                    <span class="n-f-2 n-c-2 n-mt-2">{{ LAN.common.updateTime }}: {{ data.updateTimeGmt }} ({{
            data.updateTimeLocal }})
                    </span>
                </div>

                <div v-if="tab == 'MSC2020'">
                    <span class="n-block n-c-4"
                          v-if="(data.mscTagInfo || []).length > 0">
                        <template v-for="(item) in (data.mscTagInfo || [])"
                                  v-bind:key="item.tagName">
                            <div class="n-flex-s"
                                 style="color:#b3394b;">{{ item.tagName }} </div>
                        </template>
                    </span>
                </div>

                <article class="n-c-3 book-introduce"
                         v-if="tab == 'publications'">
                    <table border="1"
                           class="n-col-1">
                        <tr>
                            <th class="n-ptb-1 n-plr-2 n-text-left">{{ LAN.book.pubDate }}</th>
                            <th class="n-ptb-1 n-plr-2 n-text-left">ISBN-13</th>
                            <th class="n-ptb-1 n-plr-2 n-text-left">ISBN-10</th>
                            <th class="n-ptb-1 n-plr-2 n-text-left">{{ LAN.book.medium }}</th>
                            <th class="n-ptb-1 n-plr-2 n-text-left">{{ LAN.book.binding }}</th>
                            <th class="n-ptb-1 n-plr-2 n-text-left">{{ LAN.book.size }}</th>
                            <th class="n-ptb-1 n-plr-2 n-text-left">{{ LAN.book.status }}</th>
                            <th class="n-ptb-1 n-plr-2 n-text-left">{{ LAN.book.price }}</th>
                        </tr>
                        <tr>
                            <td class="n-ptb-1 n-plr-2 n-text-left">{{ data.publication ?
            parseCDate(data.publication[0].publishDate) :
            '-' }}</td>
                            <td class="n-ptb-1 n-plr-2 n-text-left">{{ data.publication ? data.publication[0].isbn13 :
            '-' }}</td>
                            <td class="n-ptb-1 n-plr-2 n-text-left">{{ data.publication ? data.publication[0].isbn10 :
            '-' }}</td>
                            <td class="n-ptb-1 n-plr-2 n-text-left">{{ data.publication ? data.publication[0].mediumDesc
            : '-' }}</td>
                            <td class="n-ptb-1 n-plr-2 n-text-left">{{ data.publication ?
            data.publication[0].bindingDesc
            : '-' }}</td>
                            <td class="n-ptb-1 n-plr-2 n-text-left">{{ data.publication ? data.publication[0].size :
            '-' }}</td>
                            <td class="n-ptb-1 n-plr-2 n-text-left">{{ data.publication ?
            data.publication[0].publishStatusDesc : '-' }}
                            </td>
                            <td class="n-ptb-1 n-plr-2 n-text-left">{{ data.publication ? data.publication[0].listPrice
            :
            '-' }}</td>
                        </tr>
                    </table>
                    <ul class="n-ul n-p-0 n-m-0">
                        <li class="n-flex n-flex-a-c n-flex-j-sb n-mb-2"><label class="n-flex-s n-c-2">{{
            LAN.book.pubDate
        }}</label><span>{{ data.publication ?
            data.publication[0].publishDate : '-' }}</span></li>
                        <li class="n-flex n-flex-a-c n-flex-j-sb n-mb-2"><label class="n-flex-s n-c-2">ISBN-13</label><span>{{
            data.publication ?
                data.publication[0].isbn13 :
                '-' }}</span></li>
                        <li class="n-flex n-flex-a-c n-flex-j-sb n-mb-2"><label class="n-flex-s n-c-2">ISBN-10</label><span>{{
            data.publication ?
                data.publication[0].isbn10 :
                '-' }}</span></li>
                        <li class="n-flex n-flex-a-c n-flex-j-sb n-mb-2"><label class="n-flex-s n-c-2">{{
            LAN.book.medium
        }}</label><span>{{ data.publication ?
            data.publication[0].mediumDesc : '-' }}</span></li>
                        <li class="n-flex n-flex-a-c n-flex-j-sb n-mb-2"><label class="n-flex-s n-c-2">{{
            LAN.book.binding
        }}</label><span>{{ data.publication ?
            data.publication[0].bindingDesc : '-' }}</span></li>
                        <li class="n-flex n-flex-a-c n-flex-j-sb n-mb-2"><label class="n-flex-s n-c-2">{{ LAN.book.size
                                }}</label><span>{{ data.publication ?
            data.publication[0].size :
            '-' }}</span></li>
                        <li class="n-flex n-flex-a-c n-flex-j-sb n-mb-2"><label class="n-flex-s n-c-2">{{
            LAN.book.status }}</label><span>{{ data.publication ?
            data.publication[0].publishStatusDesc : '-' }}</span></li>
                        <li class="n-flex n-flex-a-c n-flex-j-sb"><label class="n-flex-s n-c-2">{{ LAN.book.price
                                }}</label><span>{{
                                data.publication ?
                                data.publication[0].listPrice : '-'}}</span></li>
                    </ul>
                </article>

            </a-card>
        </div>

        <a-modal v-model:visible="showViewCONTENT"
                 centered
                 width="calc(100vw - 10px)"
                 height="calc(100vh - 10px)"
                 class="pdf-dialog"
                 v-if="CONTENT && auth && showViewCONTENT"
                 :bodyStyle="{padding: 0}"
                 :title="((CONTENT.originalName||'').replace('.'+(CONTENT.extension||''),''))"
                 :footer="null"
                 @ok="showViewCONTENT = false">
            <DocViewNoOp v-if="data.isPdfReadOnly === 1"
                         :url="CONTENT.resourceUrl"
                         :name="CONTENT.originalName"
                         class="pdf">
            </DocViewNoOp>
            <DocView v-if="data.isPdfReadOnly !== 1"
                     :url="CONTENT.resourceUrl"
                     :name="CONTENT.originalName"
                     class="pdf">
            </DocView>
        </a-modal>

        <a-modal v-model:visible="showViewTOC"
                 centered
                 width="calc(100vw - 10px)"
                 height="calc(100vh - 10px)"
                 class="pdf-dialog"
                 v-if="TOC && showViewTOC"
                 :bodyStyle="{padding: 0}"
                 :title="TOC.originalName"
                 :footer="null"
                 @ok="showViewTOC = false">
            <DocView :url="TOC.resourceUrl"
                     :name="TOC.originalName"
                     class="pdf"></DocView>
        </a-modal>

        <a-modal v-model:visible="showViewpPreview"
                 centered
                 width="calc(100vw - 10px)"
                 height="calc(100vh - 10px)"
                 class="pdf-dialog"
                 v-if="PREVIEW && showViewpPreview"
                 :bodyStyle="{padding: 0}"
                 :title="PREVIEW.originalName"
                 :footer="null"
                 @ok="showViewpPreview = false">
            <DocView :url="PREVIEW.resourceUrl"
                     :name="PREVIEW.originalName"
                     class="pdf"></DocView>
        </a-modal>
    </div>
</template>
<script>
    import { mapMutations, mapState } from 'vuex';
    import { Modal } from 'ant-design-vue'
    import NImage from '@/components/nui/NImage/NImage.vue'
    import DocView from '@/components/nui/DocView/DocView.vue'
    import DocViewNoOp from '@/components/nui/DocView/DocViewNoOp.vue'
    import NEditorParser from '@/components/NEditorParser.vue';
    import Breadcrumb from '@/components/Breadcrumb.vue'
    import { LinkOutlined } from '@ant-design/icons-vue';
    import crypto from '@/assets/script/crypto';
    import nui from '@/assets/script';

    export default {
        name: 'BDetail',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                auth: false,

                showViewTOC: false,
                showViewCONTENT: false,
                showViewpPreview: false,

                tab: 'introduce',
                // tabList: [{ key: 'introduce', tab: 'introduce' }, { key: 'publications', tab: 'publications' }],
                tabList: [{ key: 'introduce', tab: 'introduce', }],
                initTabList: [{ key: 'introduce', tab: 'introduce', }],

                loadings: false,
                data: {},

                TOC: null,
                CONTENT: null,
                PREVIEW: null,
                publication: {},
                bookRelatedPersonLabel: ''
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            NMounted () {
                this.loadData()
            },

            checkContentPermission () {
                this.auth = false;
                if (!this.CONTENT) {
                    return
                }

                this.request(this.API.checkContentPermission, {
                    params: { dataid: this.$route.query.id, datatype: "book" }
                }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE) {
                        this.auth = res.data.hasAuth;
                    }
                })
            },

            checkAuth (callback) {
                if (!this.CONTENT) {
                    return
                }

                this.request(this.API.pdfCheckAuth, {
                    params: { dataid: this.$route.query.id, datatype: "book", name: this.CONTENT.name }
                }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE) {
                        this.auth = res.data.hasAuth;
                        let url = crypto.decrypt(res.data.url);
                        callback && callback(url);
                    }
                })
            },
            tabChange (key) {
                this.tab = key;
                this.$nextTick(() => {
                    this.parseMathJax()
                })
            },
            openPDF () {
                this.checkAuth((url) => {
                    if ((url || '') == '') {
                        return;
                    }
                    this.CONTENT.resourceUrl = url;
                    if ((this.data.contentPdfViewType || '') == 'NEW_WINDOW') {
                        window.open(`/web/viewer${this.data.isPdfReadOnly === 1 ? '_noop' : ''}.html?file=` + encodeURIComponent(url), '_blank');
                    }
                    else {
                        this.showViewCONTENT = true;
                    }
                });
            },
            loadData () {
                this.loadings = true

                if (!this.$route.query.id)
                    return;

                this.request(this.API.bookDetail, {
                    params: { id: this.$route.query.id }
                }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE) {
                        this.data = res.data;
                        let printPublishcation = this.data.publication.filter(item => {
                            return (item.mediumCode || '').toLowerCase() == "print";
                        })?.[0];

                        let ebookPublication = this.data.publication.filter(item => {
                            return item.mediumCode == "EBook";
                        })?.[0];

                        if (res.data.bookPublishType == 'EBook') {
                            this.publication = ebookPublication;

                        } else if (this.data.bookPublishType == 'print') {
                            this.publication = printPublishcation;
                        } else {
                            this.publication = ebookPublication;

                            if (this.publication != undefined) {
                                this.publication.isbn13 = printPublishcation.isbn13 + " & " + ebookPublication.isbn13;
                            }
                        }
                        this.bookRelatedPersonLabel = "Author";
                        if (this.data.bookRelatedPerson.length > 0) {

                            if (this.data.bookRelatedPerson[0].relatedType == "EDITOR" || this.data.bookRelatedPerson[0].relatedType == "EDITORS") {
                                this.bookRelatedPersonLabel = "Editor";
                            } else {
                                this.bookRelatedPersonLabel = "Author";
                            }

                            if (this.data.bookRelatedPerson.length > 1) {
                                this.bookRelatedPersonLabel += 's';
                            }
                        }

                        this.TOC = (this.data.contentPdf || []).filter(item => item.relationType == 'TOC')[0];
                        this.CONTENT = (this.data.contentPdf || []).filter(item => item.relationType == 'CONTENT')[0];
                        this.PREVIEW = (this.data.contentPdf || []).filter(item => item.relationType == 'PREVIEW')[0]

                        var title = this.data.title

                        switch (this.$route.query.from) {
                            case 'book':
                                this.breadcrumb = [{ title: this.LAN.nav.book, url: 'book' }, { title }]
                                break
                            case 'bookSuccession':
                                this.breadcrumb = [{ title: this.LAN.nav.bookSuccession, url: 'bookSuccession' }, { title }]
                                break
                            case 'bookLight':
                                this.breadcrumb = [{ title: this.LAN.nav.bookLight, url: 'bookLight' }, { title }]
                                break
                            case 'bookList':
                                this.breadcrumb = [{ title: this.LAN.nav.STY, url: 'bookList' }, { title }]
                                break
                            case 'mine':
                                this.breadcrumb = [{ title: this.LAN.nav.mine, url: 'mine' }, { title }]
                                break
                        }

                        this.loadings = false;
                        if ((this.data.mscTagInfo || []).length > 0) {
                            this.tabList = this.initTabList.concat([{ key: 'MSC2020', tab: 'MSC2020' }]);
                        }
                        else {
                            this.tabList = this.initTabList;
                        }
                        this.tabChange("introduce");
                        this.checkContentPermission();
                    }
                }).catch(err => {
                    this.loadings = false
                })
            },
            goContact () {
                this.$router.push({ name: 'contact' })
            }
        }),
        components: {
            NImage,
            DocView,
            DocViewNoOp,
            Breadcrumb,
            LinkOutlined,
            NEditorParser
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .list-thumb {
        width: 190px;
        height: 294px;

        .list-thumb-image {
            transition: all 0.3s;
        }
    }

    .list-item {
        transition: all 0.3s;

        &:hover {
            box-shadow: $shadow-2;
        }
    }

    .book-introduce {
        table {
            display: table;
        }

        ul {
            display: none;
        }
    }

    @media screen and (max-width: 1100px) {
        .book-introduce {
            table {
                display: none;
            }

            ul {
                display: block;
            }
        }

        .book-top {
            display: block;

            .book-info {
                margin-top: 15px;
                width: auto;
            }
        }
    }
</style>
<style lang="scss">
    .pdf-dialog {
        max-width: 100% !important;

        .ant-modal-header {
            background: rgba(56, 56, 61, 1);
            padding: 10px;
            height: 40px;
            border-bottom: 0;
        }

        .ant-modal-title {
            color: $font-color-1;
        }

        .ant-modal-close-x {
            color: $font-color-1;
            width: 40px;
            height: 40px;
            line-height: 40px;
        }

        .ant-modal-content {
            height: 100%;
        }

        .ant-modal-body {
            height: calc(100% - 40px);
        }
    }
</style>