<template>
    <div class="n-pb-4">
        <div class="c-banner c-banner-small n-col-1 n-border-box n-overflow-hidden">
            <NImage class="c-banner-image n-col-1 n-h-1"
                    type="cover"
                    :src="require('@/assets/image/banner.jpg')"></NImage>

            <div class="c-banner-text n-col-1 n-p-3">
                <h1 class="n-c-1 c-max-width n-text-center n-m-auto n-mb-1 c-f-1 n-f-1">{{LAN.welcome}}</h1>
            </div>
        </div>

        <Breadcrumb :data="breadcrumb"
                    class="n-mt-2 n-plr-2"></Breadcrumb>

        <div class="c-max-width n-m-auto n-bg-2 n-plr-2 n-mt-3">
            <a-row :gutter="[20, 0]">
                <a-col :span="24"
                       :lg="6">
                    <div v-if="loading"
                         class="n-bg-1 n-p-2 n-pb-4 n-flex n-flex-dr-c n-flex-a-c">
                        <div class="thumb n-flex-s n-mb-3 n-skeleton"></div>
                        <div class="n-skeleton n-skeleton-text n-ptb-3 n-mt-3 n-col-1"></div>
                        <div class="n-skeleton n-skeleton-text n-ptb-3 n-mt-3 n-col-1"></div>
                        <div class="n-skeleton n-skeleton-text n-ptb-3 n-mt-3 n-col-1"></div>
                    </div>

                    <div v-else
                         class="n-bg-1 n-ptb-3 n-plr-4"
                         id="menu">
                        <div class="left-menu left-auto-more"
                             :class="showLeftMore ? 'n-scroll-v auto' : 'n-overflow-hidden'">
                            <span class="left-auto-more-button n-c-base"
                                  @click="showLeftMore = true"
                                  v-if="!showLeftMore">{{ LAN.common.more }}</span>
                            <div class="c-content-left">
                                <div>
                                    <h3 class="n-f-normal n-m-0">{{ baseData.tagName }} </h3>

                                    <span class="n-block n-f-6 n-f-bold"
                                          v-if="baseData.issnPrint">{{LAN.journal.print.replace('|PRINT|', baseData.issnPrint)}}</span>
                                    <span class="n-block n-f-6 n-f-bold"
                                          v-if="baseData.issnOnline">{{LAN.journal.online.replace('|ONLINE|', baseData.issnOnline)}}</span>

                                    <span class="n-block n-text-center n-border-3 n-p-1 n-mt-4 n-radius-3 c-hover-bg n-pointer"
                                          v-if="baseData.periodicalId"
                                          @click="push.call(this, {name: 'journalPanel', query: {jid: baseData.periodicalId}})">
                                        {{ LAN.journal.gotoCDM.replace("|NAME|", baseData.periodicalNameAbbr) }}
                                    </span>

                                    <div v-for="(item,index) in baseData.fEditors"
                                         :key="index"
                                         class="n-mt-4">
                                        <span class="n-block n-f-6 n-f-bold">{{item[0].editorTypeName}}</span>
                                        <span class="n-f-normal n-m-0 n-block n-mt-1"
                                              v-for="(sItem,sIndex) in item"
                                              :key="sIndex">{{sItem.fullName}}{{ sItem.title ? `(${sItem.title})` : '' }}</span>
                                    </div>
                                </div>

                                <p v-html="baseData.description"
                                   v-if="baseData.description"
                                   class="n-mt-2 n-pt-2 n-t-line"></p>
                            </div>
                        </div>
                    </div>
                </a-col>
                <a-col :span="24"
                       :lg="18">
                    <div class="title-container">
                        <div id="title"
                             class="n-flex n-flex-a-c n-flex-j-sb search-row title-inner n-bg-2">
                            <div>
                                <h2 class="n-f-bold n-c-base c-title n-b-line n-t-line n-inline-block n-pb-1 n-mb-1">{{LAN.nav.bookSuccession}}<span class="n-inline-block n-f-normal n-ml-2 n-c-UI-blue n-f-2">{{LAN.common.total}} {{totalCount}} {{LAN.unit.b}}</span></h2>
                            </div>

                            <div class="n-mb-2 search-container">
                                <a-input-search v-model:value="keyword"
                                                :placeholder="LAN.nui.common.pleaseEnterKeyword"
                                                enter-button
                                                allowClear
                                                size="large"
                                                @search="search" />
                            </div>
                        </div>
                    </div>

                    <a-row :gutter="[15,15]"
                           v-if="loading">
                        <a-col :span="24"
                               :lg="12"
                               :key="item"
                               v-for="item in 4">
                            <div class="n-flex n-flex-dr-c n-bg-1 n-h-1 n-p-4 n-pointer n-col-1">
                                <h1 class="n-m-0 n-col-1 n-skeleton n-skeleton-text"></h1>
                                <div class="n-skeleton n-skeleton-text n-col-2 n-mt-2"></div>
                                <div class="n-skeleton n-skeleton-text n-col-2 n-mt-2"></div>
                                <div class="n-skeleton n-skeleton-text n-col-3 n-ptb-3 n-mt-2"></div>
                            </div>
                        </a-col>
                    </a-row>

                    <a-row :gutter="[15,15]"
                           v-else-if="data.length > 0">
                        <a-col :span="24"
                               :lg="12"
                               :key="item.id"
                               v-for="item in data">
                            <router-link :to="{name: 'BDetail', query: {from: 'bookSuccession', id: item.id, k: new Date().getTime()}}"
                                         custom
                                         v-slot="{ href }">
                                <a :href="href"
                                   class="n-flex n-flex-dr-c n-bg-1 n-h-1 n-p-4 n-pointer n-col-1 list-item">
                                    <h1 class="n-f-bold n-c-4 n-e-2 n-m-0 n-flex-g title">
                                        <BookFilled class="n-c-base n-mr-1" /><span v-html="parseHilight(item.title, keyword)"></span>
                                    </h1>
                                    <div>
                                        <span class="n-block n-mt-2 n-c-2 n-e-1">{{item.bookRelatedPerson.map(item => item.name).join('、')}}</span>
                                        <span class="n-block n-c-2 n-m-0">{{LAN.common.publishDate}}: {{parseCDate(item.publication[0].publishDate)}}</span>
                                        <div class="n-flex n-flex-a-c n-flex-j-sb n-mt-2">
                                            <div class="n-flex n-flex-a-c n-line-height-1 c-line-hack"
                                                 :class="getViewType(item.authLevelName).class">
                                                <i :class="getViewType(item.authLevelName).icon"
                                                   class="n-mr-1"></i>
                                                {{ getViewType(item.authLevelName).text }}
                                            </div>
                                            <a-button type="primary"
                                                      size="middle">
                                                {{LAN.common.view}}
                                            </a-button>
                                        </div>
                                    </div>
                                </a>
                            </router-link>
                        </a-col>
                    </a-row>

                    <a-row :gutter="[15,15]"
                           class="n-col-1"
                           v-else>
                        <a-col class="n-bg-1 n-ptb-3"
                               span="24">
                            <a-empty :description="LAN.book.noBook"></a-empty>
                        </a-col>
                    </a-row>

                    <a-pagination v-model:current="currentPage"
                                  show-quick-jumper
                                  class="n-mt-4"
                                  v-if="false"
                                  :pageSize="pageSize"
                                  :total="totalCount"
                                  @change="loadData" />
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script >
    import { mapMutations, mapState } from 'vuex';
    import { StarOutlined, LikeOutlined, MessageOutlined, BookFilled, UndoOutlined } from '@ant-design/icons-vue';
    import NImage from '@/components/nui/NImage/NImage.vue'
    import Breadcrumb from '@/components/Breadcrumb.vue'

    import nui from '@/assets/script';

    export default {
        name: 'BookSuccessionList',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                keyword: '',
                subTitle: '',
                loading: false,
                currentPage: 1,
                pageSize: 1000,
                baseData: {},
                data: [],
                dataCache: [],
                totalCount: 0,
                breadcrumb: [],
                showLeftMore: false
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            NActivated () {
                this.breadcrumb = [{ title: this.LAN.nav.bookSuccession, url: 'bookSuccession' }, { title: this.LAN.nav.bookSuccessionList }]

                this.$nextTick(() => {
                    var title = document.getElementById('title');

                    if (title) {
                        title.style.width = title.offsetWidth + 'px'
                        this.$root.titleMounted()
                    }
                })
            },
            NForward () {
                this.keyword = ''
                this.currentPage = 1
                this.loadData()
            },
            search () {
                this.data = this.dataCache.filter(item => item.title.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0)
                // this.data = this.dataCache.filter(item => this.trims(item.title).toLowerCase().indexOf(this.trims(this.keyword).toLowerCase()) >= 0)
            },
            loadData () {
                this.loading = true

                Promise.all([
                    this.request(this.API.getTagName, {
                        params: {
                            tagId: this.$route.query.id
                        }
                    }),
                    this.request(this.API.bookSuccessionList, {
                        params: {
                            current: this.currentPage,
                            size: this.pageSize,
                            tagId: this.$route.query.id
                        }
                    })
                ]).then(res => {
                    var [res1, res2] = res

                    if (res1.code == this.REQUEST_SUCCESS_CODE) {
                        var editors = res1.data.editors || [],
                            fEditors = {};

                        editors.forEach(item => {
                            if (fEditors[item.editorTypeCode]) {
                                fEditors[item.editorTypeCode].push(item)
                            } else {
                                fEditors[item.editorTypeCode] = [item]
                            }
                        });

                        res1.data.fEditors = fEditors

                        this.baseData = res1.data
                    }

                    if (res2.code == this.REQUEST_SUCCESS_CODE) {
                        this.data = res2.data.records
                        this.dataCache = res2.data.records

                        this.totalCount = res2.data.total
                    }

                    this.loading = false

                    this.$nextTick(() => {
                        this.$root.menuMounted();

                        var leftMenu = document.querySelector('.left-menu')

                        console.log(111, leftMenu.offsetHeight, leftMenu.scrollHeight);

                        this.showLeftMore = leftMenu.offsetHeight >= leftMenu.scrollHeight
                    })
                }).catch(err => {
                    this.loading = false
                })
            }
        }),
        components: {
            StarOutlined,
            LikeOutlined,
            MessageOutlined,
            NImage,
            BookFilled,
            UndoOutlined,
            Breadcrumb
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .list-item {
        transition: all 0.3s;

        .title {
            max-height: 50px;
            line-height: 25px;
            transition: all 0.3s;
        }

        &:hover {
            box-shadow: $shadow-2;

            .title {
                color: $color-base;
            }
        }
    }

    .left-auto-more {
        position: relative;

        .left-auto-more-button {
            position: absolute;
            z-index: 3;
            bottom: 0;
            height: 60px;
            width: 100%;
            right: 0;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                height: 80px;
            }

            background-image: linear-gradient(
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 1) 60%,
                rgba(255, 255, 255, 1) 100%
            );
        }
    }

    @media screen and (max-width: 1100px) {
        .search-row {
            display: block;
        }
    }
</style>