<template>
    <div class="n-image-container"
         :class="{'hack-left': hackLeft}">
        <div class="n-image n-col-1 n-h-1"
             :class="{'n-image-contain': type == 'contain', 'n-image-cover': type == 'cover'}">
            <div ref="image"
                 @mouseenter="enter"
                 @mousemove="move"
                 @mouseleave="showView = false">
                <img :src="src"
                     :alt="src"
                     ref="source"
                     v-if="loaded == true">
                <img :src="errorSrc"
                     :alt="src"
                     v-if="loaded == false">
                <div class="view-rect"
                     ref="rect"
                     v-if="showView"></div>
            </div>
        </div>
        <view class="view"
              ref="view"
              v-if="showView">
            <img :src="src"
                 :alt="src"
                 ref="aim">
        </view>
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';

    import nui from '../../../assets/script';

    export default {
        name: 'NImage',
        props: {
            k: String,
            src: { type: String, default: '' },
            errorSrc: { type: String, default: require('./N-image-error.png') },
            type: { type: String, default: 'normal' },
            zoomView: { type: Boolean, default: true },
        },
        components: {},
        computed: {
            ...mapState(nui.extendVue.extendState.concat(['scrollInfo'])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                mounted: false,
                isInViewport: false,
                showView: false,
                isShow: true,
                loaded: null,
                hackLeft: false,
                w: 0,
                iw: 0,
                h: 0,
                ih: 0
            })
        },
        watch: {
            scrollInfo () {
                if (!this.loaded && !this.isInViewport) {
                    if (this.isElementInViewport()) {
                        this.install()
                        this.mounted = true
                    }
                }
            }
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            NDeactivated () {
                this.showView = false
                this.mounted = false
                this.isShow = false
            },
            NMounted () {
                if (this.isElementInViewport() && !this.loaded) {
                    this.install()
                    this.mounted = true
                }
                this.isShow = true
            },
            NUpdated () {
                if (!this.loaded && !this.isInViewport) {
                    if (this.isElementInViewport()) {
                        this.install()
                        this.mounted = true
                    }
                }
            },
            NBeforeDestroy () {
                this.isShow = false
            },
            NActivated () {
                if (this.isElementInViewport() && !this.loaded) {
                    this.install()
                    this.mounted = true
                }

                this.isShow = true
            },
            isElementInViewport () {
                if (!this.isShow) {
                    this.isInViewport = false
                    return false
                }

                const rect = this.$el.getBoundingClientRect(),
                    isInViewport = (
                        rect.top >= 0 &&
                        rect.left >= 0 &&
                        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                    );


                this.isInViewport = isInViewport
                return isInViewport;
            },
            install () {
                if (this.loaded) {
                    return
                }

                var image = new Image();

                image.onload = () => {
                    if (!this.mounted) {
                        return
                    }

                    this.$nextTick(() => {
                        var iw = image.width,
                            ih = image.height,
                            w = this.$el.offsetWidth,
                            h = this.$el.offsetHeight,
                            img = this.$refs.image;

                        if (w / h > iw / ih) {
                            img.style.width = w
                            img.style.height = (w * ih / iw) + 'px'
                            img.style.marginTop = (h - (w * ih / iw)) / 2 + 'px'
                        } else {
                            img.style.height = h + 'px'
                            img.style.width = (h * iw / ih) + 'px'
                            img.style.marginLeft = (w - (h * iw / ih)) / 2 + 'px'
                        }

                        this.ih = ih
                        this.h = h
                        this.iw = iw
                        this.w = w

                        this.loaded = true

                    })
                };

                image.onerror = () => {
                    this.loaded = false
                };

                image.src = this.src;

                if (document.documentElement.clientWidth - this.$el.getBoundingClientRect().left - this.$el.offsetWidth - 20 < 400) {
                    this.hackLeft = true
                } else {
                    this.hackLeft = false
                }
            },
            enter () {
                if (!this.zoomView) {
                    return
                }

                this.showView = true

                this.$nextTick(() => {
                    this.$refs.aim.style.width = this.$refs.image.offsetWidth * 5 + 'px'
                    this.$refs.aim.style.height = this.$refs.image.offsetHeight * 5 + 'px'
                })
            },
            move (e) {
                if (!this.zoomView) {
                    return
                }

                var rect = this.$refs.rect;

                if (rect) {
                    var left = e.pageX - this.$el.getBoundingClientRect().left - rect.offsetWidth / 2,
                        top = e.pageY - this.$el.getBoundingClientRect().top - rect.offsetHeight / 2;

                    if (left <= 0) {
                        left = 0
                    } else if (left + 80 >= this.$el.offsetWidth) {
                        left = this.$el.offsetWidth - 80
                    }

                    if (top <= 0) {
                        top = 0
                    } else if (top + 80 >= this.$el.offsetHeight) {
                        top = this.$el.offsetHeight - 80
                    }

                    rect.style.left = left + 'px'
                    rect.style.top = top + 'px'

                    var img = this.$refs.image,
                        dl = img.style.marginLeft,
                        dt = img.style.marginTop,
                        dlp = parseFloat(dl.replace('px', '') || '0'),
                        dtp = parseFloat(dt.replace('px', '') || '0'),
                        dlpd = left - dlp,
                        dtpd = top - dtp,
                        originTop = dtpd * 5,
                        originLeft = dlpd * 5;

                    if (originLeft + 400 > this.$refs.image.offsetWidth * 5) {
                        originLeft = this.$refs.image.offsetWidth * 5 - 400
                    }

                    if (originTop + 400 > this.$refs.image.offsetHeight * 5) {
                        originTop = this.$refs.image.offsetHeight * 5 - 400
                    }

                    this.$nextTick(() => {
                        this.$refs.aim.style.marginTop = -1 * originTop + 'px'
                        this.$refs.aim.style.marginLeft = -1 * originLeft + 'px'
                    })
                }
            }
        }),
        ...nui.extendVue.extendLife
    };
</script>
<style lang="scss" scoped>
    .n-image-container {
        position: relative;
        .view {
            position: absolute;
            top: 50%;
            right: auto;
            left: calc(100% + 10px);
            box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.4);
            width: 400px;
            height: 400px;
            background-color: rgba(0, 0, 0, 0.3);
            border: $color-base;
            transform: translateY(-50%);
            border: $color-base 1px solid;
            z-index: 400;
            overflow: hidden;
        }

        &.hack-left {
            .view {
                left: auto;
                right: calc(100% + 10px);
            }
        }

        .n-image {
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            overflow: hidden;
            position: relative;
            .view-rect {
                position: absolute;
                width: 80px;
                height: 80px;
                border: $color-base 1px solid;
                background-color: rgba(255, 255, 255, 0.2);
                top: 0;
                left: 0;
            }

            &.n-image-contain {
                background-size: contain;
            }

            &.n-image-cover {
                background-size: cover;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
</style>
