<template>
    <div class="c-max-width n-m-auto n-flex n-flex-a-c n-flex-j-sb breadcrumb-container">
        <a-breadcrumb separator="/"
                      class="breadcrumb">
            <a-breadcrumb-item href="#">
                <router-link :to="{name: 'book', query: {k: new Date().getTime()}}"
                             custom
                             v-slot="{ href }">
                    <a :href="href">
                        <HomeOutlined />
                    </a>
                </router-link>
            </a-breadcrumb-item>

            <a-breadcrumb-item class="breadcrumb"
                               v-for="(item, index) in data"
                               :key="index">

                <router-link :to="{name: item.url, query: {k: new Date().getTime()}}"
                             custom
                             v-if="item.url"
                             v-slot="{ href }">
                    <a :href="href"
                       :class="index == data.length - 1 ? 'n-c-base' : 'n-c-2'">{{item.title}}</a></router-link>

                <span v-else>{{item.title}}</span>
            </a-breadcrumb-item>
        </a-breadcrumb>

        <a-button type="primary"
                  shape="round"
                  v-if="canBack()"
                  @click="$router.go(-1)">
            <template #icon>
                <LeftOutlined />
            </template>
            {{LAN.common.back}}
        </a-button>
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';
    import { HomeOutlined, LeftOutlined } from '@ant-design/icons-vue';

    import nui from '@/assets/script';

    export default {
        name: 'Breadcrumb',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        props: {
            data: Array
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
            })
        },
        watch: {
            '$route': function () {
            }
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat(['setWindowSize', 'setLocation'])),

            NMounted () {
            },
        }),
        components: {
            HomeOutlined,
            LeftOutlined
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .breadcrumb a {
        color: $font-color-2;
    }

    .breadcrumb:last-child a {
        color: $font-color-4;
    }

    @media screen and (max-width: 1100px) {
        .breadcrumb {
            display: none;
        }

        .breadcrumb-container {
            justify-content: flex-end;
        }
    }
</style>