import { createStore } from 'vuex'

import base from './base'

export default createStore({
    state: {
        ...base.state,

        level: [],
        status: [],
        location: [],

        // //Hash History记录
        // historyState: {},



        // //安全区域
        // safeAreaData: { top: 0, bottom: 0 },


        isMobileByWinWidth: document.body.clientWidth <= 950,
        // isMobileByTouch: document.body.ontouchstart !== undefined,

        // orientation: 0
    },
    mutations: {
        ...base.mutations,

        setLevel (state, level) {
            state.level = level;
        },
        setStatus (state, status) {
            state.status = status;
        },
        setLocation (state, data) {
            state.location = data;
        },
        setIsMobileByWinWidth (state) {
            state.isMobileByWinWidth = document.body.clientWidth <= 950;
            state.isMobileByTouch = document.body.ontouchstart !== undefined;
        },
        // setOrientation (state, data) {
        //     state.orientation = data
        // },
        // /**
        //  * @description 设置History缓存
        //  * @description 设置Hash History缓存
        //  */
        // setHistoryState (state, data) {
        //     state.historyState = data
        // },

        // /**
        //  * @description 缓存安全区域
        //  */
        // setSafeArea (state, data) {
        //     state.safeAreaData = data;
        // },
        // /**
    },
    actions: {},
    modules: {}
});
