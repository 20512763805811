<template>
    <div></div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';

    import nui from '@/assets/script';

    export default {
        name: 'NEditorParser',
        props: { content: String },
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            parse () {
                let iframe = document.createElement('iframe');
                iframe.style.width = "100%";
                iframe.style.border = 0
                iframe.onload = () => {
                    iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px'
                }

                this.$el.innerHTML == '';
                this.$el.appendChild(iframe);

                iframe.contentWindow.document.open();
                iframe.contentWindow.document.write(`${this.EDITORSCRIPT + this.EDITORSTYLE + '<body class="mce-content-body">' + this.content + '</body>'}`);
                iframe.contentWindow.document.close();
            }
        }),
        components: {
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
</style>