<template>
    <div class="n-pb-4">
        <div class="c-banner c-banner-small n-col-1 n-border-box n-overflow-hidden">
            <NImage class="c-banner-image n-col-1 n-h-1"
                    type="cover"
                    :src="require('@/assets/image/banner.jpg')"></NImage>

            <div class="c-banner-text n-col-1 n-p-3">
                <h1 class="n-c-1 c-max-width n-text-center n-m-auto n-mb-1 c-f-1 n-f-1">{{LAN.welcome}}</h1>
            </div>
        </div>

        <div class="c-max-width n-m-auto n-bg-2 n-mt-4 n-plr-2">
            <a-row :gutter="[20, 0]">
                <a-col :span="24"
                       :lg="4">
                    <div id="menu">
                        <h2 class="n-f-bold n-c-base c-title n-b-line n-t-line n-inline-block n-pb-1 n-mb-4">{{ LAN.nav.book }}</h2>

                        <div class="left-menu n-scroll-v">
                            <ul class="n-p-0 n-ul n-m-0 c-content-left">
                                <template v-for="(item, index) in categorys">
                                    <li class="n-f-6 n-p-1 n-mb-2 n-c-4 n-pointer"
                                        v-if="item.total > 0"
                                        :key="item"
                                        :class="{'n-c-base': category[0] == index, 'n-active': item.showSub, 'n-selected': category[0] == index && (!item.sub || item.sub.length <= 0)}"
                                        @click="changeCategory(index)">

                                        <CaretRightOutlined class="n-mr-1 n-c-2 n-f-6 c-arrow"
                                                            v-if="item.sub.length > 0" />{{ LAN.book[index] }} <span class="n-inline-block n-f-normal n-c-UI-blue n-f-2">({{item.total}})</span>

                                        <ul v-if="item.showSub"
                                            class="n-ul n-m-0 n-p-0 n-pl-5 n-mt-1">
                                            <li v-for="item in item.sub"
                                                :key="item.code"
                                                class="n-c-3 n-b-line n-ptb-1"
                                                :class="{'n-active': item.code == category[1], 'n-selected': item.code == category[1]}"
                                                @click.stop="changeCategory(index, item.code)">
                                                【{{item.code}}】{{ item.title.replace(item.code+" ","") }}&nbsp;<span class="n-inline-block n-f-normal n-c-UI-blue n-f-2">({{item.count}})</span>
                                            </li>
                                        </ul>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </a-col>
                <a-col :span="24"
                       :lg="20">
                    <div class="sort-container">
                        <div id="sort"
                             class="n-flex sort-inner n-col-1 n-bg-2">
                            <a-button type="primary"
                                      size="large"
                                      class="n-mr-6 category-button n-flex-s"
                                      @click="showMenu = !showMnenu">
                                <template #icon>
                                    <MenuUnfoldOutlined />
                                </template>
                            </a-button>

                            <div class="n-plr-1 n-flex-j-sb n-col-1 search-row">
                                <ul class="n-ul n-flex n-flex-a-c n-bg-1 n-radius-3 n-overflow-hidden n-p-0 n-m-0">
                                    <li class="n-ptb-2 n-plr-4 n-pointer"
                                        :class="sort.sort == 'title' ? 'n-bg-base n-c-1' : ''"
                                        @click="doSort('title','')">{{LAN.common.byTitle}}</li>
                                    <li class="n-ptb-2 n-pl-4 n-pr-2 n-pointer"
                                        :class="sort.sort == 'date' ? 'n-bg-base n-c-1' : ''"
                                        @click="doSort('date', 't')">{{LAN.common.byDate}}
                                        <SwapLeftOutlined class="sort-btn"
                                                          v-if="sort.sort != 'date' || sort.subSort == 't'" />
                                        <SwapRightOutlined class="sort-btn"
                                                           v-if="sort.sort == 'date' && sort.subSort == 'b'" />
                                    </li>
                                    <!-- <li class="n-ptb-2 n-pl-4 n-pr-2 n-pointer"
                                        :class="sort.sort == 'price' ? 'n-bg-base n-c-1' : ''"
                                        @click="doSort('price', 't')">{{LAN.common.byPrice}}
                                        <SwapLeftOutlined class="sort-btn"
                                                          v-if="sort.sort != 'price' || sort.subSort == 't'" />
                                        <SwapRightOutlined class="sort-btn"
                                                           v-if="sort.sort == 'price' && sort.subSort == 'b'" />
                                    </li> -->
                                </ul>

                                <div class="n-mr-2 search-container">
                                    <a-input-search v-model:value="keyword"
                                                    enter-button
                                                    allowClear
                                                    size="large"
                                                    :placeholder="LAN.nui.common.pleaseEnterKeyword"
                                                    @search="sortData" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <a-col :span="24"
                           :lg="24"
                           class="book-body">
                        <a-row :gutter="[15,15]"
                               class="n-col-1"
                               v-if="checking">
                            <a-col :span="24"
                                   :lg="12"
                                   :key="item"
                                   v-for="item in 4">
                                <div class="n-flex n-bg-1 n-p-4 n-pointer list-item">
                                    <div class="list-thumb n-flex-s n-mr-3 n-skeleton"></div>
                                    <div class="n-flex-g n-flex-g-w-1-hack">
                                        <div class="n-skeleton n-skeleton-text n-ptb-6"></div>
                                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-6"></div>
                                        <div class="n-skeleton n-skeleton-text n-mt-6"></div>
                                    </div>
                                </div>
                            </a-col>
                        </a-row>

                        <a-row :gutter="[15,15]"
                               class="n-col-1"
                               v-else-if="data.length > 0">

                            <a-col :span="24"
                                   :lg="12"
                                   :key="item.id"
                                   v-for="(item, index) in data">
                                <div class="n-flex n-bg-1 n-p-4 n-pointer list-item n-h-1"
                                     @click="push.call(this, {name: 'BDetail', query: {from: 'book', id: item.id}})">
                                    <div class="list-thumb c-border n-flex-s n-mr-3">
                                        <NImage class="list-thumb-image n-col-1 n-h-1"
                                                type="cover"
                                                zoomView
                                                :k="index"
                                                :src="item.coverPicture"></NImage>
                                    </div>
                                    <div class="n-flex-g n-flex-g-w-1-hack">
                                        <h1 class="n-f-bold n-c-4 n-m-0 n-e-2"
                                            style="height: 50px; line-height: 25px"
                                            v-html="parseHilight(item.title, keyword)"></h1>

                                        <span class="n-e-1 n-block">{{ item.bookRelatedPerson.map(item => item.name).join('、') }}</span>
                                        <span class="n-c-2 n-block n-mt-2">{{LAN.common.publishDate +': '+ parseCDate(item.publication[0].publishDate)}}</span>
                                        <!-- <span class="n-c-2 n-block"
                                              v-html="LAN.common.salePrice1 + ': ' + item.publication[0].currency + item.publication[0].listPrice"></span> -->
                                        <div class="n-flex n-flex-a-c n-flex-j-sb n-mt-2">
                                            <div class="n-flex n-flex-a-c n-line-height-1 c-line-hack"
                                                 :class="getViewType(item.authLevelName).class">
                                                <i :class="getViewType(item.authLevelName).icon"
                                                   class="n-mr-1"></i>
                                                {{ getViewType(item.authLevelName).text }}
                                            </div>
                                            <a-button type="primary"
                                                      size="middle">
                                                {{LAN.common.view}}
                                            </a-button>
                                        </div>
                                    </div>
                                </div>
                            </a-col>

                        </a-row>

                        <a-row :gutter="[15,15]"
                               class="n-col-1"
                               v-else>
                            <a-col class="n-bg-1 n-ptb-3"
                                   span="24">
                                <a-empty :description="LAN.book.noBook"></a-empty>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-col>
            </a-row>
        </div>
    </div>

    <a-drawer v-model:visible="showMenu"
              placement="left"
              width="70%">
        <ul class="n-p-0 n-ul n-m-0 float-menu">
            <template v-for="(item, index) in categorys">
                <li class="n-f-6 n-pt-1 n-pb-2 n-mb-2 n-b-line n-c-4 n-pointer"
                    v-if="item.total > 0"
                    :key="item"
                    :class="{'n-c-base': category[0] == index, 'n-active': item.showSub}"
                    @click="changeCategory(index)">
                    <CaretRightOutlined class="n-mr-1 n-c-2 n-f-6 c-arrow"
                                        v-if="item.sub.length > 0" />{{ LAN.book[index] }} <span class="n-inline-block n-f-normal n-c-UI-blue n-f-2">({{item.total}})</span>

                    <ul v-if="item.showSub"
                        class="n-ul n-m-0 n-p-0 n-pl-5 n-mt-1">
                        <li v-for="item in item.sub"
                            :key="item.code"
                            class="n-c-3 n-b-line n-ptb-1"
                            :class="{'n-active': item.code == category[1]}"
                            @click.stop="changeCategory(index, item.code)">
                            【{{item.code}}】{{ item.title }}
                        </li>
                    </ul>
                </li>
            </template>
        </ul>
    </a-drawer>
</template>
<script >
    import pinyin from "pinyin";
    import { mapMutations, mapState } from 'vuex';
    import { SwapLeftOutlined, SwapRightOutlined, MenuFoldOutlined, MenuUnfoldOutlined, CaretRightOutlined, UndoOutlined, TagsOutlined } from '@ant-design/icons-vue';
    import NImage from '@/components/nui/NImage/NImage.vue'
    import category from '@/assets/script/category'

    import nui from '@/assets/script';

    export default {
        name: 'Book',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                showMenu: false,

                sort: { sort: 'title', subSort: '' },
                keyword: '',
                checking: false,

                category: [],
                data: [],
                dataCache: [],
                categorys: {
                    subscribed: { sub: [], total: 0, showSub: false },
                    featured: { sub: [], total: 0, showSub: false },
                    MSC2020: { sub: [], total: 0, showSub: false },
                }
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat([])),

            NActivated () {
                // 加载数量
                this.request(this.API.subscribedCount, { requestId: 'subscribed-total' }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE) {
                        this.categorys.subscribed.total = res.data;

                        if (this.categorys.subscribed.total > 0) {
                            // 加载数量
                            if (this.category.length <= 0) {
                                this.changeCategory('subscribed');
                            }
                        } else {
                            // 加载数量
                            if (this.category.length <= 0) {
                                this.changeCategory('featured');
                            }
                        }
                    }
                })
                // 加载数量
                this.request(this.API.bookCount, { requestId: 'featured-total', params: { tagName: 'Featured Publications' } }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE) { this.categorys.featured.total = res.data; }
                })

                this.$root.menuMounted();
                this.request(this.API.tagsByCategory, { params: { tagType: 'MSC2022', needBookCount: true } }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE) {

                        this.categorys.MSC2020.sub = res.data;
                        this.categorys.MSC2020.total = this.categorys.MSC2020.sub.length;
                    }
                    this.$root.menuMounted();
                })

                this.$nextTick(() => {
                    var sort = document.getElementById('sort');

                    if (sort) {
                        sort.style.width = sort.offsetWidth + 'px'
                        this.$root.sortMounted();
                    }
                })
            },
            changeCategory (category, subCategory) {
                this.keyword = ''
                this.category = [category];

                subCategory && (this.category = this.category.concat([subCategory]))

                if (subCategory) {
                    this.loadData()
                } else if (this.categorys[category].sub.length > 0) {
                    this.categorys[category].showSub = !this.categorys[category].showSub
                } else {
                    for (var i in this.categorys) {
                        i != category && (this.categorys[i].showSub = false)
                    }

                    this.loadData()
                }

            },
            loadData () {
                if (this.category == 'subscribed') {
                    this.checking = true

                    return this.request(this.API.subscribed, {
                        requestId: 'list',
                        params: { size: 9999999 }
                    }).then(res => {
                        if (res.code == this.REQUEST_SUCCESS_CODE) {
                            this.data = res.data.records;
                            this.dataCache = res.data.records;
                            this.sortData()
                        }
                        this.checking = false
                    }).catch(err => {
                        this.checking = false
                    })
                } else if (this.category == 'featured') {
                    this.checking = true

                    return this.request(this.API.book, {
                        requestId: 'list',
                        params: { tagName: 'Featured Publications', size: 9999999 }
                    }).then(res => {
                        if (res.code == this.REQUEST_SUCCESS_CODE) {
                            this.data = res.data.records;
                            this.dataCache = res.data.records;
                            this.sortData()
                        }
                        this.checking = false
                    }).catch(err => {
                        this.checking = false
                    })
                } else {
                    this.checking = true

                    this.request(this.API.book, {
                        requestId: 'list',
                        params: { tagName: this.category[1], tagCategory: this.category[0], size: 9999999 }
                    }).then(res => {
                        if (res.code == this.REQUEST_SUCCESS_CODE) {
                            this.data = res.data.records;
                            this.dataCache = res.data.records;
                            this.sortData()
                        }
                        this.checking = false
                    }).catch(err => {
                        this.checking = false
                    })
                }
            },
            sortData () {
                this.$nextTick(() => {
                    // var data = this.extend([], this.dataCache.filter(item => {
                    //     return this.trims(item.title).toLowerCase().indexOf(this.trims(this.keyword).toLowerCase()) >= 0
                    // }))

                    var data = this.extend([], this.dataCache.filter(item => {
                        return item.title.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0
                    }))

                    this.data = data.sort((a, b) => {
                        if (this.sort.sort == 'title') {
                            var as = pinyin(a.title, {
                                style: "initials",
                                heteronym: true,
                            })[0], bs = pinyin(b.title, {
                                style: "initials",
                                heteronym: true,
                            })[0], as0, bs0;

                            as0 = as[0] ? as[0].substring(0, 1) : ''
                            bs0 = bs[0] ? bs[0].substring(0, 1) : ''

                            return as0 > bs0 ? 1 : -1
                        } else if (this.sort.sort == 'date') {
                            var as = a.publication[0].publishDate,
                                bs = b.publication[0].publishDate;

                            as = new Date(as || '2099-1-1').getTime();
                            bs = new Date(bs || '2099-1-1').getTime();

                            return this.sort.subSort == 't' ? as - bs : bs - as
                        } else if (this.sort.sort == 'price') {
                            var as = parseFloat(a.publication[0].listPrice || 999999),
                                bs = parseFloat(b.publication[0].listPrice || 999999);

                            return this.sort.subSort == 't' ? as - bs : bs - as
                        }
                    })
                })
            },
            doSort (sort) {
                var newSort = Object.assign({}, this.sort);


                if (sort == 'title') {
                    newSort = { sort }
                } else {
                    if (sort == newSort.sort) {
                        if (newSort.subSort == '' || newSort.subSort == 'b') {
                            newSort = { sort, subSort: 't' }
                        } else {
                            newSort = { sort, subSort: 'b' }
                        }
                    } else {
                        newSort = { sort, subSort: 't' }
                    }
                }

                this.sort = newSort
                this.sortData()
            }
        }),
        components: {
            SwapLeftOutlined,
            SwapRightOutlined,
            MenuFoldOutlined,
            MenuUnfoldOutlined,
            CaretRightOutlined,
            NImage,
            UndoOutlined
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .list-thumb {
        width: 130px;
        height: 168px;

        .list-thumb-image {
            transition: all 0.3s;
        }
    }

    .list-item {
        transition: all 0.3s;

        &:hover {
            box-shadow: $shadow-2;
        }
    }

    .left-menu,
    .float-menu {
        li {
            transition: all 0.3s;

            &:hover {
                color: $color-base;
            }

            &.n-selected {
                background-color: $background-red;
                color: $color-base;

                &:after {
                    background-color: $background-red;
                }
            }

            &.n-active {
                color: $color-base;

                .c-arrow {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .sort-btn {
        transform: rotate(90deg);
    }

    .category-button {
        display: none;
    }

    .search-row {
        display: flex;
    }

    .sort-container {
        position: relative;
        padding-top: 60px;
        z-index: 10;
        .sort-inner {
            position: absolute;
            top: 0;
        }
    }

    @media screen and (max-width: 1100px) {
        .book-body {
            margin: 0 !important;
            padding: 0 !important;

            .list-item {
                padding: 10px !important;
            }
            .ant-row {
                width: 100% !important;
                margin: 0 !important;
                padding: 0 !important;
            }
            .ant-col {
                padding: 0 5px !important;
            }
        }

        .left-menu {
            display: none;
        }
        .category-button {
            display: inline-block;
        }
        .search-container {
            margin-right: 0;
            margin-top: 10px;
        }

        .search-row {
            display: inline-block;
        }

        .left-menu {
            display: none;
        }
        .category-button {
            display: inline-block;
        }

        .sort-container {
            padding-top: 110px;
        }
    }
</style>
<style lang="scss">
    .menu {
        .ant-menu-item {
            padding: 15px !important;
        }
    }
</style>
