import CryptoJS from 'crypto-js';

export default class crypto {
    /**
     * token加密key 使用@org.springblade.test.CryptoKeyGenerator获取,需和后端配置保持一致
     * @type {string}
     */
    static cryptoKey = 'wI9z6Gor5JkOhuJPje67QmbLMXzmhXCK';
    /**
     * 报文加密key 使用@org.springblade.test.CryptoKeyGenerator获取,需和后端配置保持一致
     * @type {string}
     */
    static aesKey = '2rn4keEY4YDoe9D8h9v8UANoHzQpLvA0';
    /**
     * 报文加密key 使用@org.springblade.test.CryptoKeyGenerator获取,需和后端配置保持一致
     * @type {string}
     */
    static desKey = 'F2IAlI3QECixo3t4';

    /**
     * aes 加密方法
     * @param data
     * @returns {*}
     */
    static encrypt (data) {
        return this.encryptAES(data, this.aesKey);
    }

    /**
     * aes 解密方法
     * @param data
     * @returns {*}
     */
    static decrypt (data) {
        return this.decryptAES(data, this.aesKey);
    }

    /**
     * aes 加密方法，同java：AesUtil.encryptToBase64(text, aesKey);
     */
    static encryptAES (data, key) {
        const dataBytes = CryptoJS.enc.Utf8.parse(data);
        const keyBytes = CryptoJS.enc.Utf8.parse(key);
        const encrypted = CryptoJS.AES.encrypt(dataBytes, keyBytes, {
            iv: keyBytes,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    }

    /**
     * aes 解密方法，同java：AesUtil.decryptFormBase64ToString(encrypt, aesKey);
     */
    static decryptAES (data, key) {
        // debugger;
        const keyBytes = CryptoJS.enc.Utf8.parse(key);
        const decrypted = CryptoJS.AES.decrypt(data, keyBytes, {
            iv: keyBytes,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return CryptoJS.enc.Utf8.stringify(decrypted);
    }

    /**
     * des 加密方法，同java：DesUtil.encryptToBase64(text, desKey)
     */
    static encryptDES (data, key) {
        const keyHex = CryptoJS.enc.Utf8.parse(key);
        const encrypted = CryptoJS.DES.encrypt(data, keyHex, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
    }

    /**
     * des 解密方法，同java：DesUtil.decryptFormBase64(encryptBase64, desKey);
     */
    static decryptDES (data, key) {
        const keyHex = CryptoJS.enc.Utf8.parse(key);
        const decrypted = CryptoJS.DES.decrypt(
            {
                ciphertext: CryptoJS.enc.Base64.parse(data),
            },
            keyHex,
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            }
        );
        return decrypted.toString(CryptoJS.enc.Utf8);
    }
}
