<template>
    <div :id="id"
         :class="parsed ? '' : 'n-hide-opacity'">{{content}}</div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';

    import nui from '@/assets/script';

    export default {
        name: 'NEditorParser',
        props: { content: String, id: { type: String, default: new Date().getTime() } },
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                parsed: false
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            parse () {
                window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, this.$el])

                setTimeout(() => {
                    this.parsed = true
                }, 300)
            }
        }),
        components: {
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
</style>