<template>
    <div class="n-pb-4">
        <div class="c-banner c-banner-small n-col-1 n-border-box n-overflow-hidden">
            <NImage class="c-banner-image n-col-1 n-h-1"
                    type="cover"
                    :src="require('@/assets/image/banner.jpg')">
            </NImage>

            <div class="c-banner-text n-col-1 n-p-3">
                <h1 class="n-c-1 c-max-width n-text-center n-m-auto n-mb-1 c-f-1 n-f-1">{{ LAN.welcome }}</h1>
            </div>
        </div>

        <div class="c-max-width n-m-auto n-mt-3 n-plr-2">
            <a-row :gutter="[20, 0]"
                   class="n-flex-a-s">
                <a-col :span="24"
                       :lg="4"
                       style="padding: 0">
                    <div id="menu"
                         os="0"
                         class="n-bg-1">
                        <div class="n-plr-4 n-pt-3">
                            <a-button type="primary"
                                      size="large"
                                      class="n-mr-6 category-button n-flex-s"
                                      @click="showMenu = !showMnenu">
                                <template #icon>
                                    <MenuUnfoldOutlined />
                                </template>
                            </a-button>
                            <h2 class="n-f-bold n-c-base c-title n-b-line n-t-line n-inline-block n-pb-1">{{
                LAN.common.catalogue }}</h2>
                        </div>
                        <div class="left-menu">
                            <div class="n-plr-4">
                                <ul class="n-p-0 n-ul n-m-0 n-pb-4 c-content-left">
                                    <li class="n-f-6 n-ptb-1 n-plr-2 n-pointer c-hover n-b-line"
                                        :class="{ 'n-c-red n-selected': category == 1 }"
                                        @click="changeCategory(1)">
                                        {{ LAN.journal.home }}</li>
                                    <li class="n-f-6 n-ptb-1 n-plr-2 n-pointer n-pointer c-hover n-b-line"
                                        :class="{ 'n-c-red n-selected': category == 2 }"
                                        @click="changeCategory(2)">
                                        {{ LAN.journal.editors }}</li>
                                    <li class="n-f-6 n-ptb-1 n-plr-2 n-pointer n-pointer c-hover n-b-line"
                                        :class="{ 'n-c-red n-selected': category == 3 }"
                                        @click="changeCategory(3)">
                                        {{ LAN.journal.submissions }}</li>
                                    <li class="n-f-6 n-ptb-1 n-plr-2 n-pointer n-pointer c-hover n-b-line"
                                        :class="{ 'n-c-red n-selected': category == 4 }"
                                        v-if="(data.acceptedPapers && data.acceptedPapers.length > 0) || (data.closedIssues && data.closedIssues.length > 0)"
                                        @click="changeCategory(4)">{{ LAN.journal.accepted }}</li>
                                    <li class="n-f-6 n-ptb-1 n-plr-2 n-pointer n-pointer c-hover n-b-line"
                                        :class="{ 'n-c-red n-selected': category == 5 }"
                                        v-if="data.isOnline == 1 && this.data.onlyExternalPublish == 0"
                                        @click="changeCategory(5)">
                                        {{ LAN.journal.allVolumes }}</li>
                                    <li class="n-f-6 n-ptb-1 n-plr-2 n-pointer n-mb-2 n-pointer c-hover"
                                        v-if="data.isOnline == 1 && this.data.onlyExternalPublish == 1"
                                        @click="gotoJournalDetail">
                                        {{ LAN.journal.onlines }}</li>
                                    <li class="n-f-6 n-ptb-1 n-plr-2 n-pointer n-mb-2 n-pointer c-hover"
                                        v-if="data.isOnline == 1 && this.data.onlyExternalPublish == 0"
                                        @click="push.call(this, { name: 'journalPanel', query: { jid: $route.query.id } })">
                                        {{ LAN.journal.onlines }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </a-col>
                <a-col :span="24"
                       :lg="20">
                    <div v-if="loading"
                         class="n-h-1 n-bg-1 n-p-3">
                        <div class="thumb n-flex-s n-mr-3 n-mb-3 n-skeleton"></div>
                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-col-2"></div>
                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                        <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                    </div>

                    <div class="n-h-1 n-bg-1 n-p-3"
                         v-else>
                        <div v-if="category != 5">
                            <div class="n-flex n-flex-a-c n-flex-j-sb n-flex-w n-mb-2">
                                <h3 class="n-f-bold">{{ data.name }}<span v-if="(data.nameChinese || '') != ''">&nbsp;({{
                data.nameChinese }})</span></h3>

                                <div class="n-flex n-flex-a-c n-flex-j-e n-flex-g">
                                    <a class="n-c-base n-mr-3 c-hover-line"
                                       :href="data.externalSiteUrl"
                                       target="_blank"
                                       v-if="data.externalSiteUrl && data.onlyExternalPublish == 0">{{
                LAN.journal.readOn.replace('|SITE|', data.externalSiteName).replace('|NAME|',
                    data.name) }}</a>

                                    <a-button type="primary"
                                              shape="round"
                                              v-if="canBack()"
                                              @click="$router.go(-1)">
                                        <template #icon>
                                            <LeftOutlined />
                                        </template>
                                        {{ LAN.common.back }}
                                    </a-button>
                                </div>
                            </div>

                            <div class="n-flex content">
                                <div class="thumb n-flex-s n-mr-3 c-border n-mb-3">
                                    <NImage class="thumb-image n-col-1 n-h-1"
                                            type="cover"
                                            zoomView
                                            :src="data.coverPicture"></NImage>
                                </div>

                                <div>
                                    <div class="n-flex n-flex-w">
                                        <h4 class="n-f-bold n-m-0 n-mr-4">{{ LAN.journal.print.replace('|PRINT|',
                data.issnPrint ||
                '-') }}</h4>
                                        <h4 class="n-f-bold n-m-0">{{ LAN.journal.online.replace('|ONLINE|',
                data.issnOnline || '-') }}
                                        </h4>
                                    </div>

                                    <span class="n-block"
                                          v-if="LANI == 0">
                                        {{ (data.firstPublishYear ? LAN.journal.sinces.replace("|YEAR|", data.firstPublishYear) : '') + LAN.journal.sincep.replace("|US|", (data.issuesPerYear)) }}
                                    </span>
                                    <span class="n-block"
                                          v-else>
                                        {{  LAN.journal.sincep.replace("|US|", (data.issuesPerYear)) + (data.firstPublishYear ? LAN.journal.sinces.replace("|YEAR|", data.firstPublishYear) : '')  }}
                                    </span>

                                    <span class="n-block"
                                          v-if="(data.issuePubOtherRemark || '') != ''">{{
                data.issuePubOtherRemark || '' }}</span>
                                    <template v-for="(pItem, pIndex) in (data.editors || [])"
                                              :key="pIndex">
                                        <h4 v-if="pItem.editors?.filter(item => item.isDisplayOnTop == 1).length > 0"
                                            class="n-f-bold n-m-0 n-mt-4">{{ pItem.groupName }}
                                        </h4>
                                        <span class="n-block"
                                              v-for="(sItem, sIndex) in pItem.editors?.filter(item => item.isDisplayOnTop == 1)"
                                              :key="sIndex">{{ sItem.fullName }}{{ sItem.title ? ` (${sItem.title})` :
                '' }}</span>
                                    </template>

                                    <div class="n-mt-4">
                                        <template v-if="(data.specialIssueEditors || []).length > 0">
                                            <a @click="scrollToEditor(item.specialIssueTitle)"
                                               class="n-f-underline c-hover-1"
                                               v-for="(item, index) in data.specialIssueEditors"
                                               :key="index">{{ parseIssueTitle(item.specialIssueTitle) }}</a>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <article v-if="category == 1">
                            <h3 class="n-f-bold n-m-0 n-mt-2">{{ LAN.journal.scope }}</h3>
                            <p v-html="data.extraInfo?.aimScope"></p>
                            <h3 class="n-f-bold n-m-0 n-mt-2"
                                v-if="data.extraInfo?.introduction">{{ LAN.journal.intr }}
                            </h3>
                            <p v-html="data.extraInfo?.introduction"></p>
                            <h3 class="n-f-bold n-m-0 n-mt-2">{{ LAN.journal.pub }}</h3>
                            <p>
                                {{ data.firstPublishYear ? (LAN.journal.pubs + ' ' + (data.firstPublishYear || '-') + '.') : '' }}
                                <br v-if="data.firstPublishYear">

                                {{ data.publishMonth ? LAN.journal.peryear.replace('|US|', data.issuesPerYear) : LAN.journal.peryear1.replace('|US|', data.issuesPerYear) }}

                                {{ data.publishMonth ? LAN.journal.eachMonth +' '+ parseEachMonth(data.publishMonth) : '' }}

                                <span class="n-block"
                                      v-if="(data.issuePubOtherRemark || '') != ''">
                                    {{ data.issuePubOtherRemark || '' }}
                                </span>
                            </p>
                            <h3 class="n-f-bold n-m-0 n-mt-2"
                                v-if="data.indexing?.length > 0 || data.reviewing?.length > 0">
                                {{ LAN.journal.indexView }}</h3>
                            <h4 class="n-f-bold n-m-0 n-mt-2"
                                v-if="data.indexing?.length > 0">{{ LAN.journal.indexBy }}
                            </h4>
                            <p class="n-c-base n-m-0">
                                <a :href="item.linkUrl"
                                   class="n-block"
                                   target="_blank"
                                   v-for="(item, index) in data.indexing"
                                   :key=index>{{
                [item.fullName, item.title].filter(item => !!item).join(':') }}</a>
                            </p>
                            <h4 class="n-f-bold n-m-0 n-mt-2"
                                v-if="data.reviewing?.length > 0">{{
                LAN.journal.reviewedBy }}
                            </h4>
                            <p class="n-c-base n-m-0">
                                <a :href="item.linkUrl"
                                   class="n-block"
                                   target="_blank"
                                   v-for="(item, index) in data.reviewing"
                                   :key=index>{{
                [item.fullName, item.title].filter(item => !!item).join(':') }}</a>
                            </p>

                            <h3 class="n-f-bold n-m-0 n-mt-2"
                                v-if="data.extraInfo?.citationReportMetrics">
                                {{ LAN.journal.citation }}</h3>
                            <p class="n-m-0"
                               v-html="data.extraInfo?.citationReportMetrics"></p>

                            <h3 class="n-f-bold n-m-0 n-mt-2"
                                v-if="data.extraInfo?.sponsorship">
                                {{ LAN.journal.sponsorship }}</h3>
                            <p class="n-m-0"
                               v-if="data.extraInfo?.sponsorship"
                               v-html="data.extraInfo?.sponsorship">
                            </p>

                            <p class="n-bg-2 n-p-2 n-mt-2"
                               v-if="data.extraInfo?.remark"
                               v-html="data.extraInfo?.remark">
                            </p>

                            <h3 class="n-f-bold n-m-0 n-mt-3"
                                v-if="data.extraInfo?.specialNotices">
                                {{ LAN.journal.specialNotices }}</h3>
                            <p class="n-bg-2 n-p-2 n-mt-2"
                               v-if="data.extraInfo?.specialNotices"
                               v-html="data.extraInfo?.specialNotices">
                            </p>
                        </article>

                        <article v-if="category == 2">
                            <div v-for="(item, index) in data.fEditors"
                                 :key="index">
                                <h3 class="n-f-bold n-m-0 n-mt-5">{{ index }}</h3>

                                <a-table class="n-mt-2"
                                         :columns="editorsColumns"
                                         :pagination="false"
                                         :data-source="item">
                                    <template #bodyCell="{ column, record }">
                                        <template v-if="column.key === 'addressLine'">
                                            <!-- <div v-if="record.addressLine1 || record.addressLine2 || record.addressLine3 || record.addressLine4">
                                                <span class="n-block"
                                                      v-if="record.addressLine1">{{ record.addressLine1
                                                    }}</span>
                                                <span class="n-block"
                                                      v-if="record.addressLine2">{{ record.addressLine2
                                                    }}</span>
                                                <span class="n-block"
                                                      v-if="record.addressLine3">{{ record.addressLine3
                                                    }}</span>
                                                <span class="n-block"
                                                      v-if="record.addressLine4">{{ record.addressLine4
                                                    }}</span>
                                            </div> -->
                                            <span>{{ record.title||'-' }}</span>
                                        </template>

                                        <template v-if="column.key === 'email'">
                                            <a :href="'mailto:' + record.email"
                                               v-if="record.email"
                                               target="_blank">{{
                LAN.journal.mailTo }}</a>
                                            <span v-else>-</span>
                                        </template>

                                        <template v-if="column.key === 'webPageUrl'">
                                            <a :href="record.webPageUrl"
                                               v-if="record.webPageUrl"
                                               target="_blank">{{
                LAN.journal.webLink }}</a>
                                            <span v-else>-</span>
                                        </template>
                                    </template>
                                </a-table>
                            </div>

                            <div class="n-mt-5"
                                 v-if="(data.specialIssueEditors || []).length > 0">
                                <div v-for="(item, index) in data.specialIssueEditors"
                                     :key="index">
                                    <span :id="item.specialIssueTitle"
                                          class="n-pt-2">{{ item.specialIssueTitle }}</span>

                                    <template v-for="(item2, index2) in item.editors"
                                              :key="index2">
                                        <h3 class="n-f-bold n-m-0 n-mt-2">{{ item2.groupName }}</h3>

                                        <a-table class="n-mt-2"
                                                 :columns="editorsColumns"
                                                 :pagination="false"
                                                 :data-source="item2.editors">
                                            <template #bodyCell="{ column, record }">
                                                <template v-if="column.key === 'addressLine'">
                                                    <div v-if="record.addressLine1 || record.addressLine2 || record.addressLine3 || record.addressLine4">
                                                        <span class="n-block"
                                                              v-if="record.addressLine1">{{
                record.addressLine1 }}</span>
                                                        <span class="n-block"
                                                              v-if="record.addressLine2">{{
                record.addressLine2 }}</span>
                                                        <span class="n-block"
                                                              v-if="record.addressLine3">{{
                record.addressLine3 }}</span>
                                                        <span class="n-block"
                                                              v-if="record.addressLine4">{{
                record.addressLine4 }}</span>
                                                    </div>
                                                    <span v-else>-</span>
                                                </template>

                                                <template v-if="column.key === 'email'">
                                                    <a :href="'mailto:' + record.email"
                                                       v-if="record.email"
                                                       target="_blank">{{ LAN.journal.mailTo }}</a>
                                                    <span v-else>-</span>
                                                </template>

                                                <template v-if="column.key === 'webPageUrl'">
                                                    <a :href="record.webPageUrl"
                                                       v-if="record.webPageUrl"
                                                       target="_blank">{{ LAN.journal.webLink }}</a>
                                                    <span v-else>-</span>
                                                </template>
                                            </template>
                                        </a-table>
                                    </template>
                                </div>
                            </div>
                        </article>

                        <article v-if="category == 3">
                            <h3 class="n-f-bold n-m-0 n-mt-3">{{ LAN.journal.general }}</h3>
                            <p class="n-m-0"
                               v-html="data.submission.generalInformation || '-'"></p>
                            <h3 class="n-f-bold n-m-0 n-mt-3"
                                v-if="data.submission.aboutSubmission">
                                {{ LAN.journal.about }}</h3>
                            <p class="n-m-0"
                               v-html="data.submission.aboutSubmission"></p>
                            <h3 class="n-f-bold n-m-0 n-mt-3"
                                v-if="data.submission.selectionAcceptance">
                                {{ LAN.journal.selection }}</h3>
                            <p class="n-m-0"
                               v-html="data.submission.selectionAcceptance"></p>
                        </article>

                        <article v-if="category == 4">
                            <h3 class="n-f-bold n-m-0 n-mt-2">{{ LAN.journal.closeIssues }}</h3>

                            <a-table class="n-mt-2"
                                     :columns="columns"
                                     :pagination="false"
                                     :data-source="data.closedIssues || []"
                                     v-if="data.closedIssues.length > 0">
                                <template #bodyCell="{ column, record }">
                                    <template v-if="column.key === 'author'">
                                        <span class="n-block"
                                              v-for="(item, aIndex) in record.author.split('\n')"
                                              :key="aIndex">{{ item }}</span>
                                    </template>
                                    <template v-if="column.key === 'title'">
                                        <div :ref="'editor' + index"
                                             :id="'editor' + index">{{ record.title }}</div>
                                    </template>
                                </template>

                            </a-table>

                            <span class="n-mt-2 n-bg-2 n-p-2 n-block"
                                  v-else>{{ LAN.journal.closeIssueRight }}</span>

                            <h3 class="n-f-bold n-m-0 n-mt-6">{{ LAN.journal.acceptedTitle }}</h3>

                            <a-table class="n-mt-2"
                                     :columns="columns"
                                     :pagination="false"
                                     :data-source="data.acceptedPapers || []"
                                     v-if="data.acceptedPapers.length > 0">
                                <template #bodyCell="{ column, record }">
                                    <template v-if="column.key === 'author'">
                                        <span class="n-block"
                                              v-for="(item, aIndex) in record.author.split('\n')"
                                              :key="aIndex">{{ item }}</span>
                                    </template>
                                    <template v-if="column.key === 'title'">
                                        <div :ref="'editor' + index"
                                             :id="'editor' + index"
                                             v-html="record.title"></div>
                                    </template>
                                </template>
                            </a-table>

                            <span class="n-mt-2 n-bg-2 n-p-2 n-block"
                                  v-else>{{ LAN.journal.acceptedRight }}</span>
                        </article>

                        <div v-if="category == 5">
                            <a-row align="middle"
                                   type="flex"
                                   :wrap="true">
                                <a-col flex="1 1 auto">
                                    <h3 class="n-f-bold n-m-0 n-pb-2 n-mr-3">Volumes and issues</h3>
                                </a-col>
                                <a-col flex="0 0 auto">
                                    <div class="n-flex n-flex-a-c n-flex-j-sb n-mb-2">
                                        <a class="n-c-base n-mr-3 c-hover-line"
                                           :href="data.externalSiteUrl"
                                           target="_blank"
                                           v-if="data.externalSiteUrl && data.onlyExternalPublish == 0">{{
                LAN.journal.readOn.replace('|SITE|',
                    data.externalSiteName).replace('|NAME|', data.name)
            }}</a>

                                        <a-button type="primary"
                                                  shape="round"
                                                  v-if="canBack()"
                                                  @click="$router.go(-1)">
                                            <template #icon>
                                                <LeftOutlined />
                                            </template>
                                            {{ LAN.common.back }}
                                        </a-button>
                                    </div>
                                </a-col>
                            </a-row>
                            <a-collapse v-model:activeKey="active"
                                        class="n-mt-2">
                                <a-collapse-panel v-for="(item, index) in data.volumes || []"
                                                  :key="index">
                                    <template #header>
                                        <div class="left n-flex n-flex-a-c">

                                            <h3 class="n-m-0">{{ LAN.journal.yearVolume.replace("|Y|",
                item.fYear).replace("|V|", item.volumeNum) }}</h3>
                                        </div>
                                    </template>
                                    <ul class="n-ul n-p-0 n-m-0">
                                        <li class="n-flex n-flex-w">
                                            <div v-for="(sItem, sIndex) in item.issues"
                                                 :key="sIndex"
                                                 class="sub-item n-flex-s n-p-1">
                                                <div class="n-flex n-flex-a-c n-bg-2 n-p-2 n-radius-3 n-pointer c-hover-2"
                                                     @click="push.call(this, { name: 'journalPanel', query: { jid: $route.query.id, sid: item.id, iid: sItem.id } })">
                                                    <GlobalOutlined class="n-c-2 c-f-1 n-mr-2" />

                                                    <div class="n-flex-s">
                                                        <h4 class="n-m-0">
                                                            {{ LAN.journal.qq.replace('|Q|', sItem.issueLabel) }}</h4>
                                                    </div>
                                                    <span class="c-bg-red n-c-base n-f-bold n-line-height-1 n-ptb-1 n-plr-2 n-radius-6 n-mr-3 n-ml-3"
                                                          v-if="sItem.isNewest == 1">{{ LAN.journal.newest }}</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </a-collapse-panel>
                            </a-collapse>
                        </div>

                        <span class="n-f-2 n-c-2 n-mt-2 n-block">{{ LAN.common.updateTime }}: {{ data.updateTimeGmt }} ({{data.updateTimeLocal }})
                        </span>
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>

    <a-drawer v-model:visible="showMenu"
              placement="left"
              width="70%">
        <ul class="n-p-0 n-ul n-m-0">
            <li class="n-f-6 n-pt-1 n-pb-2 n-pointer c-hover n-b-line"
                :class="{ 'n-c-red': category == 1 }"
                @click="changeCategory(1)">{{ LAN.journal.home }}</li>
            <li class="n-f-6 n-pt-1 n-pb-2 n-pointer c-hover n-b-line"
                :class="{ 'n-c-red': category == 2 }"
                @click="changeCategory(2)">{{ LAN.journal.editors }}</li>
            <li class="n-f-6 n-pt-1 n-pb-2 n-pointer c-hover n-b-line"
                :class="{ 'n-c-red': category == 3 }"
                @click="changeCategory(3)">{{ LAN.journal.submissions }}</li>
            <li class="n-f-6 n-pt-1 n-pb-2 n-pointer c-hover n-b-line"
                v-if="(data.acceptedPapers && data.acceptedPapers.length > 0) || (data.closedIssues && data.closedIssues.length > 0)"
                :class="{'n-c-red': category == 4}"
                @click="changeCategory(4)">{{LAN.journal.accepted}}</li>
            <li class="n-f-6 n-pt-1 n-pb-2 n-pointer c-hover n-b-line"
                :class="{'n-c-red': category == 5}"
                v-if="data.isOnline == 1 && this.data.onlyExternalPublish == 0"
                @click="changeCategory(5)">
                {{LAN.journal.allVolumes}}</li>
            <li class="n-f-6 n-pt-1 n-pb-2 n-mb-2 n-pointer c-hover"
                v-if="data.isOnline == 1 && this.data.onlyExternalPublish == 1"
                @click="gotoJournalDetail">
                {{LAN.journal.onlines}}</li>
            <li class="n-f-6 n-pt-1 n-pb-2 n-mb-2 n-pointer c-hover"
                v-if="data.isOnline == 1 && this.data.onlyExternalPublish == 0"
                @click="push.call(this, {name: 'journalPanel', query: {jid: $route.query.id}})">{{LAN.journal.onlines}}
            </li>

        </ul>
    </a-drawer>
</template>
<script>
    import { mapMutations, mapState } from 'vuex';

    import { SwapLeftOutlined, SwapRightOutlined, MenuFoldOutlined, MenuUnfoldOutlined, CaretRightOutlined, UndoOutlined, GlobalOutlined } from '@ant-design/icons-vue';
    import NImage from '@/components/nui/NImage/NImage.vue'
    import Breadcrumb from '@/components/Breadcrumb.vue'
    import NEditorParserLocal from '@/components/NEditorParserLocal.vue';
    import nui from '@/assets/script';

    export default {
        name: 'JournalList',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                loading: false,
                active: [],
                category: 1,
                showMenu: false,
                data: {},
                columns: [],
                editorsColumns: [],
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),
            NActivated () {
                if (this.$route.query.p) {
                    this.category = this.$route.query.p
                }
            },
            NMounted () {
                if (this.$route.query.p) {
                    this.category = this.$route.query.p
                }

                this.columns = [{
                    title: this.LAN.journal.actIssue,
                    dataIndex: 'forIssue',
                    key: 'forIssue',
                    width: 100
                }, {
                    title: this.LAN.journal.actSeq,
                    dataIndex: 'seqNo',
                    key: 'seqNo',
                    width: 60
                }, {
                    title: this.LAN.journal.actTitle,
                    dataIndex: 'title',
                    key: 'title',
                }, {
                    title: this.LAN.journal.actAuthor,
                    dataIndex: 'author',
                    key: 'author',
                }, {
                    title: this.LAN.journal.actDate,
                    width: 120,
                    dataIndex: 'dateOfAcceptance',
                    key: 'dateOfAcceptance',
                }]
                this.editorsColumns = [{
                    title: this.LAN.journal.editor.name,
                    dataIndex: 'fullName',
                    key: 'fullName',
                    width: 180,
                }, {
                    title: this.LAN.journal.editor.affilliation,
                    dataIndex: 'addressLine',
                    key: 'addressLine',
                }, {
                    title: this.LAN.journal.editor.contact,
                    dataIndex: 'email',
                    key: 'email',
                    width: 90,
                }, {
                    title: this.LAN.journal.editor.webPage,
                    dataIndex: 'webPageUrl',
                    key: 'webPageUrl',
                    width: 60,
                }, {
                    title: this.LAN.journal.editor.expertise,
                    width: 140,
                    dataIndex: 'expertise',
                    key: 'expertise',
                }]
                this.loadData()
            },
            parseIssueTitle (str) {
                if (str.substr(-1) == ':') {
                    return str.substr(0, str.length - 1)
                }
            },
            changeCategory (index) {
                this.category = index;
                this.showMenu = false;
                this.$nextTick(() => {
                    this.parseMathJax()
                })
            },
            loadData () {
                this.loading = true;

                return this.request(this.API.journalList, {
                    requestId: 'journalList',
                    params: { id: this.$route.query.id }
                }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE && res.data && res.data.id) {
                        var editors = res.data.editors || [],
                            fEditors = {};

                        editors.forEach(item => {
                            fEditors[item.groupName] = item.editors.map(item => {
                                item.expertise = item.expertise || '-';
                                return item
                            });
                        });

                        var closeIssues = res.data.closedIssues || [];
                        var acceptedPapers = res.data.acceptedPapers || [];
                        closeIssues.forEach(e => {
                            e.dateOfAcceptance = e.dateOfAcceptance ? nui.parseDateFormat(e.dateOfAcceptance) : '';
                        });
                        acceptedPapers.forEach(e => {
                            e.dateOfAcceptance = e.dateOfAcceptance ? nui.parseDateFormat(e.dateOfAcceptance) : '';
                        });

                        res.data.fEditors = fEditors;
                        res.data.closeIssues = closeIssues;
                        res.data.acceptedPapers = acceptedPapers;
                        this.data = res.data

                        var openIId = this.$route.query.openIId
                        var openVId = this.$route.query.openVId

                        if (openIId) {
                            var opened = []

                            res.data.volumes.forEach((item, index) => {
                                if (item.issues.filter(sItem => sItem.id == openIId).length > 0) {
                                    opened.push(index)
                                }
                            })

                            this.active = opened
                        } else if (openVId) {
                            var opened = []

                            res.data.volumes.forEach((item, index) => {
                                if (item.id == openVId) {
                                    opened.push(index)
                                }
                            })

                            this.active = opened
                        } else {
                            var news = []

                            res.data.volumes.forEach((item, index) => {
                                if (item.issues.filter(sItem => sItem.isNewest).length > 0) {
                                    news.push(index)
                                }
                            })

                            this.active = news
                        }

                        if (this.data.isOnline != 1 && this.category == 5) {
                            this.category = 1
                        }

                    }
                    else {
                        this.$router.push({ name: '404page' });
                    }
                    this.loading = false
                    this.$nextTick(() => {
                        this.parseMathJax()
                    })
                }).catch(err => {
                    this.loading = false;

                    if (err.code != 'ERR_CANCELED') {
                        this.$router.push({ name: '404page' });
                    }
                })
            },
            gotoJournalDetail () {
                //仅在外部发布的期刊，如JDG跳转到外部网站链接
                if (this.data.onlyExternalPublish == 1) {
                    window.open(this.data.externalSiteUrl, '_blank')
                } else {
                    this.$router.push({ name: 'journalPanel', query: { jid: $route.query.id } })
                }
            },
            scrollToEditor (title) {
                this.category = 2

                this.$nextTick(() => {
                    var aim = document.getElementById(title)

                    aim.scrollIntoView()
                })
            },
            parseEachMonth (str) {
                if (!str) {
                    return '-.'
                }

                var arr = str.split(',').map(item => this.LAN.month[item]),
                    last = arr[arr.length - 1];

                arr.pop()

                return arr.join(', ') + ' and ' + last + '.'
            }
        }),
        components: {
            SwapLeftOutlined,
            SwapRightOutlined,
            MenuFoldOutlined,
            MenuUnfoldOutlined,
            NEditorParserLocal,
            CaretRightOutlined,
            NImage,
            UndoOutlined,
            Breadcrumb,
            GlobalOutlined
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .thumb {
        width: 150px;
        height: 220px;

        .thumb-image {
            transition: all 0.3s;
        }
    }

    .category-button {
        display: none;
    }

    .c-title {
        display: block;

        &:before {
            width: 90%;
        }

        &:after {
            width: 100%;
        }
    }

    @media screen and (max-width: 1100px) {
        .left-menu {
            display: none;
        }

        .c-title {
            display: none;
        }

        .category-button {
            display: inline-block;
        }
    }
</style>