<template>
    <div class="n-pb-4 n-bg-1">
    </div>
</template>
<script>
    import { mapMutations, mapState } from 'vuex';

    import nui from '@/assets/script';

    export default {
        name: 'JournalAbbr',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {});
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            NMounted () {
                let routeInfo = this.$route;
                if (routeInfo && routeInfo.redirectedFrom && routeInfo.redirectedFrom.path) {
                    let abbr = routeInfo.redirectedFrom.path.split("/")[1];

                    console.log('abbr', abbr);

                    this.request(this.API.periodicalByAbbr, { params: { abbr: abbr } }).then(res => {
                        if (res.code == this.REQUEST_SUCCESS_CODE && res.data && res.data != "-1") {
                            this.$router.push({ name: 'journalList', query: { id: res.data } })
                        } else {
                            this.$router.push({ name: '404page' });
                        }
                    });
                } else {
                    this.$router.push({ name: '404page' });
                }
            },

        }),
        components: {
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .tag-list {
        width: 210px;

        li {
            position: relative;

            &:hover {
                .tag-list-item {
                    transform: scale(1.2);
                }
            }
        }

        .tag-list-item {
            transition: all 0.3s;
            width: 100%;
            height: 120px;
        }

        .tag-content {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 1;
            }

            h1 {
                position: relative;
                z-index: 2;
                font-size: 36px;

                small {
                    font-size: 16px;
                    position: relative;
                    top: -2px;
                }
            }

            h4 {
                position: relative;
                z-index: 2;
            }
        }
    }

    .home-banner-text {
        h1 {
            width: 465px;
            line-height: 1.2;
        }
    }

    .mid {
        width: 70%;
        margin: 0 auto;
        background-image: linear-gradient(
            90deg,
            rgba(251, 225, 230, 0.69),
            rgba(255, 255, 255, 0)
        );
    }

    @media screen and (max-width: 1100px) {
        .home-banner-text {
            display: block;

            h1 {
                margin-bottom: 10px;
                width: 100%;
                text-align: center;
            }

            h3 {
                margin-top: 10px;
            }
        }

        .mid {
            width: 100%;
        }
    }
</style>