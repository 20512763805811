<template>
    <div class="n-pb-4">
        <div class="c-banner c-banner-small n-col-1 n-border-box n-overflow-hidden">
            <NImage class="c-banner-image n-col-1 n-h-1"
                    type="cover"
                    :src="require('@/assets/image/banner.jpg')"></NImage>

            <div class="c-banner-text n-col-1 n-p-3">
                <h1 class="n-c-1 c-max-width n-text-center n-m-auto n-mb-1 c-f-1 n-f-1">{{LAN.welcome}}</h1>
            </div>
        </div>

        <div class="c-max-width n-m-auto n-bg-2 n-mt-4 n-plr-2">
            <h2 class="n-f-bold n-c-base c-title n-b-line n-t-line n-inline-block n-pb-1 n-mb-4">{{LAN.common.contactUs}}</h2>

            <a-row :gutter="[15,15]">
                <a-col :span="24"
                       :lg="8">
                    <a-card class="n-col-1 n-h-1 c-card">
                        <template #title>
                            <div class="n-flex n-flex-a-c contact-title">
                                <h4 class="n-e-2 n-space n-f-bold n-m-0">General mailing address</h4>
                            </div>
                        </template>

                        <p class="n-m-0 n-p-0">International Press, Inc.</p>
                        <p class="n-m-0 n-p-0">P.O. Box 502</p>
                        <p class="n-m-0 n-p-0">Somerville, MA 02143</p>
                        <p class="n-m-0 n-p-0">U.S.A.</p>
                    </a-card>
                </a-col>
                <a-col :span="24"
                       :lg="8">
                    <a-card class="n-col-1 n-h-1 c-card">
                        <template #title>
                            <div class="n-flex n-flex-a-c contact-title">
                                <h4 class="n-e-2 n-space n-f-bold n-m-0">Shipping & express mailing address</h4>
                            </div>
                        </template>

                        <p class="n-m-0 n-p-0">International Press, Inc.</p>
                        <p class="n-m-0 n-p-0">387 Somerville Avenue</p>
                        <p class="n-m-0 n-p-0">Somerville, MA 02143</p>
                        <p class="n-m-0 n-p-0">U.S.A.</p>
                    </a-card>
                </a-col>
                <a-col :span="24"
                       :lg="8">
                    <a-card class="n-col-1 n-h-1 c-card">
                        <template #title>
                            <div class="n-flex n-flex-a-c contact-title">
                                <h4 class="n-e-2 n-space n-f-bold n-m-0">Orders</h4>
                            </div>
                        </template>

                        <p class="n-m-0 n-p-0"><a href="mailto://ipb-orders@intlpress.com"
                               style="color:#0A87FD">ipb-orders@intlpress.com</a></p>
                        <p class="n-m-0 n-p-0">tel +1 617-623-3016</p>
                        <p class="n-m-0 n-p-0">fax +1 617-623-3101</p>
                    </a-card>
                </a-col>
                <a-col :span="24"
                       :lg="8">
                    <a-card class="n-col-1 n-h-1 c-card">
                        <template #title>
                            <div class="n-flex n-flex-a-c contact-title">
                                <h4 class="n-e-2 n-space n-f-bold n-m-0">Claims for unreceived printed journal issues and books</h4>
                            </div>
                        </template>

                        <p class="n-m-0 n-p-0"><a href="mailto://ipb-claims@intlpress.com"
                               style="color:#0A87FD">ipb-claims@intlpress.com</a></p>
                    </a-card>
                </a-col>
                <a-col :span="24"
                       :lg="8">
                    <a-card class="n-col-1 n-h-1 c-card">
                        <template #title>
                            <div class="n-flex n-flex-a-c contact-title">
                                <h4 class="n-e-2 n-space n-f-bold n-m-0">Online access to journal content (lost access, changes to IP addresses)</h4>
                            </div>
                        </template>

                        <p class="n-m-0 n-p-0"><a href="mailto://online-access@intlpress.com"
                               style="color:#0A87FD">online-access@intlpress.com</a></p>
                    </a-card>
                </a-col>
                <a-col :span="24"
                       :lg="8">
                    <a-card class="n-col-1 n-h-1 c-card">
                        <template #title>
                            <div class="n-flex n-flex-a-c contact-title">
                                <h4 class="n-e-2 n-space n-f-bold n-m-0">Journal production</h4>
                            </div>
                        </template>

                        <p class="n-m-0 n-p-0">(For typesetting vendors & editorial offices only)</p>
                        <p class="n-m-0 n-p-0">Send ready-to-publish materials to:</p>
                        <p class="n-m-0 n-p-0"><a href="mailto://journalproduction@intlpress.com"
                               style="color:#0A87FD">journalproduction@intlpress.com</a></p>
                    </a-card>
                </a-col>
                <a-col :span="24"
                       :lg="8">
                    <a-card class="n-col-1 n-h-1 c-card">
                        <template #title>
                            <div class="n-flex n-flex-a-c contact-title">
                                <h4 class="n-e-2 n-space n-f-bold n-m-0">Journal author consent-to-publish agreements</h4>
                            </div>
                        </template>

                        <p class="n-m-0 n-p-0">Send the appropriate agreement signed to:</p>
                        <p class="n-m-0 n-p-0"><a href="mailto://authorctp@intlpress.com"
                               style="color:#0A87FD">authorctp@intlpress.com</a></p>
                        <p class="n-m-0 n-p-0">tel. +1 617-625-0480</p>
                    </a-card>
                </a-col>
                <a-col :span="24"
                       :lg="8">
                    <a-card class="n-col-1 n-h-1 c-card">
                        <template #title>
                            <div class="n-flex n-flex-a-c contact-title">
                                <h4 class="n-e-2 n-space n-f-bold n-m-0">Book production / Webmaster</h4>
                            </div>
                        </template>

                        <p class="n-m-0 n-p-0">Mr. Zhang</p>
                        <p class="n-m-0 n-p-0"><a href="https://www.un-bug.com"
                               style="color:#0A87FD"
                               target="_blank">https://www.un-bug.com</a></p>
                        <p class="n-m-0 n-p-0"> <a href="mailto://sbin@un-bug.com"
                               style="color:#0A87FD">sbin@un-bug.com</a></p>
                        <p class="n-m-0 n-p-0">tel: +86 13812756879</p>
                    </a-card>
                </a-col>
                <a-col :span="24"
                       :lg="8">
                    <a-card class="n-col-1 n-h-1 c-card">
                        <template #title>
                            <div class="n-flex n-flex-a-c contact-title">
                                <h4 class="n-e-2 n-space n-f-bold n-m-0">Permission requests</h4>
                            </div>
                        </template>

                        <p class="n-m-0 n-p-0">Requests for permission to use copyrighted material owned by International Press</p>
                        <p class="n-m-0 n-p-0">Send your request to:</p>
                        <p class="n-m-0 n-p-0"><a href="mailto://permission-requests@intlpress.com"
                               style="color:#0A87FD">permission-requests@intlpress.com</a></p>
                    </a-card>
                </a-col>
                <a-col :span="24"
                       :lg="8">
                    <a-card class="n-col-1 n-h-1 c-card">
                        <template #title>
                            <div class="n-flex n-flex-a-c contact-title">
                                <h4 class="n-e-2 n-space n-f-bold n-m-0">General Manager</h4>
                            </div>
                        </template>

                        <p class="n-m-0 n-p-0">Mr. Lixin Qin</p>
                        <p class="n-m-0 n-p-0"><a href="mailto://lixin@intlpress.com"
                               style="color:#0A87FD">lixin@intlpress.com</a></p>
                    </a-card>
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script >
    import { mapMutations, mapState } from 'vuex';
    import { StarOutlined, LikeOutlined, MessageOutlined } from '@ant-design/icons-vue';
    import NImage from '@/components/nui/NImage/NImage.vue'

    import nui from '@/assets/script';

    export default {
        name: 'Contact',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                pageInfo: { currentPage: 1, totalPage: 100 }
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            NMounted () {
            }
        }),
        components: {
            StarOutlined,
            LikeOutlined,
            MessageOutlined,
            NImage
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .contact-title {
        height: 50px;
        line-height: 25px;
    }

    .c-card {
        transition: all 0.3s;

        h4 {
            transition: all 0.3s;
        }

        &:hover {
            box-shadow: $shadow;
            transform: translateY(-5px);

            h4 {
                color: $color-base;
            }
        }
    }
</style>