<template>
    <div class="n-pb-4">
        <div class="c-banner-jdetail n-col-1 n-border-box n-overflow-hidden">
            <!-- <NImage class="c-banner-image n-col-1 n-h-1"
                    type="cover"
                    :src="require('@/assets/image/banner.jpg')">
            </NImage> -->

            <div class="c-banner-text-jdetail c-max-width n-m-auto n-p-3 n-flex n-flex-a-c home-banner-text">
                <a-row :gutter="[20]"
                       class="n-col-1"
                       align="middle"
                       v-if="contentData.title">
                    <a-col :span="24"
                           :lg="18">

                        <div class="n-flex n-flex-a-c n-f-6">
                            <span class="n-f-normal n-m-0 c-hover n-pointer n-c-1 c-link">{{ LAN.nav.home }}</span>
                            <span class="n-f-normal n-m-0 c-hover n-pointer n-c-1 n-plr-1">></span>
                            <span class="n-f-normal n-m-0 c-hover n-pointer n-c-1 c-link"
                                  @click="goHome">{{ data.name }} </span>
                        </div>

                        <h3 class="n-f-normal j-detail-title n-m-0 n-c-1 n-mt-4"
                            v-html="contentData.title"></h3>

                        <div class="n-c-1 n-mt-1 n-block">
                            <span class=""
                                  v-if="contentData.startPage && contentData.endPage">{{ LAN.journal.pages }}
                                {{ contentData.startPage }}-{{ contentData.endPage }}</span>

                            <span class="n-mt-1 n-flex-g n-c-1 n-block">
                                {{ LAN.journal.yearVolume.replace("|Y|", volumeData.fYear).replace("|V|",
                    volumeData.volumeNum) }} {{ LAN.journal.qq.replace('|Q|', issueData.issueLabel) }}</span>

                            <span class="n-c-1 n-block n-mt-1 n-pointer c-link c-hover"
                                  v-if="contentData.doi">{{ contentData.doi
                                    }}</span>
                        </div>

                        <div class="n-flex n-flex-a-c n-flex-j-sb n-mt-6">
                            <a-button type="primary"
                                      v-if="authChecked && auth && CONTENT"
                                      @click="openPDF">{{ LAN.common.PDFAll }}</a-button>

                            <a-button type="primary"
                                      v-if="authChecked && !auth && CONTENT"
                                      @click="goContact">{{ LAN.common.subscribe }}</a-button>

                            <a class="n-c-1 c-hover-line n-block n-flex-g n-ml-2 c-link"
                               :href="data.externalSiteUrl"
                               target="_blank"
                               v-if="data.externalSiteUrl && data.onlyExternalPublish == 0">{{ LAN.journal.readOn.replace('|SITE|', data.externalSiteName).replace('|NAME|', data.name) }}</a>
                        </div>

                        <!-- <div class="n-flex n-flex-s">

                            <a-button type="primary"
                                      shape="round"
                                      @click="$router.go(-1)"
                                      v-if="canBack()">
                                <template #icon>
                                    <LeftOutlined />
                                </template>
                                {{ LAN.common.back }}
                            </a-button>
                        </div> -->
                    </a-col>

                    <a-col :span="24"
                           :lg="6">
                        <div class="n-flex n-flex-dr-c n-flex-a-c">
                            <div class="thumb n-flex-s n-mt-2">
                                <NImage class="thumb-image n-col-1 n-h-1"
                                        type="cover"
                                        offsetY="30px"
                                        zoomView
                                        :size="300"
                                        :src="volumeData.coverPicture"></NImage>
                            </div>

                            <div class="n-mt-2 quik-link">
                                <!-- <h3 class="n-f-normal n-m-0 n-c-1 n-text-center c-hover n-pointer c-link"
                                    @click="goHome">{{ LAN.journal.journalHome }}</h3> -->
                                <h3 class="n-f-normal n-m-0 n-c-1 n-text-center c-hover n-pointer c-link"
                                    v-if="data.isOnline == 1"
                                    @click="goOnline">{{ LAN.journal.allVolumes }}
                                    <ArrowRightOutlined class="link-arrow" />
                                </h3>
                                <h3 class="n-f-normal n-m-0 n-c-1 n-text-center c-hover n-pointer c-link"
                                    v-if="data.isOnline == 1 && data.isAnnualIssue != 1"
                                    @click="goThisVolume">{{ LAN.journal.thisVolume }}
                                    <ArrowRightOutlined class="link-arrow" />
                                </h3>
                                <!-- <h3 class="n-f-normal n-m-0 n-c-1 n-text-center c-hover n-pointer c-link"
                                    @click="$router.push({ name: 'journalPanel', query: { jid: ids.jid, sid: ids.sid, iid: ids.iid } })">
                                    {{ LAN.journal.thisIssue }}</h3> -->
                            </div>
                        </div>

                    </a-col>
                </a-row>
            </div>
        </div>

        <div class="c-max-width n-m-auto n-mt-3 n-plr-2">
            <a-row :gutter="[20, 10]">
                <a-col :span="24"
                       :lg="18">
                    <div class="n-bg-1 n-p-3"
                         v-if="loading">
                        <ul class="n-ul n-p-0 list n-bg-1 n-mt-2">
                            <li class="n-pointer n-p-4"
                                v-for="item in 3"
                                :key="item">
                                <div class="n-skeleton n-skeleton-text n-ptb-4 n-col-3"></div>
                                <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                                <div class="n-skeleton n-skeleton-text n-ptb-4 n-mt-3"></div>
                            </li>
                        </ul>
                    </div>

                    <div class="n-bg-1 n-p-3"
                         v-else>

                        <article>
                            <div class="n-flex-s n-mt-2 n-bg-2 n-p-2"
                                 v-if="contentData?.specialIssueTitle || contentData?.specialIssueEditors">
                                <div v-html="contentData.specialIssueTitle"></div>
                                <div v-html="contentData.specialIssueEditors"></div>
                            </div>

                            <h4 class="n-f-bold n-m-0 n-mt-6">{{ LAN.journal.author+(contentData.authors?.length > 1?'s':'') }}</h4>
                            <p v-if="contentData.authors?.length > 0">
                                <span class="n-block author-row"
                                      target="_blank"
                                      v-for="(item, index) in contentData.authors"
                                      :key=index>{{ item.fullName
                                    }}{{ item.title ? `（${item.title}）` : '' }}</span>
                            </p>

                            <div v-if="contentData.fAbstract">
                                <h4 class="n-f-bold n-m-0 n-mt-6">{{ LAN.journal.abstract }}</h4>
                                <div v-html="contentData.fAbstract || '-'"></div>
                            </div>

                            <h4 class="n-f-bold n-m-0 n-mt-6"
                                v-if="contentData.keyword">{{ LAN.journal.keyword }}</h4>
                            <div v-if="contentData.keyword"
                                 v-html="contentData.keyword"></div>

                            <h4 class="n-f-bold n-m-0 n-mt-6"
                                v-if="contentData.msc2010">{{ LAN.journal.msc2010 }}</h4>
                            <div class="n-mt-2"
                                 v-if="contentData.msc2010">{{ contentData.msc2010 }}</div>

                            <div class="n-bg-2 n-mt-6 n-p-2"
                                 v-if="contentData.remark"
                                 v-html="contentData.remark">
                            </div>

                            <p class="n-mt-6">
                                <span v-if="contentData.receiveDate"> {{ LAN.journal.received }} <span class="n-f-bold n-ml-2">{{
                contentData.receiveDate || '-' }}</span><br></span>
                                <span v-if="contentData.receivedRevisedDate">{{ LAN.journal.receivedRevised }} <span class="n-f-bold n-ml-2">{{
                contentData.receivedRevisedDate ||
                '-' }}</span><br></span>
                                <span v-if="contentData.acceptedDate"> {{ LAN.journal.accepted }} <span class="n-f-bold n-ml-2">{{
                contentData.acceptedDate || '-' }}</span><br></span>
                                <span v-if="contentData.publishDate"> {{ LAN.journal.published }} <span class="n-f-bold n-ml-2">{{
                contentData.publishDate || '-' }}</span></span>
                            </p>
                        </article>

                        <span class="n-f-2 n-c-2 n-mt-2 n-block"
                              v-if="contentData.updateTimeGmt">{{ LAN.common.updateTime }}: {{ contentData.updateTimeGmt }} ({{contentData.updateTimeLocal }})
                        </span>
                    </div>
                </a-col>
                <a-col :span="24"
                       :lg="6">
                    <div class="n-bg-1 n-p-3">
                        <h1 class="n-m-0 n-f-bold c-hover n-pointer c-link"
                            @click="$router.push({ name: 'journalPanel', query: { jid: ids.jid, sid: ids.sid, iid: ids.iid } })">
                            {{ LAN.journal.thisIssue }}
                            <ArrowRightOutlined class="link-arrow" />
                        </h1>

                        <ul class="n-mt-6 n-ul n-p-0">
                            <li v-for="item in contents"
                                :key="item.id"
                                class="c-link n-mb-4 n-f-6 n-line-height-1-5 n-pointer c-hover"
                                :class="{'n-c-UI-blue': item.id == this.ids.cid}"
                                @click="goContent(item)">
                                {{ item.title }}
                                <ArrowRightOutlined class="link-arrow" />
                            </li>
                        </ul>
                    </div>
                </a-col>
            </a-row>
        </div>

        <a-modal v-model:visible="showViewCONTENT"
                 centered
                 width="calc(100vw - 10px)"
                 height="calc(100vh - 10px)"
                 class="pdf-dialog"
                 v-if="CONTENT && auth && showViewCONTENT"
                 :bodyStyle="{padding: 0}"
                 :title="CONTENT.originalName"
                 :footer="null"
                 @ok="showViewCONTENT = false">
            <DocView :url="CONTENT.resourceUrl"
                     :name="CONTENT.originalName"
                     class="pdf"></DocView>
        </a-modal>
    </div>
</template>
<script>
    import { mapMutations, mapState } from 'vuex';
    import { Modal } from 'ant-design-vue'
    import NImage from '@/components/nui/NImage/NImage.vue'
    import DocView from '@/components/nui/DocView/DocView.vue'
    import Breadcrumb from '@/components/Breadcrumb.vue'
    import { LinkOutlined, ArrowRightOutlined } from '@ant-design/icons-vue';
    import crypto from '@/assets/script/crypto';
    import nui from '@/assets/script';
    import LAN from '@/assets/script/LAN';

    export default {
        name: 'BDetail',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                ids: {},
                auth: false,
                authChecked: false,

                showViewTOC: false,
                showViewCONTENT: false,
                showViewpPreview: false,


                loading: false,
                data: {},
                contentData: {},
                volumeData: {},
                issueData: {},
                contents: [],

                CONTENT: null
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            NMounted () {
                this.loading = true;

                let viewtype = this.$route.params.viewtype || '';

                this.request(this.API.getJDetailIds, {
                    params: { id: this.$route.params.cid, viewType: viewtype == 'none' ? '' : viewtype }
                }).then(res => {
                    if (res.code == 200) {
                        this.ids = res.data
                        this.loadData()
                    } else {
                        this.$router.push({ name: '404page' });
                    }
                }).catch(err => {
                    this.$router.push({ name: '404page' });
                })
            },
            checkContentPermission () {
                this.auth = false;
                this.authChecked = false;
                if (!this.CONTENT) {
                    return
                }
                let viewtype = this.$route.params.viewtype || '';

                this.request(this.API.checkContentPermission, {
                    params: {
                        dataid: this.ids.cid,
                        datatype: "volume",
                        viewType: viewtype == 'none' ? '' : viewtype
                    }
                }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE) {
                        this.auth = res.data.hasAuth;
                        this.authChecked = true;
                    }
                })
            },
            checkAuth (callback) {
                if (!this.CONTENT) {
                    return
                }

                let viewtype = this.$route.params.viewtype || '';

                this.request(this.API.pdfCheckAuth, {
                    params: {
                        dataid: this.ids.cid,
                        datatype: "volume",
                        name: this.CONTENT.name,
                        viewType: viewtype == 'none' ? '' : viewtype
                    }
                }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE) {
                        this.auth = res.data.hasAuth;
                        let url = crypto.decrypt(res.data.url);
                        callback && callback(url);
                    }
                })
            },
            openPDF () {
                this.checkAuth((url) => {
                    if ((url || '') == '') {
                        return;
                    }
                    this.CONTENT.resourceUrl = url;
                    this.showViewCONTENT = true;
                });
            },
            loadData () {
                this.loading = true

                if (!this.ids.jid || !this.ids.cid) {
                    return;
                }

                let viewtype = this.$route.params.viewtype || '';

                var a = this.request(this.API.journalList, {
                    requestId: 'JDJL',
                    params: {
                        id: this.ids.jid,
                        viewType: viewtype == 'none' ? '' : viewtype
                    }
                }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE && res.data && res.data.id) {
                        this.data = res.data
                        this.volumeData = res.data.volumes.filter(item => item.id == this.ids.sid)[0] || {};
                        this.issueData = this.volumeData.issues?.filter(item => item.id == this.ids.iid)[0] || {};
                        // 
                        if (!this.volumeData.id) {
                            this.$router.push({ name: '404page' });
                        }

                        if (!this.issueData.id) {
                            this.$router.push({ name: '404page' });
                        }
                    } else {
                        this.$router.push({ name: '404page' });
                    }
                })

                var b = this.request(this.API.journalDetail, {
                    requestId: 'JDJD',
                    params: {
                        contentId: this.ids.cid,
                        viewType: viewtype == 'none' ? '' : viewtype
                    }
                }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE && res.data && res.data.id) {
                        this.contentData = res.data

                        this.CONTENT = (this.contentData.contentPdf || []).filter(item => item.relationType == 'periodical_issue_content_pdf')[0]

                        this.checkContentPermission();
                        //YAU-486 日期格式化
                        this.contentData.receiveDate = nui.parseDateFormat(this.contentData.receiveDate);
                        this.contentData.receivedRevisedDate = nui.parseDateFormat(this.contentData.receivedRevisedDate);
                        this.contentData.acceptedDate = nui.parseDateFormat(this.contentData.acceptedDate);
                        this.contentData.publishDate = nui.parseDateFormat(this.contentData.publishDate);


                    } else {
                        this.$router.push({ name: '404page' });
                    }
                })

                var c = this.request(this.API.journalPanel, {
                    requestId: 'journalPanel',
                    params: { periodicalId: this.ids.jid, issueId: this.ids.iid, viewType: viewtype == 'none' ? '' : viewtype }
                }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE && res.data && res.data.issueId && res.data.volumeId && res.data.periodicalId) {
                        this.contents = res.data.content
                    } else {
                        this.$router.push({ name: '404page' });
                    }
                })

                Promise.all([a, b, c]).then(() => {
                    this.loading = false

                    this.$nextTick(() => {
                        this.parseMathJax()
                    })
                }).catch((err) => {
                    this.loading = false

                    if (err.code != 'ERR_CANCELED') {
                        this.$router.push({ name: '404page' });
                    }
                })
            },
            goHome () {
                if (this.data.isAnnualIssue == 1) {
                    this.push.call(this, { name: 'bookSuccessionList', query: { id: this.data.serialId } })
                } else {
                    this.$router.push({ name: 'journalList', query: { id: this.ids.jid } })
                }
            },
            goOnline () {
                if (this.data.isAnnualIssue == 1) {
                    this.push.call(this, { name: 'journalAnnualPanel', query: { jid: this.ids.jid, sid: this.data.serialId } })
                } else {
                    if (this.data.onlyExternalPublish == 1) {
                        window.open(this.data.externalSiteUrl, '_blank')
                    } else {
                        this.$router.push({ name: 'journalList', query: { p: 5, id: this.ids.jid } })
                    }
                }
            },
            goThisVolume () {
                if (this.data.onlyExternalPublish == 1) {
                    window.open(this.data.externalSiteUrl, '_blank')
                } else {
                    this.$router.push({ name: 'journalList', query: { p: 5, id: this.ids.jid, openIId: this.ids.iid } })
                }
            },
            goContent (data) {
                let viewtype = this.$route.params.viewtype || '';

                this.$router.push({ name: 'JDetail', params: { cid: data.id, viewtype } })
            },
            goContact () {
                this.$router.push({ name: 'contact' })
            }
        }),
        components: {
            NImage,
            DocView,
            Breadcrumb,
            LinkOutlined,
            ArrowRightOutlined
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .thumb {
        width: 170px;
        height: 210px;

        .thumb-image {
            transition: all 0.3s;
        }
    }

    .title-icon {
        background-color: rgb(255, 226, 186);
        color: rgb(255, 162, 66);
        padding: 5px;
    }

    .list-item {
        transition: all 0.3s;

        &:hover {
            box-shadow: $shadow-2;
        }
    }

    .book-introduce {
        table {
            display: table;
        }

        ul {
            display: none;
        }
    }

    @media screen and (max-width: 1100px) {
        .book-introduce {
            table {
                display: none;
            }

            ul {
                display: block;
            }
        }

        .book-top {
            display: block;

            .book-info {
                margin-top: 15px;
                width: auto;
            }
        }
    }
</style>
<style lang="scss">
    .pdf-dialog {
        max-width: 100% !important;

        .ant-modal-header {
            background: rgba(56, 56, 61, 1);
            padding: 10px;
            height: 40px;
            border-bottom: 0;
        }

        .ant-modal-title {
            color: $font-color-1;
        }

        .ant-modal-close-x {
            color: $font-color-1;
            width: 40px;
            height: 40px;
            line-height: 40px;
        }

        .ant-modal-content {
            height: 100%;
        }

        .ant-modal-body {
            height: calc(100% - 40px);
        }
    }

    .author-row {
        text-indent: -2em;
        margin-left: 2em;
    }

    .j-detail-title {
        font-size: 40px;
        line-height: 48px;
    }

    @media screen and (max-width: 990px) {
        .quik-link {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-top: 10px;
            flex-wrap: wrap;
            h3 {
                padding: 0 5px;
            }
        }
    }

    .c-banner-jdetail {
        background-color: rgb(59, 3, 14);

        &:after {
            display: none;
        }
    }

    .link-arrow {
        position: relative;
        top: 1px;
        font-size: 12px;
    }
</style>