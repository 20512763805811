<template>
    <div class="pdf-container">
        <iframe ref="printIframe" frameborder="0" class="pdf"></iframe>
    </div>
</template>

<script>
import { Buffer } from 'buffer'
import { CloseOutlined } from '@ant-design/icons-vue';

export default {
    name: 'DocView',
    props: { url: String, name: String },
    methods: {
    },
    data () {
        return {
        }
    },
    mounted () {
        var url = '/web/viewer.html?file=' + encodeURIComponent(this.url);
        this.$refs.printIframe.contentWindow.location.replace(url);
    },
    components: {
        CloseOutlined
    }
};
</script>
<style lang="scss" scoped>
.pdf-container {
    position: relative;
    overflow: hidden;
    height: 100%;

    .pdf {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}
</style>
