<template>
    <div class="n-pb-4">
        <div class="c-banner c-banner-small n-col-1 n-border-box n-overflow-hidden">
            <NImage class="c-banner-image n-col-1 n-h-1"
                    type="cover"
                    :src="require('@/assets/image/banner.jpg')"></NImage>

            <div class="c-banner-text n-col-1 n-p-3">
                <h1 class="n-c-1 c-max-width n-text-center n-m-auto n-mb-1 c-f-1 n-f-1">{{LAN.welcome}}</h1>
            </div>
        </div>

        <div class="c-max-width n-m-auto n-mt-3 n-plr-2">
            <a-row :gutter="[20, 0]"
                   class="n-flex-a-s">
                <a-col :span="24"
                       :lg="4"
                       style="padding: 0">
                    <div id="menu"
                         os="0"
                         class="n-bg-1">
                        <div class="n-plr-4 n-pt-3">
                            <h2 class="n-f-bold n-c-base c-title n-b-line n-t-line n-inline-block n-pb-1">{{
                LAN.nav.QQOnlineShort }}</h2>
                        </div>
                        <div class="left-menu">
                            <div class="n-plr-4">
                                <ul class="n-p-0 n-ul n-m-0 n-pb-4 c-content-left">
                                    <li class="n-f-6 n-ptb-1 n-plr-2 n-pointer c-hover n-b-line"
                                        @click="$router.push({name: 'journal'})">
                                        {{ LAN.nav.QQHome }}</li>
                                    <li class="n-f-6 n-ptb-1 n-plr-2 n-pointer n-pointer c-hover n-b-line n-selected">
                                        {{ LAN.nav.QQOnlineShort }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </a-col>
                <a-col :span="24"
                       :lg="20">
                    <div class="n-bg-1 n-p-3">
                        <div v-if="loading">
                            <div class="n-skeleton n-skeleton-text n-mb-2"
                                 v-for="item in 20"
                                 :key="item"></div>
                        </div>

                        <div v-else-if="data.length > 0">
                            <h2 class="n-inline-block n-f-bold n-mb-5">{{ LAN.common.allJournalOnline }}</h2>
                            <ul class="n-ul n-p-0 n-m-0">
                                <li v-for="item in data"
                                    class="n-mb-2 n-f-6 c-hover-line c-hover"
                                    :key="item.id">
                                    <div v-if="item.onlyExternalPublish == 1"
                                         @click.stop="goExternalSite(item)"
                                         class="n-flex n-flex-a-c n-line-height-1 n-pointer">
                                        {{item.name}} ({{ item.nameAbbr }})
                                    </div>
                                    <div v-if="item.onlyExternalPublish == 0"
                                         @click.stop="push.call(this, {name: 'journalPanel', query: {jid: item.id}})"
                                         class="n-flex n-flex-a-c n-line-height-1 n-pointer">
                                        {{item.name}} {{ item.nameAbbr ? '(' + item.nameAbbr + ')' : '' }}
                                    </div>

                                </li>
                            </ul>
                        </div>

                        <a-empty :description="LAN.journal.noJournal"
                                 v-else></a-empty>

                    </div>
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script >
    import { mapMutations, mapState } from 'vuex';
    import { StarOutlined, LikeOutlined, MessageOutlined, MenuUnfoldOutlined } from '@ant-design/icons-vue';
    import NImage from '@/components/nui/NImage/NImage.vue'

    import nui from '@/assets/script';

    export default {
        name: 'Journal',
        computed: {
            ...mapState(nui.extendVue.extendState.concat([])),
        },
        data () {
            return nui.extend(true, {}, nui.extendVue.extendData, {
                loading: false,
                data: []
            })
        },
        methods: nui.extend(true, {}, nui.extendVue.extendMethods, {
            ...mapMutations(nui.extendVue.extendMutations.concat()),

            NMounted () {
                this.loadData()
            },
            loadData () {
                this.loading = true;

                return this.request(this.API.journal, {
                    requestId: 'list',
                    params: { current: 1, size: 9999999, keyword: '' }
                }).then(res => {
                    if (res.code == this.REQUEST_SUCCESS_CODE) {
                        this.data = res.data.records.filter(item => item.isOnline == 1)
                    }
                    this.loading = false
                    this.$nextTick(() => {
                        this.parseMathJax()
                    })
                }).catch(err => {
                    this.loading = false
                })
            },
            /**仅在外部发布的期刊，如JDG跳转到外部网站链接 */
            goExternalSite (_data) {
                if (_data.onlyExternalPublish == 1) {
                    window.open(_data.externalSiteUrl, '_blank')
                }
            },
            goJournalLP (data) {
                if (data.isAnnualIssue == 1) {
                    this.push.call(this, { name: 'bookSuccessionList', query: { id: data.serialId } })
                } else {
                    this.push.call(this, { name: 'journalList', query: { id: data.id } })
                }
            }
        }),
        components: {
            StarOutlined,
            LikeOutlined,
            MessageOutlined,
            MenuUnfoldOutlined,
            NImage
        },
        ...nui.extendVue.extendLife
    }
</script>
<style lang="scss" scoped>
    .category-button {
        display: none;
    }

    .c-title {
        display: block;

        &:before {
            width: 90%;
        }

        &:after {
            width: 100%;
        }
    }

    @media screen and (max-width: 1100px) {
        #menu {
            background-color: transparent;
        }
        .left-menu {
            display: none;
        }
    }
</style>