import { createApp } from 'vue'
import App from './App.vue'
import antd from 'ant-design-vue';
import router from './router';// 路由
import store from './store'; // 状态管理模块
import LAN from '@/assets/script/LAN'
import nui from '@/assets/script'
import CONFIG from '@/assets/script/config'
import tap from './assets/script/tools/tap';// 兼容移动端PC端的，解决了移动端点击延时以及不能多指点击问题的tap事件
import 'ant-design-vue/dist/antd.less';
import './assets/style/icon.css';// 图标样式
import './assets/style/nui.scss'// 全局样式
import './assets/style/common.scss'// 全局样式

const app = createApp(App);

// 自定义SetTitle函数用来更新页面的Title
document.setTitle = function (t) {
    try {
        document.title = t;
        // var i = document.createElement('iframe');
        // i.src = CONFIG.BASE + 'favicon.ico';
        // i.style.display = 'none';
        // i.onload = function () {
        //     setTimeout(function () {
        //         document.body.removeChild(i)
        //     }, 1000);
        // };
        // try {
        //     document.body.appendChild(i);
        // } catch (e) {
        //     window.console.log('setTitle Error', e);
        // }
    } catch (ee) {
        window.console.log('setTitle Error full', ee);
    }
};

// 每次路由更新都重置页面Title
router.beforeEach((to, from, next) => {
    var title = typeof to.meta.title == "function" ? to.meta.title(to, from) : to.meta.title

    if (title) {
        document.setTitle(LAN.LF.parseLanguageBySequence(title));
    }

    //路有变化的时候将全局注册的组件清除掉
    var globalComponents = document.querySelectorAll('.n-global-component');

    globalComponents.forEach(item => document.body.removeChild(item))

    next();
});

//路由变动调用一些基础函数
router.beforeEach((to, from, next) => {
    if (to.name != from.name) {
        nui.cancelAjaxWhenRouterChange(store, to, from)
    }

    var sc = localStorage.getItem('SC') || '{}'

    try {
        document.getElementById('body').style.opacity = 0
        sc = JSON.parse(sc)

        sc[from.name] = document.getElementById('body').scrollTop || 0
        localStorage.setItem('SC', JSON.stringify(sc))
    } catch (err) {

    }

    next();
});

//路由变动调用一些基础函数
router.afterEach((to, from, next) => {
    var sc = localStorage.getItem('SC') || '{}'

    try {
        sc = JSON.parse(sc)

        setTimeout(() => {
            document.getElementById('body').scrollTop = (sc[to.name] || 0)
            document.getElementById('body').style.opacity = 1
        }, 100);
    } catch (err) {

    }
});

// 除登录，发起端，会议室外其他路由需鉴权
router.beforeEach((to, from, next) => {
    if (to.name == 'login' || to.name == 'noAuth') {
        next();
    } else {
        // if (!nui.isLogin()) {
        //     next('/');
        // } else {
        next();
        // }
    }
});

window.addEventListener('beforeunload', () => {
    localStorage.removeItem('SC')
})

app.directive('tap', tap)
app.use(store)
app.use(router)
app.use(antd)
app.mount('#app')

export default { app, router, store };